<template>
    <div>
        <div>
            <div :class=" ismsg == false ?  'Checklist_Section_width fixdheader': 'fixdheader_checklist' ">
                <span class="headerstyle">Checklist-Section</span>
                <span class="right">
                        <el-button icon="el-icon-back" v-if="!ismsg" type="info" @click="$router.go(-1)">Back</el-button>
                        <el-button  v-if="ismsg" type="info" @click="$emit('messageclose', {ismsg:false})">Close</el-button>
                        <el-button v-if="sectionid != null" @click="cellchanged('analysis_checklistsection_save',()=>{save()},false)" :disabled="!editpermission" type="success">Save</el-button>
                </span>
                <el-row>
                    <span class="left" >
                        <selectpart  :Similar="Similar" @projectpartchange="projectpartchange">
                        </selectpart>
                    </span>
                    <span  class="left">
                        <el-select :disabled="sectiondisabled" v-model="sectionid" filterable @change="GetChecklistSection" :id="'analysis_checklistsection_filter_section'" placeholder="Section"    
                        style="width:244px;" class="padding2px bold">
                            <el-option v-for="item in sections" :key="item.id" :label="item.sectiondisplay"
                                :value="item.id" :disabled="item.islinethrough == true ? false : true">
                                <div :class="!item.islinethrough  ? 'islinethrough' : ''">{{item.sectiondisplay}}</div>
                            </el-option>
                        </el-select>
                    </span>
                    <span style="margin-left: 6px; margin-top: 4px;" class="left">
                        <div class="el-input el-input--mini bold " v-if="project.library">
                            {{ project.library }}
                        </div>
                    </span>
                </el-row>
                <hr class="hrstyle" />
                <div v-if="sectionid != null">
                    <el-row>
                        <el-select v-model="form.role" filterable placeholder="Role" :id="'analysis_checklistsection_filter_role'">
                            <el-option v-for="item in ['Take Off', 'Bill']" :disabled="!editpermission" :key="item"
                                :label="item" :value="item">
                            </el-option>
                        </el-select>

                        <el-select v-model="clsfiltervalue" v-if="!ismsg" placeholder="Section heading" class="marginleft4px" :id="'analysis_checklistsection_filter_sectionheading'" :style="{width: clsfilterwidth}" clearable>
                            <el-option v-for="item in form.section" :disabled="!editpermission" :key="item.id" :label="item.srno + '. ' +item.sectionname" :value="item.id"></el-option>
                        </el-select>
                    </el-row>
                    <hr class="hrstyle" />
                </div>
            </div>
            <div v-if="sectionid != null" :class="ismsg == false ? 'Checklist_Section_width' : ''">
                <el-row>
                    <el-col>
                        <div class="var_crt">
                            <span style="font:bold;">Variations and Corrections</span>
                        </div>

                    </el-col>
                </el-row>
                <el-row>
                    <el-table :data="form.elementlistVCs" class="fullwidth custom_padding " size="mini">

                        <el-table-column prop="variationnumber" label="Val" width="55">
                            <template slot-scope="scope">
                                <el-input :disabled="!editpermission" v-model="scope.row.variationnumber" :id="'analysis_checklistsection_table_val_'+scope.$index"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="correctionnumber" label="Crtn" width="55">
                            <template slot-scope="scope">
                                <el-input :disabled="!editpermission" v-model="scope.row.correctionnumber" :id="'analysis_checklistsection_table_crtn_'+scope.$index"></el-input>
                            </template>
                        </el-table-column>
                        <el-table-column prop="datestarted" label="Date Started" width="105" min-width="90">
                            <template slot-scope="scope">
                                <el-date-picker v-model="scope.row.datestarted" align="center" type="date" style="width:100px"
                                    :id="'analysis_checklistsection_table_datastarted_'+scope.$index"
                                    prefix-icon="{}" format="dd-MMM-yyyy" value-format="yyyy-MM-dd"
                                    placeholder="Date Started">
                                </el-date-picker>
                            </template>
                        </el-table-column>
                        <el-table-column prop="description" label="Description">
                            <template slot-scope="scope">
                                <el-input :disabled="!editpermission" v-model="scope.row.description" :id="'analysis_checklistsection_table_description_'+scope.$index">
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column align="right" label="Action" width="100">
                            <template slot-scope="scope">
                                    <el-button size="mini" @click="Addsection(scope.$index, scope.row)"
                                        class="padding7TB" :disabled="!editpermission" type="primary"
                                        icon="el-icon-circle-plus"></el-button>
                                    <el-button size="mini" class="padding7TB" :disabled="!editpermission" type="danger"
                                        icon="el-icon-delete" @click="delRowSection(scope.$index, scope.row)">
                                    </el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <hr class="hrstyle" />
                <el-row>
                    <el-col>
                        <div class="var_crt">
                            <span style="font:bold;">Sections</span>
                        </div>
                    </el-col>
                </el-row>
                <el-table :data="[{}]" class="tableBox-cell">
                    <el-table-column width="25"></el-table-column>
                    <el-table-column label="T" width="25"></el-table-column>
                    <el-table-column label="B" width="25"></el-table-column>
                    <el-table-column label="Date Stamp" width="115"></el-table-column>
                    <el-table-column label="List"></el-table-column>
                    <el-table-column label="Answer / Link" align="left" width="220"></el-table-column>
                    <el-table-column label="Unit / UR(S)" align="center" width="75"></el-table-column>
                    <!-- <el-table-column label="Biller" align="center" width="100"></el-table-column> -->
                    <el-table-column label="Library" align="center" width="85"></el-table-column>
                    <el-table-column label="LR" align="center" width="35"></el-table-column>
                </el-table>
                <el-table :show-header="false" ref="sectiontable" :data="getchecklistsections"
                    class="fullwidth custom_padding tableBox section_table_header" size="mini"
                    :cell-style="mastertablecellClassName" :row-class-name="masterTableRowClassName" default-expand-all>
                    <el-table-column type="expand" width="25">
                        <template slot-scope="pt" class="fullwidth">
                            <div v-if="pt.row.sectiondetails && pt.row.sectiondetails.length > 0">
                            <el-table :show-header="false" key="id" :data="pt.row.sectiondetails"
                                class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                ref="sectiondetail" default-expand-all>
                                <el-table-column type="expand" width="25">
                                    <template slot-scope="Ascope">
                                        <div v-if="getChildren(Ascope.row).length > 0">
                                        <el-table :show-header="false" :data="Ascope.row.children"
                                            class="fullwidth custom_padding tableBox" :cell-style="tablecellClassName"
                                            :row-style="tableRowStyle" ref="Ascope" default-expand-all>
                                            <el-table-column type="expand" width="25">
                                                <template slot-scope="Bscope">
                                                    <div v-if="getChildren(Bscope.row).length > 0">
                                                    <el-table :show-header="false" :data="Bscope.row.children"
                                                        class="fullwidth custom_padding tableBox"
                                                        :cell-style="tablecellClassName" ref="Bscope" 
                                                        :row-style="tableRowStyle" default-expand-all>
                                                        <el-table-column type="expand" width="25">
                                                            <template slot-scope="Cscope">
                                                                <div v-if="getChildren(Cscope.row).length > 0">
                                                                <el-table :show-header="false" row-key="srno"
                                                                    :data="getChildren(Cscope.row)"
                                                                    class="fullwidth custom_padding tableBox"
                                                                    :cell-style="tablecellClassName" ref="Cscope">
                                                                    <el-table-column width="25"></el-table-column>
                                                                    <el-table-column prop="srno" width="25">
                                                                        <template slot-scope="scope">
                                                                            <el-checkbox :disabled="form.role == 'Bill'"
                                                                                @change="signoffbilltick($event, scope.row, Cscope.row, 'children','takeoff')"
                                                                                :id="'analysis_checklistsection_table_cscope_bill_checkbox'+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                                v-model="scope.row.cs.takeoff">
                                                                            </el-checkbox>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="srno" width="25">
                                                                        <template slot-scope="scope">
                                                                            <el-checkbox
                                                                                :disabled="form.role == 'Take Off'"
                                                                                @change="signoffbilltick($event, scope.row, Cscope.row, 'children','bill')"
                                                                                :id="'analysis_checklistsection_table_cscope_tackoff_checkbox'+pt.$index+Ascope.$index+Bscope.$index+Cscope.$index+scope.$index"
                                                                                v-model="scope.row.cs.bill">
                                                                            </el-checkbox>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="datestarted"
                                                                        label="Date Started" width="115">
                                                                        <template slot-scope="scope">
                                                                            {{ dateformat(scope.row.cs.date) }}
                                                                            <!-- <el-date-picker v-model="scope.row.cs.date"
                                                                                type="date" style="width:100px"
                                                                                prefix-icon="{}" format="dd-MMM-yyyy"
                                                                                value-format="yyyy-MM-dd"
                                                                                placeholder="Date Started">
                                                                            </el-date-picker> -->
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="activity" label="List" show-overflow-tooltip>
                                                                        <template slot-scope="scope">                                                                            
                                                                            <div v-if="scope.row.rowtype === 'E' || scope.row.rowtype === 'F'">
                                                                                <el-input :class="'row_color_'+scope.row.rowtype"
                                                                                           :disabled="!editpermission"
                                                                                           placeholder="Activity"
                                                                                           v-model="scope.row.cs.activity"></el-input>
                                                                            </div>
                                                                            <div v-else>
                                                                                {{ scope.row.activity }}
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column label="MOM/Answer" align="left"
                                                                        prop="method" width="220" show-overflow-tooltip>
                                                                        <template slot-scope="scope">
                                                                            <!-- <div v-if="scope.row.rowtype === 'D'">
                                                                                {{ scope.row.method }}
                                                                            </div> -->
                                                                            <div v-if="scope.row.rowtype === 'G' && scope.row.sourcelink">
                                                                                <el-link type="primary" :href="scope.row.sourcelink" target="_blank" class="fontsize12" :underline="false">{{ scope.row.sourcelink }}</el-link>
                                                                            </div>
                                                                            <!-- <div v-if="scope.row.rowtype === 'F' && scope.row.answer">
                                                                                <el-dropdown>
                                                                                    <span class="el-dropdown-link">
                                                                                        Answer's
                                                                                        <i
                                                                                            class="el-icon-arrow-down el-icon--right"></i>
                                                                                    </span>
                                                                                    <el-dropdown-menu slot="dropdown">
                                                                                        <div v-for="(itme, index) in getanswer(scope.row.answer)"
                                                                                            :key="index">
                                                                                            <el-dropdown-item divided>
                                                                                                {{ itme }}
                                                                                            </el-dropdown-item>
                                                                                        </div>
                                                                                    </el-dropdown-menu>
                                                                                </el-dropdown>
                                                                            </div> -->
                                                                            <div v-if="scope.row.rowtype === 'F'">
                                                                                <el-select style="width:100%;"
                                                                                    placeholder="Select Answer from List"
                                                                                    default-first-option
                                                                                    filterable
                                                                                    allow-create
                                                                                    :clearable="false"
                                                                                    v-model="scope.row.cs.answer"
                                                                                    v-if="scope.row.rowtype === 'F'"
                                                                                    :class="'row_color_'+scope.row.rowtype">
                                                                                    <el-option
                                                                                        v-for="(item, index) in scope.row.cs.templateanswers"
                                                                                        :key="index"
                                                                                        :label="item"
                                                                                        :value="item">
                                                                                    </el-option>
                                                                                </el-select>
                                                                            </div>
                                                                            <div v-if="scope.row.rowtype === 'E'">
                                                                                <!-- {{ scope.row.answer }} -->
                                                                                <el-input 
                                                                                    placeholder="Answer"
                                                                                    v-model="scope.row.cs.answer"
                                                                                    :disabled="!editpermission"
                                                                                    v-if="scope.row.rowtype === 'E'"
                                                                                    :class="'row_color_'+scope.row.rowtype"></el-input>
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column prop="activity" width="70"
                                                                        label="Unit/UR(S)">
                                                                        <template slot-scope="scope">
                                                                            <div v-if="scope.row.rowtype === 'D'">
                                                                                {{ scope.row.unit }}
                                                                            </div>
                                                                            <div v-if="scope.row.rowtype === 'E' || scope.row.rowtype === 'F'">
                                                                                <el-select
                                                                                    v-model="scope.row.cs.urs"
                                                                                    :class="'row_color_'+scope.row.rowtype"
                                                                                    clearable
                                                                                    remote
                                                                                    :remote-method="getURsRemote"
                                                                                    :disabled="!editpermission"
                                                                                    reserve-keyword
                                                                                    filterable
                                                                                    placeholder="UR(S)">
                                                                                    <el-option
                                                                                        v-for="(item, index) in $store.state.URs"
                                                                                        :key="index"
                                                                                        :label="item.ur"
                                                                                        :value="item.ur">
                                                                                    </el-option>
                                                                                </el-select>
                                                                            </div>
                                                                        </template>
                                                                    </el-table-column>
                                                                    <!-- <el-table-column label="Biller" prop="rowtype"
                                                                        align="center" width="100">
                                                                        <template slot-scope="scope">
                                                                            {{ scope.row.cs.billbyname }}
                                                                        </template>
                                                                    </el-table-column> -->
                                                                    <el-table-column label="Library" align="center" width="85">
                                                                        <template slot-scope="scope">
                                                                            {{ scope.row.method }}
                                                                        </template>
                                                                    </el-table-column>
                                                                    <el-table-column label="LR" align="center" width="35">
                                                                        <template slot-scope="scope">
                                                                            {{ addLeadingZeros(scope.row.LR, 3) }}
                                                                        </template>
                                                                    </el-table-column>
                                                                </el-table>
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="srno" width="25">
                                                            <template slot-scope="scope">
                                                                <el-checkbox :disabled="form.role == 'Bill'"
                                                                :id="'analysis_checklistsection_table_bscope_bill_checkbox'+pt.$index+Ascope.$index+Bscope.$index+scope.$index"
                                                                    @change="signoffbilltick($event, scope.row, Bscope.row, 'children','takeoff')"
                                                                    v-model="scope.row.cs.takeoff">
                                                                </el-checkbox>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="srno" width="25">
                                                            <template slot-scope="scope">
                                                                <el-checkbox :disabled="form.role == 'Take Off'"
                                                                :id="'analysis_checklistsection_table_bscope_tackoff_checkbox'+pt.$index+Ascope.$index+Bscope.$index+scope.$index"
                                                                    @change="signoffbilltick($event, scope.row, Bscope.row, 'children','bill')"
                                                                    v-model="scope.row.cs.bill"></el-checkbox>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="datestarted" label="Date Started"
                                                            width="115">
                                                            <template slot-scope="scope">
                                                                <!-- <el-date-picker v-model="scope.row.cs.date" type="date"
                                                                    style="width:100px" prefix-icon="{}"
                                                                    format="dd-MMM-yyyy" value-format="yyyy-MM-dd"
                                                                    placeholder="Date Started">
                                                                </el-date-picker> -->
                                                                {{ dateformat(scope.row.cs.date) }}
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column prop="activity" label="List">
                                                            <template slot-scope="scope">
                                                                <div class="sectionfont_C"> {{ scope.row.activity }}
                                                                </div>
                                                            </template>
                                                        </el-table-column>
                                                        <el-table-column label="MOM / Answer" align="left" width="1">
                                                        </el-table-column>
                                                        <el-table-column label="Unit / UR(S)" prop="unit" width="1">
                                                        </el-table-column>
                                                        <!-- <el-table-column label="Biller" prop="rowtype" align="center"
                                                            width="100">
                                                            <template slot-scope="scope">
                                                                {{ scope.row.cs.billbyname }}
                                                            </template>
                                                        </el-table-column> -->
                                                        <el-table-column label="LR" prop="LR" align="center" width="35">
                                                            <template slot-scope="scope">
                                                                {{ addLeadingZeros(scope.row.LR, 3) }}
                                                            </template>
                                                        </el-table-column>
                                                    </el-table>
                                                    </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="srno" width="25">
                                                <template slot-scope="scope">
                                                    <el-checkbox :disabled="form.role == 'Bill'"
                                                    :id="'analysis_checklistsection_table_ascope_bill_checkbox'+pt.$index+Ascope.$index+scope.$index"
                                                        @change="signoffbilltick($event, scope.row, Ascope.row, 'children','takeoff')"
                                                        v-model="scope.row.cs.takeoff"></el-checkbox>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="srno" width="25">
                                                <template slot-scope="scope">
                                                    <el-checkbox :disabled="form.role == 'Take Off'"
                                                    :id="'analysis_checklistsection_table_ascope_tackoff_checkbox'+pt.$index+Ascope.$index+scope.$index"
                                                        @change="signoffbilltick($event, scope.row, Ascope.row, 'children','bill')"
                                                        v-model="scope.row.cs.bill"></el-checkbox>
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="datestarted" label="Date Started" width="115">
                                                <template slot-scope="scope">
                                                    <!-- <el-date-picker v-model="scope.row.cs.date" type="date"
                                                        style="width:100px" prefix-icon="{}" format="dd-MMM-yyyy"
                                                        value-format="yyyy-MM-dd" placeholder="Date Started">
                                                    </el-date-picker> -->
                                                    {{ dateformat(scope.row.cs.date) }}
                                                </template>
                                            </el-table-column>
                                            <el-table-column prop="activity" label="List">
                                                <template slot-scope="scope">
                                                    <div class="sectionfont_B"> {{ scope.row.activity }} </div>
                                                </template>
                                            </el-table-column>
                                            <el-table-column label="MOM / Answer" align="left" width="1">
                                            </el-table-column>
                                            <el-table-column label="Unit / UR(S)" prop="unit" width="1">
                                            </el-table-column>
                                            <!-- <el-table-column label="Biller" prop="rowtype" align="center" width="100">
                                                <template slot-scope="scope">
                                                    {{ scope.row.cs.billbyname }}
                                                </template>
                                            </el-table-column> -->
                                            <el-table-column label="LR" prop="LR" align="center" width="35">
                                                <template slot-scope="scope">
                                                    {{ addLeadingZeros(scope.row.LR, 3) }}
                                                </template>
                                            </el-table-column>
                                        </el-table>
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="srno" width="25">
                                    <template slot-scope="scope">
                                        <el-checkbox :disabled="form.role == 'Bill'"
                                        :id="'analysis_checklistsection_table_sectiondetail_bill_checkbox'+pt.$index+scope.$index"
                                            @change="signoffbilltick($event, scope.row, pt.row, 'sectiondetails','takeoff')"
                                            v-model="scope.row.cs.takeoff"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="srno" width="25">
                                    <template slot-scope="scope">
                                        <el-checkbox :disabled="form.role == 'Take Off'"
                                        :id="'analysis_checklistsection_table_sectiondetail_tackoff_checkbox'+pt.$index+scope.$index"
                                            @change="signoffbilltick($event, scope.row, pt.row, 'sectiondetails','bill')"
                                            v-model="scope.row.cs.bill"></el-checkbox>
                                    </template>
                                </el-table-column>
                                <el-table-column prop="datestarted" label="Date Started" width="115">
                                    <template slot-scope="scope">
                                        <!-- <el-date-picker v-model="scope.row.cs.date" type="date" style="width:100px"
                                            prefix-icon="{}" format="dd-MMM-yyyy" value-format="yyyy-MM-dd"
                                            placeholder="Date Started">
                                        </el-date-picker> -->
                                        {{ dateformat(scope.row.cs.date) }}
                                    </template>
                                </el-table-column>
                                <el-table-column prop="activity" label="List">
                                    <template slot-scope="scope">
                                        <div class="sectionfont_A"> {{ scope.row.activity }} </div>
                                    </template>
                                </el-table-column>
                                <el-table-column label="MOM / Answer" align="left" width="1"></el-table-column>
                                <el-table-column label="Unit / UR(S)" prop="unit" width="1"></el-table-column>
                                <!-- <el-table-column label="Biller" prop="rowtype" align="center" width="100">
                                    <template slot-scope="scope">
                                        {{ scope.row.cs.billbyname }}
                                    </template>
                                </el-table-column> -->
                                <el-table-column label="LR" prop="LR" align="center" width="35">
                                    <template slot-scope="scope">
                                        {{ addLeadingZeros(scope.row.LR, 3) }}
                                    </template>
                                </el-table-column>
                            </el-table>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="srno" width="25" style="font-size: small;font-weight: 600;">
                    </el-table-column>
                    <el-table-column label="List" prop="sectionname" :disabled="!editpermission"
                        style="font-size: small;font-weight: 500;">
                        <template slot-scope="scope">
                            {{ scope.row.sectionname }}
                        </template>
                    </el-table-column>
                    <el-table-column label="MOM / Answer" align="left" width="1"></el-table-column>
                    <el-table-column label="Unit / UR(S)" prop="unit" width="1"></el-table-column>
                    <!-- <el-table-column label="Biller" prop="rowtype" align="center" width="100">
                    </el-table-column> -->
                </el-table>
                <hr class="hrstyle" />
                <el-row class="spaceBetween">
                    <b>Biller :</b> {{this.form.lasteditedbillername}}
                </el-row>
                <el-row>
                    <span class="left">
                        <el-button size="mini" @click="signoffsection()" class="padding7TB" :disabled="!editpermission"
                            type="primary">Sign Off</el-button>
                    </span>
                    <span :class="form.signoffs && form.signoffs.length > 2 ? 'left fixwidthchecked' : 'left'">
                        <div v-for="item in form.signoffs">
                            <div>
                                &nbsp;<b>Checked:</b> &nbsp; <span style="color:#909399;font-size:12px;">{{
                                        item.signoffbyname}} &nbsp;
                                    {{ dateformat(item.signoffon) }}</span>
                            </div>
                        </div>
                    </span>
                </el-row>
            </div>
        </div>
    </div>
</template>
<script>
import selectpart from "./selectpart.vue";
import moment from "moment";
export default {
    props: {
        ismsg:{type: Boolean, default: false},
        pageaction:{type:Object,default:null}
    },
    data() {
        return {
            sectiondisabled:false,
            user: this.$store.state.user,
            form: {
                role: 'Take Off',
                elementlistVCs: [{}],
                section: [{}],
            },
            urlparams: {
                projectid: null, partid: null, sectionid: null
            },
            sections: [],
            sectionid: null,
            project: {},
            Similar: {
                projectPlaceholder: '',
                partPlaceholder: '',
                lastsavedshow: false,
                projectdisabled:false,
                partdisabled:false,
            },
            clsfiltervalue: null,
            clsfilterwidth: '160px'
        }
    },
    components: {
        selectpart: selectpart,
    },
    computed: {
        dateformat() {
            return function (data) {
                if (data) {
                    var dd = new Date(data); 
                    return moment(dd).format("DD-MMM-YYYY  hh:mm") //+ " " + moment().utc(data).format("hh:mm");
                } else {
                    return "";
                }
            }
        },
        getChildren() {
            return function (data) {
                if (!data.children || data.children.length == 0) {
                    return [];
                }
                return data.children;
            }
        },
        getchecklistsections() {
            if (this.form.section.length == 0) {
                return [];
            }
            var sectiondata = this.form.section;
            var clsections = sectiondata.filter((f) => f.id === this.clsfiltervalue);

            if (clsections.length > 0) {
                var clsectionname = clsections[0].sectionname;
                var size = this.measureTextWidth(clsectionname, 14) + 15;
                size = size < 150 ? 150 : size;
                this.clsfilterwidth = size.toString() + "px";
                return clsections;
            } else {
                this.clsfilterwidth = "160px";
            }
            return sectiondata;
        }
    },
    methods: {
        signoffsection() {
            this.form.signoffs.unshift({ projectsectionid: this.sectionid, signoffby: this.user.id, signoffbyname: this.user.loginname, signoffon: new Date() })
        },
        signoffbilltick(event, row, obj, name, type) {
            var tt = new Date();
            const date = moment.utc(tt).format();
            if (event == true) {
                row.cs.date = date;
                if(type == 'bill')
                {
                    row.cs.billon = date;
                    row.cs.billby = this.user.id;
                }
                if(type == 'takeoff')
                {
                    row.cs.takeoffon = date;
                    row.cs.takeoffby = this.user.id;
                }
            }
            if (row.cs.bill == false && row.cs.takeoff == false) {
                row.cs.date = null;
            }
            var t = JSON.parse(JSON.stringify(obj[name]));
            this.$set(obj, name, t);
        },
        FormatChildren(data, action) {
            if (!data.children || data.children.length == 0) {
                return data;
            } else {
                data.children.forEach((c, index) => {
                    if (action == "save") {
                        if (c.cs.bill == true && (c.cs.billby == 0 || c.cs.billby == null)) {
                            c.cs.billby = this.user.id;
                            c.cs.billon = new Date();
                        }
                        if (!c.cs.bill) {
                            c.cs.billby = null;
                            c.cs.billon = null;
                        }
                        if (c.cs.takeoff == true && (c.cs.takeoffby == 0 || c.cs.takeoffby == null)) {
                            c.cs.takeoffby = this.user.id;
                            c.cs.takeoffon = new Date();
                        }
                        if (!c.cs.takeoff) {
                            c.cs.takeoffby = null;
                            c.cs.takeoffon = null;
                        }
                    }
                    if (action == "get") {              
                        if (c.rowtype == 'F') {
                            c.cs.templateanswers = JSON.parse(c.cs.templateanswer);
                        }
                        if (c.cs.billby > 0) {
                            this.$nextTick(() => {
                                this.$set(c.cs,'bill',true);
                                // c.cs.bill = true;
                            })
                        } else {
                            // c.cs.billby = 0;
                            this.$set(c.cs,'bill',false);
                            this.$set(c.cs,'billby',0);
                            // c.cs.bill = false;
                        }
                        if (c.cs.takeoffby > 0) {
                            this.$nextTick(() => {
                                this.$set(c.cs,'takeoff',true);
                                // c.cs.takeoff = true;
                            });
                        } else {
                            this.$set(c.cs,'takeoff',false);
                            this.$set(c.cs,'takeoffby',0);
                            // c.cs.takeoffby = 0;
                            // c.cs.takeoff = false;
                        }
                    }
                    this.FormatChildren(c, action);
                });
            }
        },

        getsection(){
            if(this.sectionid){
                let s = this.sections.find(x => x.id == this.sectionid);
                return s.section;
            }else{
                return "";
            }
        },

        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, this.getsection());
            } else {
                this.canmatchcellid(callback, cellaction, this.getsection());
            }
        },

        save() {
            this.form.section.forEach(e => {
                e.sectiondetails.forEach(ed => {
                    if (ed.cs.bill == true && (ed.cs.billby == 0 || ed.cs.billby == null)) {
                        ed.cs.billby = this.user.id;
                        ed.cs.billon = new Date();
                    }
                    if (!ed.cs.bill) {
                        ed.cs.billby = null;
                        ed.cs.billon = null;
                    }
                    if (ed.cs.takeoff == true && (ed.cs.takeoffby == 0 || ed.cs.takeoffby == null)) {
                        ed.cs.takeoffby = this.user.id;
                        ed.cs.takeoffon = new Date();
                    }
                    if (!ed.cs.takeoff) {
                        ed.cs.takeoffby = null;
                        ed.cs.takeoffon = null;
                    }
                    this.FormatChildren(ed, 'save');
                });
            });
            this.$http.post("elementlist/saveChecklistSection", this.form)
                .then(response => {
                    this.$message({
                        showClose: true,
                        message: "Save Successful!", type: "success"
                    });
                    this.GetChecklistSection(this.sectionid);
                    this.$signalr.checkingdialogboxheaderpage();
                }).catch(err => {
                    this.loading = false;
                    this.$bus.$emit('error', err);
                });
        },
        delRowSection(index, row) {
            this.form.elementlistVCs.splice(index, 1);
            if (this.form.elementlistVCs.length == 0) {
                this.form.elementlistVCs.push({});
            }
        },
        Addsection(index, row) {
            this.form.elementlistVCs.splice(index + 1, 0, { description: '', projectsectionid: this.sectionid });
        },
        masterTableRowClassName({ row, rowIndex }) {
            return 'section-format';
        },
        mastertablecellClassName({ row, column, rowIndex, columnIndex }) {
            if (columnIndex == 6) {
                return {
                    "font-size": "12px"
                }
            }

            return {"background":"#014f46", "color": "white"}
        },
        tablecellClassName: function ({ row, column, rowIndex, columnIndex }) {
            if (row) {
                if (row.rowtype === 'A') {
                    if (columnIndex == 6) {
                        return {
                            background: "#027869",
                            color: "white",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#027869",
                        color: "white",
                    };

                } else if (row.rowtype === 'B') {
                    if (columnIndex == 6) {
                        return {
                            background: "#03cfb5",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#03cfb5",
                        color: "black"
                    };
                } else if (row.rowtype === 'C') {
                    if (columnIndex == 6) {
                        return {
                            background: "#0ffdf4",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#0ffdf4",
                        color: "black"
                    };
                } else if (row.rowtype !== 'A' && row.rowtype !== 'B' && row.rowtype !== 'C') {
                    if (columnIndex == 6) {
                        return {
                            background: "#f8f8ff",
                            color: "black",
                            "font-size": "12px"
                        }
                    }
                    return {
                        background: "#f8f8ff",
                        color: "black"
                    };
                }
            }
            return '';
        },
        GetChecklistSection: function (sectionid) {
            var self = this;
            self.loading = true;
            self.sectionid = sectionid;
            let tt = self.sections.find(x => x.id == sectionid);
            if(tt != undefined){
                this.$store.state.sectiondisplay =  tt.sectiondisplay;
            }
            var obj = {};
            if(this.pageaction){
                obj = this.pageaction;
            }            
            this.$http.post("elementlist/GetChecklistSection?elementtemplatid=" + tt.elementtemplatid + "&sectionid=" + sectionid+'&ismsg='+this.ismsg,obj)
                .then(response => {
                    let Oldrole = this.form.role;
                    this.form = response.data;
                    this.form.role = Oldrole;
                    this.form.id = this.sectionid;
                    if (this.form.elementlistVCs == undefined || this.form.elementlistVCs.length == 0) {
                        this.form.elementlistVCs = [{ description: '', projectsectionid: this.sectionid }];
                    }
                    this.form.section.forEach(e => {
                        e.sectiondetails.forEach(ed => {
                            if (ed.cs.billby > 0) {
                                ed.cs.bill = true;
                            } else {
                                ed.cs.billby = 0;
                                ed.cs.bill = false;
                            }
                            if (ed.cs.takeoffby > 0) {
                                ed.cs.takeoff = true;
                            } else {
                                ed.cs.takeoffby = 0;
                                ed.cs.takeoff = false;
                            }
                            this.FormatChildren(ed, 'get');
                        });
                    });
                }).catch(err => {
                    this.loading = false;
                    this.$bus.$emit('error', err);
                });
        },
        getsectionforchecklist() {
            this.post("ElementList/getelementlisttemplatebytype?type=" + 3,).then(
                (response) => {
                    var section = [];
                    response.data.forEach(e => {
                        this.sections.forEach(x => {
                            // if(!x.hide && !x.locked){
                                 if (x.section == e.nrmelementid && e.formtype == this.project.library) {
                                     x.elementtemplatid = e.id;
                                     x.islinethrough = true;
                                 }
                                 if(this.project.projectpart.id == x.projectPartId){
                                     var isfind = section.find(t => t.id == x.id); 
                                     if(!isfind){
                                        // if(this.$store.state.sectiondisplay == x.sectiondisplay && x.islinethrough){
                                        //     this.sectionid = x.id;
                                        //     this.GetChecklistSection(x.id);
                                        // }
                                      section.push(x);
                                     }
                                 }
                        //   }
                        });
                        // section.push(...li);
                    });
                    var isssfind = section.find(x => this.$store.state.sectiondisplay == x.sectiondisplay && x.islinethrough);
                    if(isssfind){
                        this.sectionid = isssfind.id;
                        this.GetChecklistSection(isssfind.id);
                    }
                    if(response.data.length > 0){
                       this.sections = JSON.parse(JSON.stringify(section));
                    }else{
                        this.sections = this.sections.filter(x => x.projectPartId == this.project.projectpart.id);
                    }
                });
        },
        projectpartchange: function (Projectobj) {
            this.project.id = Projectobj.project.id;
            this.project = Projectobj.project;
            this.project.projectpart = Projectobj.part;
            if (this.project.projectname == "") {
                this.sectionid = null;
                this.$store.state.projectsections = [];
            }
            else {
                this.sections = [];
                this.sections = this.$store.state.projectsections;
                if (!isNaN(this.urlparams.sectionid) && this.urlparams.sectionid != null) {
                    this.$store.state.projectsections = this.project.projectsectionsview.filter(s => s.projectPartId == this.project.projectpart.id);
                    this.sections = this.$store.state.projectsections;
                    let newsection = this.sections.find(x => x.id === this.urlparams.sectionid);
                    this.$store.state.sectiondisplay = newsection.sectiondisplay;
                    this.urlparams = { projectid: null, partid: null, sectionid: null };
                }
            }
            if (this.sections.length > 0) {
                this.getsectionforchecklist();
            }
        },

        tableRowStyle: function ({ row }) {
            if (row.rowtype == "B" || row.rowtype == "C") {
                if (!row.activity) {
                    return { 'display' : 'none' }
                }
            }
        }
    },
    // destroyed() {
    //     // this.$store.state.checklistids.ismsg = false;
    // },
    created() {
        const params = new URLSearchParams(window.location.search);
        if (params.size > 0) {
            let projectid = parseInt(params.get('projectid'));
            let partid = parseInt(params.get('partid'));
            let sectionid = parseInt(params.get('sectionid'));

            if (isNaN(projectid) || isNaN(partid) || isNaN(sectionid)) {
                this.$alert("One or more parameters are not valid numbers", 'Invalid URL');
            } else {
                this.urlparams = { projectid: projectid, partid: partid, sectionid: sectionid };                
                this.Similar.projectids = this.urlparams;
                this.$bus.$emit('addprojectpart', null);
            }
        }
        if (this.ismsg == true) {
            this.Similar.projectdisabled = true;
            this.Similar.partdisabled = true;
            this.sectiondisabled = true;
        } else {
            this.Similar.projectdisabled = false;
            this.Similar.partdisabled = false;
            this.sectiondisabled = false;
        }
        this.$bus.$on("setuser", (user) => {
            this.user = user;
        });
    }
}
</script>
<style>
.fixwidthchecked {
    height: 50px;
    min-height: 20px;
    overflow-y: scroll;
    width: 293px;
}

.Checklist_Section_width {
    max-width: 920px;
    width: 100%;
}

.el-table .section-format {
    font-size: medium;
    font-weight: 600;
}
.islinethrough{
    text-decoration: line-through;
}
.var_crt {
    background-color: lightgray;
    margin: 2px;
    padding: 2px;
    /* border: 1px solid; */
    font-weight: bold;
}

.padding7TB {
    padding: 5px !important;
}

.sectionfont_A {
    font-weight: bold;
    font-size: 14px;
}

.sectionfont_B {
    font-weight: bold;
    font-size: 13px;
}

.sectionfont_C {
    font-weight: bold;
    font-size: 12px;
}
</style>
