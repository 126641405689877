<template>
    <div>
        <div v-if="!roleaddedit">
            <div class="fixdheader roleformwidth">
                <span class="headerstyle">Role's</span>
                <div class="floatright margintb1mm">
                    <span class="padright8px">
                        <el-button :disabled="!editpermission"
                                   icon="el-icon-circle-plus"
                                   type="primary"
                                   @click="addrole"></el-button>
                    </span>
                </div>
                <hr class="hrstyle" />
            </div>

            <div class="tablepaddinglr3">
                <el-table :data="roles" class="roleformwidth" :height="height">
                    <el-table-column prop="name"
                                     label="Roles"
                                     width="255">
                    </el-table-column>

                    <el-table-column label="Edit" width="55" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini"
                                       type="primary"
                                       :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                       @click="handleEdit(scope.row)"></el-button>
                        </template>
                    </el-table-column>

                    <el-table-column label="Copy" width="55" align="center">
                        <template slot-scope="scope">
                            <el-button size="mini" type="primary"
                                       icon="glyphicon glyphicon-duplicate"
                                       @click="handleCopy(scope.row)"
                                       :disabled="!editpermission"></el-button>
                        </template>
                    </el-table-column>

                    <el-table-column label="Delete" width="55" align="center">
                        <template slot-scope="scope">
                            <el-button type="danger"
                                       icon="el-icon-delete"
                                       @click="deleterole(scope.row)"
                                       :disabled="!editpermission"></el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div v-if="roleaddedit">
            <div class="fixdheader roleformwidth">
                <span class="headerstyle">Role Add/Edit</span>
                <div class="floatright margintb1mm">
                    <span>
                        <el-button :disabled="!editpermission"
                                   @click="save()"
                                   type="success">Save</el-button>
                    </span>

                    <span class="marginleft4px">
                        <el-button @click="cancel">Cancel</el-button>
                    </span>
                </div>
                <hr class="hrstyle" />

                <el-form ref="form" label-width="87px" class="roleformwidth">
                    <el-form-item label="Role Name" class="marginb5">
                        <el-input :spellcheck="isspellcheck" v-model="form.role.name"></el-input>
                    </el-form-item>
                </el-form>
            </div>

            <!--
            <el-form ref="form" label-width="87px" class="roleformwidth">
                <el-form-item label="Role Name" class="marginb5">
                    <el-input :spellcheck="isspellcheck" v-model="form.role.name"></el-input>
                </el-form-item>
                -->

                <div class="tablepaddinglr3">
                    <el-table :data="form.rolepermissionpages" class="fullwidth"  :height="height">
                        <el-table-column prop="p.displayname" label="Pages" width="320">
                        </el-table-column>

                        <el-table-column prop="permission" label="Permission" width="100">
                            <template slot="header" slot-scope="{}">
                                <el-select v-model="mainpermission"
                                           class="fullwidth"
                                           @change="selectallpermission"
                                           placeholder="Permissions">
                                    <el-option :disabled="!editpermission"
                                               v-for="item in [
                                        { value: 0, name: 'None' },
                                        { value: 1, name: 'Read' },
                                        { value: 2, name: 'Read + Edit' },
                                    ]"
                                               :key="item.value"
                                               :label="item.name"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>

                            <template slot-scope="scope">
                                <el-select v-model="scope.row.rp.permission"
                                           placeholder="Please Select Permission"
                                           class="fullwidth">
                                    <el-option :disabled="!editpermission"
                                               v-for="item in [
                                        { value: 0, name: 'None' },
                                        { value: 1, name: 'Read' },
                                        { value: 2, name: 'Read + Edit' },
                                    ]"
                                               :key="item.value"
                                               :label="item.name"
                                               :value="item.value">
                                    </el-option>
                                </el-select>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            <!-- </el-form> -->
        </div>

         <!-- Loading Indicator -->
         <!-- <div v-if="loading" class="loading-indicator">
            <div class="progress-container">
                <el-progress :percentage="progressPercentage">
                </el-progress>
            </div>

            <div class="estimated-time">
                Progress Time: {{ estimatedTime }} seconds
            </div>

            <div class="css-spinner" v-show="loading"></div>
        </div> -->

        <loading-helper></loading-helper>
    </div>
</template>
<script>

    export default {
        data() {
            return {
                mainpermission: "",
                roles: [],
                roleaddedit: false,
                form: {
                    role: null,
                    rolepermissionpages: []
                },
                height: window.innerHeight - 140,
                loading: false,
                progressPercentage: 0,
                estimatedTime: 0, 
                startTime: 0, 
            };
        },

        methods: {
            selectallpermission(event) {
                this.form.rolepermissionpages.forEach((p) => {
                    p.rp.permission = event;
                });
            },

            cancel() {
                this.form = { role: {}, rolepermissionpages: [] };
                this.roleaddedit = false;
            },

            deleterole: function (row) {
                this.$confirm("This will permanently delete the role. Continue?", "Warning",
                    {
                        confirmButtonText: "OK",
                        cancelButtonText: "Cancel",
                        type: "warning"
                    })
                    .then(() => {
                        this.showloading();
                        this.$http.post("account/deleteRoleById", row)
                            .then((response) => {
                                this.hideloading();
                                this.$message({
                                    type: "success",
                                    message: response.data,
                                });
                                this.getroles();
                            })
                            .catch((err) => {
                                this.$bus.$emit("error", err);
                                this.hideloading();
                            });
                    });
            },

            handleEdit(row) {
                this.form = { role: {}, rolepermissionpages: [] };
                this.showloading();
                this.$http.post("account/getRoleById", row)
                    .then((response) => {
                        this.form = response.data;
                        this.form.rolepermissionpages = this.getUsefulPages(this.form.rolepermissionpages);
                        this.roleaddedit = true;
                        this.hideloading();
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                        this.hideloading();
                    });
            },

            handleCopy(row) {
                this.showloading();
                this.$http.post("account/getRoleById", row)
                    .then((response) => {
                        this.form = response.data;
                        this.form.rolepermissionpages = this.getUsefulPages(this.form.rolepermissionpages);
                        this.hideloading();

                        this.$prompt('Please enter new Role name', 'Tip', {
                            confirmButtonText: 'OK',
                            cancelButtonText: 'Cancel',
                            inputValue: this.form.role.name + " - Copy"
                        }).then(value => {
                            this.form.role.id = 0;
                            this.form.role.name = value.value;

                            this.form.rolepermissionpages.forEach(function (row, i) {
                                row.rp.id = 0;
                                row.rp.roleid = 0;
                            });
                            this.save();
                        });
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                        this.hideloading();
                    });
            },

            getroles: function () {
                this.processPercentage = 0;
                this.processTime = 0;
                this.loading = true;
                this.startTime = new Date().getTime();

                this.showloading();
                this.$http.get("account/getrole")
                    .then((response) => {
                        this.roles = response.data;
                        this.hideloading();
                        

                        const totalItems = this.roles.length;
                        let processedItems = 0;

                        // Process each item in the array
                        this.roles.forEach(item => {
                        // ... process the item ...

                        // Increment processedItems after processing each item
                        processedItems++;

                        // Calculate progress percentage
                        this.progressPercentage = Math.floor((processedItems / totalItems) * 100);

                        // Calculate estimated time based on progress
                        //   const elapsedTime = (new Date().getTime() - this.startTime) / 1000; // in seconds
                        //   const estimatedTotalTime = (elapsedTime / (processedItems / totalItems)).toFixed(2); // Total time estimation
                        //   this.estimatedTime = estimatedTotalTime;
                        });

                        // All items processed
                        clearInterval(this.processInterval); 
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                        this.hideloading();
                    });
            },

            myvalidate: function (callback) {
                var msg = [];
                if (!this.form.role.name || this.form.role.name.trim() == "") {
                    msg.push("Role Name is required.");
                }

                if (this.form.role.name) {
                    let findmatchedrole = this.roles.find(x => 
                        x.id != this.form.role.id && x.name.trim() != "" && 
                        x.name.trim() == this.form.role.name.trim()
                    );

                    if(findmatchedrole) {
                        msg.push("Role Name: " + this.form.role.name + " already exists.");
                    }
                }

                if (msg.length > 0) {
                    var style = "<style> .el-message-box {width:50%;} </style>";
                    this.$alert(msg.join("<br />") + style, "Invalid Data", {
                        dangerouslyUseHTMLString: true,
                    });
                } else {
                    callback();
                }
            },

            save: function () {
                this.myvalidate(() => {
                    var arr = this.form.rolepermissionpages.filter(x => x.rp.permission != 0);
                    var data = { role: this.form.role, rolepermissionpages: arr };
                    this.showloading();
                    this.$http.post("account/saverole", data)
                        .then((response) => {
                            this.$message({
                                showClose: true,
                                message: response.data.message,
                                type: "success",
                            });
                            this.form = { role: {}, rolepermissionpages: [] };
                            this.roleaddedit = false;
                            this.getroles();
                            this.hideloading();
                        })
                        .catch((err) => {
                            this.$bus.$emit("error", err);
                            this.hideloading();
                        });
                });
            },

            addrole: function () {
                this.form = { role: { id: 0, name: ""}, rolepermissionpages: [] };
                this.showloading();
                this.$http.get("account/getpages")
                    .then(response => {
                        this.form.rolepermissionpages = response.data;
                        this.form.rolepermissionpages = this.getUsefulPages(this.form.rolepermissionpages);
                        this.roleaddedit = true;
                        this.hideloading();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                        this.roleaddedit = false;
                    });
            },

            getUsefulPages: function(pageslist) {
                pageslist = pageslist.filter(currentitem => currentitem.p.name != 'qutimechecklist');
                return pageslist;
            },

            tableHeight() {
                this.height = window.innerHeight - 140;
            }
        },
        created() {
            this.getroles();
        },

        destroyed() {
            window.removeEventListener('resize', this.tableHeight);
        },

        mounted: function () {
            window.addEventListener('resize', this.tableHeight);
        },
    };
</script>                               
<style>
    .el-table th.el-table__cell > .cell {
        padding-left: 3px;
        padding-right: 3px;
    }


.loading-indicator {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.progress-container {
  width: 80%; /* Adjust the width of the progress bar */
  margin-bottom: 10px;
}

.css-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-top: 4px solid #007bff; /* Change color as needed */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 2s linear infinite;
  margin-top: 20px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
