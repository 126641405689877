<template>

  <div>
        <div v-if="!detail">
      <div class="fixdheader">
            <el-row>
          <el-col :span="12" class="headerstyle"> <span>Project Setup</span> </el-col>
           </el-row>

           <el-row class="widthsetup">

               <el-col :span="6">
                  <el-form ref="form" :model="form" size="mini" @submit.native.prevent>
                    <el-form-item >
                      <el-autocomplete
                        class="inline-input padding2px"
                        style="width: 100%"
                        placeholder="Project Name"
                        id="setup_project_setup_project_name"
                        v-model="grid.search"
                        :fetch-suggestions="(query, done)=>{return searchProject(query, done,true)}"
                        @select="onSearch"
                        @change="onSearch"
                        @blur="onSearch"
                        @clear="onSearch"
                        value-key="projectname"
                        clearable
                        suffix-icon="el-icon-search"
                      >
                      </el-autocomplete>
                    </el-form-item>
                  </el-form>
               </el-col >

               <el-col :span="3"> 
                <div class="custom-select padding2px5px ">
                  <el-select
                    v-model="sortdata.sorttype"
                    placeholder="Sory by"
                    @change="saveprojectsortpreferences"
                    clearable
                    filterable
                    class="custom-el-select">
                    <el-option
                      v-for="item in sortoptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value">
                      <span>{{ item.label }}</span>
                      <span style="float: right; padding-left: 6px;">
                        <i class="el-icon-d-caret"></i>
                      </span>
                    </el-option>
                  </el-select>
                  <!-- <div class="projectsortselectcaret-wrapper">
                    <i class="sort-caret ascending el-icon-caret-top" :class="{ active: sortstate === 'asc' }"></i>
                    <i class="sort-caret descending" :class="{ active: sortstate === 'desc' }"></i>
                  </div> -->

                  <el-button class="projectsortselectcaret-wrapper" @click="toggleSort" :disabled="!this.sortdata.sorttype || this.sortdata.sorttype <= 0" plain>
                    <i class="projectsortselect-caret ascending" :class="{ active: sortdata.ordertype === 1 }"></i>
                    <i class="projectsortselect-caret descending" :class="{ active: sortdata.ordertype === 2 }"></i>
                  </el-button>
                </div>
               </el-col>

               <el-col class="center" :span="7">
                    <el-pagination class="pagesizeswidth textalignlpage" 
                                    @size-change="rowperpages" 
                                    @current-change="currentChange" 
                                    :current-page.sync="grid.pageno" 
                                    :page-sizes="pagesizes" 
                                    :page-size="grid.pagesize"  
                                    layout="sizes, prev, pager, next" :total="grid.count"
                                    small>
                    </el-pagination>
                </el-col>
               <el-col :span="8">
                <span>
                        <el-button class=" floatright"
                          type="primary"
                          style="padding-left:  3px ; padding-right: 3px; margin-left: 3px;"
                          id="setup_project_setup_create_new"
                          :disabled="!editpermission"
                          @click="createNew()"
                          icon="el-icon-circle-plus"
                          >Create New</el-button
                        >
                    </span>
                <span>
                  <el-button type="primary" class="marginleft4px floatright"
                                   style="padding: 4px 3px 2px;" id="libraries_mctemplateslibrary_templatetoarchive" @click="onSearchbykey(grid,'istraining',grid.istraining == false ? true:false)">Show Training
                        <el-switch v-model="grid.istraining"  @change="onSearchbykey(grid,'istraining',grid.istraining == false ? true:false)"></el-switch>
                    </el-button>
                  </span>
                <span>
                  <el-button type="primary" class="marginleft4px floatright"
                                   style="padding: 4px 3px 2px;" id="libraries_mctemplateslibrary_templatetoarchive" @click="onSearchbykey(grid,'ishidden',grid.ishidden == false ? true:false)">Show Hidden
                        <el-switch v-model="grid.ishidden"  @change="onSearchbykey(grid,'ishidden',grid.ishidden == false ? true:false)"></el-switch>
                    </el-button>
                  </span>
               </el-col>
           </el-row >

           <div class="spaceBetween" />
           <div class="widthsetup">
                <hr class="hrstyle " />
             </div>
      </div>


                  <!-- <hr class="hrspacebetween hrstyle" /> -->
            <el-row class="left">
                <el-table class="widthsetup"
                      :data="tabledata"
                      :height="height"
                      :default-sort="{ prop: 'projectname', order: 'descending' }"
                      size="mini"
                      v-loading="loading"
                      @sort-change="sortChange"
                      type="expand"
                      align="left">

                      <el-table-column
                        prop="projectname"
                        label="Project Name"
                        sortable="custom"
                        width="360">
                      </el-table-column>

                      <el-table-column
                        prop="createdbyuser.loginname"
                        label="Creator"
                        sortable="custom"
                        width="120">
                      </el-table-column>

                      <el-table-column
                        prop="createdon"
                        label="Created On"
                        :formatter="dateformatter"
                        sortable="custom"
                        width="135">
                      </el-table-column>

                      <el-table-column
                        prop="sortorder"
                        label="Order"
                        sortable="custom"
                         width="90">
                      </el-table-column>

                      <el-table-column
                        prop="library"
                        label="Library"
                        width="90"
                        sortable="custom">
                      </el-table-column >

                      <el-table-column
                        prop="accessedbyuser.loginname"
                        label="Last User"
                        width="100"
                        sortable="custom">
                      </el-table-column>

                      <el-table-column
                        prop="accessedon"
                        label="Access Date"
                        width="130"
                        :formatter="dateformatter"
                        sortable="custom">
                      </el-table-column>

                      <el-table-column
                          prop="hide"
                          label="Hide"
                          width="50"
                          >
                            <template slot-scope="scope">
                              <el-checkbox
                                  :disabled="!editpermission"
                                v-model="scope.row.hide"
                                :id="'setup_project_setup_checkbox_hide_'+scope.$index"
                                @change="updateproject(scope.row)"
                              ></el-checkbox>
                            </template>
                      </el-table-column >



                      <el-table-column
                          label="Edit"
                          width="46"

                          class=""
                          >
                          <template slot-scope="scope" >
                            <el-button
                              :id="'setup_project_setup_table_edit_'+scope.$index"
                              title="Edit"
                              size="mini"
                              class="padding7"
                              type="primary"
                              :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                              @click="handleEdit(scope.$index, scope.row)"
                              >  </el-button
                            >
                            <!-- {{editpermission == true ? 'Edit' : 'View' }} -->
                          </template>
                      </el-table-column>

                    </el-table>

                  </el-row>
     </div>




    <div v-show="detail">
      <el-form
        ref="detailform"
        :model="detailform"
        label-position="rught"
        size="mini"
        v-if="detail"
      >


         <div class="fixdheader projectsetupeditwidth">
          <el-row>
             <el-col :span="12" class="headerstyle"> Project Setup - Add/Edit </el-col>
             <el-col :span="12">
                  <div class="right">
                        <el-button
                            :disabled="!editpermission"
                            @click="cellchanged('setup_project_setup_save',()=>{save()},false)"
                            id="setup_project_setup_save"
                            type="success" icon="el-icon-circle-check">
                            Save
                          </el-button>

                           <el-button
                               :disabled="!editpermission"
                               v-if="detailform.id"
                               @click="saveas()"
                               id="setup_project_setup_save_as"
                               type="success"
                               icon="el-icon-circle-check">
                                 Save as
                           </el-button>

                         <el-button
                             v-if="ProjectPartselect.projectsections.length == 0"
                             icon="el-icon-delete"
                             type="danger"
                             id="setup_project_setup_delete_project"
                             :disabled="!editpermission"
                             @click="deleteproject()">
                               Delete Project
                         </el-button>

                         <el-button
                            icon="el-icon-back"
                            type="info"
                            id="setup_project_setup_back"
                            @click="backdetal">
                              Back
                         </el-button>
                  </div>
             </el-col>
          </el-row>
        <el-row :gutter="7">
          <el-col :span="5">
              <el-input
              @change="iseditproject = true;"
                v-model="detailform.projectname"
                placeholder="Project Name"
                id="setup_project_setup_add_edit_project_name"
                :spellcheck="isspellcheck"
                :disabled="!caneditname"
              ></el-input>

          </el-col>
          <el-col :span="4">
                 <el-select
                id="setup_project_setup_project_parts"
                v-model="ProjectPartselect.name"
                placeholder="Project Parts"
                class="fullwidth"
                @change="selectpart"
              >
                <el-option
                  v-for="(item, index) in detailform.projectparts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                  <div v-if="item.name != null">
                    <span style="float: left"
                      >{{ item.name }} &nbsp;&nbsp;</span
                    >
                    <el-button
                      v-if="detailform.projectparts.length != 0"
                      icon="el-icon-edit"
                      type="text"
                      :id="'setup_project_setup_project_parts_edit_'+index"
                      size="mini"
                      :disabled="!editpermission"
                      @click="editprojectpart(index)"
                      align="right"
                    ></el-button>
                  </div>
                </el-option>
                <el-option value="">
                  <template>
                    <div>
                      <el-button
                        icon="el-icon-circle-plus-outline"
                        id="setup_project_setup_project_parts_add"
                        type="text"
                        size="mini"
                        :disabled="!editpermission"
                        @click="addprojectpart"
                        style="float: left"
                      ></el-button>
                    </div>
                  </template>
                </el-option>
              </el-select>
          </el-col>
          <el-col :span="2">

              <!-- :disabled="detailform.id" -->
              <el-select
              @change="iseditproject = true"
                v-model="detailform.library"
                filterable
                placeholder="Library"
                id="setup_project_setup_project_library"
                class="fullwidth"
                :disabled="detailform.id != null"
              >
                <el-option
                  v-for="item in libraries"
                  :key="item"
                  :label="item"
                  :value="item"
                >
                </el-option>
              </el-select>

          </el-col>
          <el-col :span="4">
            <!-- <el-form-item label="Sort Order"> -->
                <el-row>
                    <el-col :span="12" class="paddtop5"><b>Sort Order</b></el-col>
                    <el-col :span="12">
                         <el-select
                         @change="iseditproject = true"
                           v-model="detailform.sortorder"
                           filterable
                           :spellcheck="isspellcheck"
                           placeholder="Sort Order"
                            id="setup_project_setup_project_sort_order">
                           <el-option
                             v-for="item in sortorders"
                             :key="item"
                             :label="item"
                             :value="item"
                           >
                           </el-option>
                         </el-select>
                    </el-col>
                </el-row>
            <!-- </el-form-item> -->
          </el-col>
          <el-col :span="4">
            <!-- <el-form-item label="Bill Decimal(s)"> -->
                  <el-row>
                      <el-col :span="14" class="paddtop5"><b>Bill Decimal(s)</b></el-col>
                      <el-col :span="3">
                            <el-input-number style="width:45px" @change="iseditproject = true"
                              v-model.number="detailform.billdecimal"
                              :controls="false"
                              :precision="0"
                              id="setup_project_setup_project_billdecimal"
                            >
                            </el-input-number>
                      </el-col>
                  </el-row>
            <!-- </el-form-item> -->
          </el-col>
          <el-col :span="4">
           
                  <el-row>
                      <el-col :span="5" class="paddtop5"><b>RFI</b></el-col>
                      <el-col :span="10">
                        <el-select
                          :disabled="rfidsable"
                           v-model="detailform.usepart"
                           id="setup_project_setup_project_RFI"
                           placeholder="RFI">
                           <el-option
                             v-for="item in [{name:'All',value:1},{name:'Parts',value:0}]"
                             :key="item.value"
                             :label="item.name"
                             :value="item.value"
                           >
                           </el-option>
                         </el-select>
                      </el-col>
                  </el-row>
            
          </el-col>
        </el-row>
        <el-row :gutter="5">
          <el-col :span="3">
            <el-form-item label="Hide Project">
              <el-checkbox @change="iseditproject = true" v-model="detailform.hide" id="setup_project_setup_project_hide_project"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="2">
            <el-form-item label="">
              Training
              <el-checkbox  v-model="detailform.istraining" id="setup_project_setup_project_istraining"></el-checkbox>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="">
              Show Base (Locked) Versions
              <el-checkbox @change="iseditproject = true" v-model="showbasaversion" id="setup_project_setup_project_show_base_version"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <hr style="margin-bottom:2px;">
      </div>
      <div class="projectsetupeditwidth tablepaddinglr3">
        <el-table  @sort-change="sectioneditedsortChange" :default-sort="{ prop: 'Section', order: 'ascending' }" :data="getprojectsections"  size="mini"  v-loading="loading">
          <template v-slot:append>
            <hr class="hrstyledark" />
            <el-table   :data="addddtdatalist" :empty-text="'We don\'t have an Add/Edit Project section for selected Project part.'" :show-header="false" size="mini"  v-loading="loading">
              <el-table-column prop="section"  sortable="custom" width="250" label="Section">
                <template slot-scope="{}">
                  {{ "Add & Ddt" }}
                </template>    
              </el-table-column>
              <el-table-column prop="section"  sortable="custom" width="300" label="Section">
                <template slot-scope="scope">
                  <el-input
                    @change="iseditproject = true"
                    :spellcheck="isspellcheck"
                      :id="'setup_project_setup_project_sectoin_display_'+scope.$index"
                      class="inline-input fullwidth"
                      @focus="focusdisplay"
                      v-model="scope.row.sectiondisplay"
                    >
                </el-input>
                </template>    
              </el-table-column>
              <el-table-column prop="comment" label="Comment" width="200">
              <template slot-scope="scope">
                <el-input v-model="scope.row.comment" @change="iseditproject = true" :spellcheck="isspellcheck"
                  :id="'setup_project_setup_project_comment_'+scope.$index"></el-input>
              </template>
            </el-table-column>
            <el-table-column
                prop="createdon"
                label="Created On"
                :formatter="dateformatter"
                width="120"
              >
              </el-table-column>

              <el-table-column
                prop="useincqc"
                label="CQC"
                width="60"
              >
              <template slot-scope="scope">
                  <el-select v-model="scope.row.useincqc" @change="iseditproject = true" clearable  placeholder="CQC"
                      :id="'setup_project_setup_project_cqc_'+scope.$index">
                      <el-option
                        v-for="item in useincqcs"
                        :key="item.value"
                        :label="item.name"
                        :value="item.value">
                      </el-option>
                    </el-select>
              </template>
              </el-table-column>

              <el-table-column
                prop="locked"
                label="Locked"
                width="60"
                align="center"
              >
                <template slot-scope="scope">
                  <el-checkbox
                    v-model="scope.row.locked"
                    :disabled="true"
                    :id="'setup_project_setup_project_locked_'+scope.$index"
                  ></el-checkbox>
                </template>
              </el-table-column>

              <el-table-column
                prop="hide"
                label="Don't Use"
                width="65"
                align="center"
              >
                <template slot-scope="scope">
                  <el-checkbox @change="iseditproject = true" v-model="scope.row.hide" :id="'setup_project_setup_project_dont_use_'+scope.$index"></el-checkbox>
                </template>
              </el-table-column>
            </el-table>
          </template>
          <el-table-column prop="section"  sortable="custom" width="250" label="Section">
            <template slot-scope="scope">
              <!--<el-input v-model="scope.row.section"></el-input>-->
              <!-- || scope.row.copiedfrom > 0 -->
              <el-select
                v-model="scope.row.section"
                filterable=""
                :id="'setup_project_setup_project_section_'+scope.$index"
                placeholder="Section"
                class="fullwidth"
                @change="sectionchange(scope.row)"
                :disabled="scope.row.id > 0 || scope.row.copiedfrom > 0"
              >
                <el-option
                  v-for="item in sections"
                  :key="item.section"
                  :label="item.section"
                  :value="item.section"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="sectiondisplay"  sortable="custom" width="300" label="Section Display">
            <template slot-scope="scope">
              <el-input
              @change="iseditproject = true"
              :spellcheck="isspellcheck"
                :id="'setup_project_setup_project_sectoin_display_'+scope.$index"
                class="inline-input fullwidth"
                @focus="focusdisplay"
                v-model="scope.row.sectiondisplay"
              >
              </el-input>
               <!-- :fetch-suggestions="querySearch"
                @select="handleSelect" -->
            </template>
          </el-table-column>

          <!-- <el-table-column prop="document" label="Document" width="200">
            <template slot-scope="scope">
              <el-input v-model="scope.row.document" :spellcheck="isspellcheck"> </el-input>
            </template>
          </el-table-column> -->

          <!-- <el-table-column prop="version" label="Version" width="100">
            <template slot-scope="scope">
              <el-input v-model="scope.row.version" :spellcheck="isspellcheck"></el-input>
            </template>
          </el-table-column> -->

          <el-table-column prop="comment" label="Comment" width="200">
            <template slot-scope="scope">
              <el-input v-model="scope.row.comment" @change="iseditproject = true" :spellcheck="isspellcheck"
                :id="'setup_project_setup_project_comment_'+scope.$index"></el-input>
            </template>
          </el-table-column>

          <el-table-column
            prop="createdon"
            label="Created On"
            :formatter="dateformatter"
            width="120"
          >
          </el-table-column>

          <el-table-column
            prop="useincqc"
            label="CQC"
            width="60"
          >
          <template slot-scope="scope">
               <el-select v-model="scope.row.useincqc" @change="iseditproject = true" clearable  placeholder="CQC"
                  :id="'setup_project_setup_project_cqc_'+scope.$index">
                   <el-option
                     v-for="item in useincqcs"
                     :key="item.value"
                     :label="item.name"
                     :value="item.value">
                   </el-option>
                 </el-select>
          </template>
          </el-table-column>

          <el-table-column
            prop="locked"
            label="Locked"
            width="60"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox
                v-model="scope.row.locked"
                :disabled="true"
                :id="'setup_project_setup_project_locked_'+scope.$index"
              ></el-checkbox>
            </template>
          </el-table-column>

          <el-table-column
            prop="hide"
            label="Don't Use"
            width="65"
            align="center"
          >
            <template slot-scope="scope">
              <el-checkbox @change="iseditproject = true" v-model="scope.row.hide" :id="'setup_project_setup_project_dont_use_'+scope.$index"></el-checkbox>
            </template>
          </el-table-column>

          <el-table-column label="Copy" fixed="right" width="50">
            <template slot-scope="scope">
              <el-button
                :disabled="!editpermission"
                title="Add Copy"
                size="mini"
                :id="'setup_project_setup_project_copy_'+scope.$index"
                type="primary"
                icon="glyphicon glyphicon-duplicate"
                @click="addCopy(scope.$index, scope.row)"
              ></el-button>
            </template>
          </el-table-column>
        </el-table>

        <el-form-item label="" class="right">
          <el-button
            icon="el-icon-circle-plus"
            type="primary"
            id="setup_project_setup_project_add_single_row"
            :disabled="!editpermission"
            @click="addRowSingle()"
            >Add Single Row</el-button
          >
          <el-button
            icon="el-icon-circle-plus"
            type="primary"
            id="setup_project_setup_project_copy_multiple_sections"
            :disabled="!editpermission"
            @click="addRow()"
            >Copy Multiple Sections</el-button
          >
        </el-form-item>
        <!-- <el-form-item label="">
          <el-button @click="save()" :disabled="!editpermission" type="success" icon="el-icon-circle-check"
            >Save</el-button
          >
          <el-button
          :disabled="!editpermission"
            v-if="detailform.id"
            @click="saveas()"
            type="success"
            icon="el-icon-circle-check"
            >Save as</el-button
          >
          <el-button
            v-if="ProjectPartselect.projectsections.length == 0"
            icon="el-icon-delete"
            type="danger"
            :disabled="!editpermission"
            @click="deleteproject()"
            >Delete Project</el-button
          >
          <el-button icon="el-icon-back" type="info" @click="backdetal"
            >Back</el-button
          >
        </el-form-item> -->
        </div>
      </el-form>

      <br />
    </div>
    <el-dialog
      v-show="partshow"
      title="Project Part Add/Edit"
      :visible.sync="partshow"
      :close-on-click-modal="false"
      width="30%"
    >
      <template>
        <el-form label-width="120px" size="small  ">
          <el-row type="flex" justify="lift">
            <el-col :span="20">
              <el-form-item label="Part Name">
                <el-input
                  id="setup_project_setup_project_part_add_edit_project_name"
                  :spellcheck="isspellcheck"
                  v-model="pojectpartname"
                  placeholder="Part Name"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row type="flex" justify="lift">
            <el-col :span="5">
              <el-form-item>
                <el-button
                  type="success"
                  @click="savePart"
                  id="setup_project_setup_project_part_add_edit_save"
                  icon="el-icon-circle-check"
                  >Ok</el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </template>
    </el-dialog>
    <!-- Copying Multiple Sections -->
    <el-dialog
      v-show="addsection"
      title=""
      :visible.sync="addsection"
      :close-on-click-modal="false"
      width="60%"
      :lock-scroll="false"
      class="titlefontchange changedialgheader-multiple-section chngdialgpadding"
    >
      <template>
           <el-row style="position: inherit;" >
                <el-col :span="20" style="line-height: 24px;
                              font-size: 23px;
                              color: #303133;">
                    Copying Multiple Sections
                </el-col>
                <el-col :span="3">
                    <div class="right" >
                      <el-button
                        type="success"
                        id="setup_project_setup_save_copy_multiple_sections"
                        @click="savesection"
                        icon="el-icon-circle-check"
                        >Ok</el-button
                      >
                      </div>
                </el-col>
                </el-row>
                 <div class="spaceBetween" />
                <el-row> <el-col :offset="2"> <b>Project Part</b></el-col></el-row>
                 <div class="spaceBetween" />
        <el-form label-width="62px" size="small" label-position="left">
          <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="To">
                  {{ ProjectPartselect.name }}
                </el-form-item>
              </el-col>
          </el-row>
            <el-row>
            <el-col :span="24">
                 <el-form-item label="From">
              <el-select
                v-model="partsearch"
                placeholder="Project Parts"
                id="setup_project_setup_select_project_part_for_multiple_sections"
                class="fullwidth"
                style="width: 200px"
                 :clearable="true"
                @change="searchpartsection"
              >
                <el-option
                  v-for="item in detailform.projectparts"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                >
                </el-option>
              </el-select>
            </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-table
              :data="sectiondisplayarr"
              class="tableBox fullwidth"
              ref="sectiondisplayarr"
              size="mini"
            >
              <el-table-column prop="section" label="Copy"  width="50">
                <template slot-scope="scope">
                  <el-checkbox
                    :id="'setup_project_setup_project_a_e_copy_multiple_sec_'+scope.$index"
                    v-model="scope.row.part"
                   @change="selectpartMultipale($event, scope.row)"
                    :disabled="scope.row.partdis && partsearch == ''"

                  ></el-checkbox>
                </template>
              </el-table-column>
              <el-table-column prop="section" label="Section"  width="310">
                <template slot-scope="scope">
                  <!--<el-input v-model="scope.row.section"></el-input>-->
                  <el-select
                    :id="'setup_project_setup_project_a_e_section_'+scope.$index"
                    v-model="scope.row.section"
                    filterable=""
                    placeholder="Section"
                    class="fullwidth"
                    @change="sectionchange(scope.row)"
                    :disabled="scope.row.partdis"
                  >
                    <el-option
                      v-for="item in sections"
                      :key="item.section"
                      :label="item.section"
                      :value="item.section"
                    >
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
                <el-table-column prop="sectiondisplay" id="setup_project_setup_project_a_e_select_display" label="Section Display" width="310"></el-table-column>
            </el-table>
          </el-row>
        </el-form>
        <!-- <div class="spaceBetween"/>
        <el-row type="flex" justify="center">
          <el-button
            type="success"
            @click="savesection"
            icon="el-icon-circle-check"
            >Ok</el-button
          >
        </el-row> -->
      </template>
    </el-dialog>
    <el-dialog width="745px"
    @close="CQCAllocationclose()"
    title="CQC Allocation"
    :visible.sync="cqcbox"
    >
        <div class="CQCAllocation">
         <el-table :data="getcqcsections" width="100%">
          <el-table-column prop="section" width="250" label="Section">
            <template slot-scope="scope">
              <!--<el-input v-model="scope.row.section"></el-input>-->
              <el-select
                v-model="scope.row.section"
                filterable=""
                :id="'setup_project_setup_project_a_e_cqcallocation_select_section_'+scope.$index"
                placeholder="Section"
                class="fullwidth"
                @change="sectionchange(scope.row)"
                :disabled="scope.row.id > 0 || scope.row.copiedfrom > 0"
              >
                <el-option
                  v-for="item in sections"
                  :key="item.section"
                  :label="item.section"
                  :value="item.section"
                >
                </el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column prop="sectiondisplay" width="350" label="Section Display">
            <template slot-scope="scope">
              <el-autocomplete
              :spellcheck="isspellcheck"
                class="inline-input fullwidth"
                :id="'setup_project_setup_project_a_e_cqcallocation_section_display_'+scope.$index"
                v-model="scope.row.sectiondisplay"
                :fetch-suggestions="querySearch"
                @select="handleSelect"
              >
              </el-autocomplete>
            </template>
          </el-table-column>
           <el-table-column
            prop="useincqc"
            label="CQC"
            width="75"
          >
          <template slot-scope="scope">
               <el-select v-model="scope.row.useincqc" clearable  placeholder="CQC"
                :id="'setup_project_setup_project_a_e_cqcallocation_cqc_'+scope.$index">
                   <el-option
                     v-for="item in useincqcs"
                     :key="item.value"
                     :label="item.name"
                     :value="item.value">
                   </el-option>
                 </el-select>
          </template>
          </el-table-column>
         </el-table>
         <div class="spaceBetween" />
         <el-row>
           <el-col :offset="15" :span="6">
                <div class="el-input el-input--mini bold">
                    Unrepresentative (Excl)
                </div>
           </el-col>
           <el-col :span="3">

           </el-col>
         </el-row>
          <div class="spaceBetween" />
         <el-row>
             <div class="el-input el-input--mini bold center">
                 Please tick those duplicates that you wish to use in the Comparative Quantity Checks CQC
             </div>
         </el-row>
         <span slot="footer" class="dialog-footer">
             <el-button @click="Confirmcopy(false)" 
                id="setup_project_setup_project_a_e_cqcallocation_cancel">Cancel</el-button>
             <el-button type="primary" @click="Confirmcopy(true)"
             id="setup_project_setup_project_a_e_cqcallocation_confirm">Confirm</el-button>
           </span>
        </div>
  </el-dialog>
    <el-dialog
      title="Add New or Copy Section"
      :visible.sync="copysection"
      width="30%"
      >
      <el-row>
          <el-radio v-model="copysectionradio" label="1" id="setup_project_setup_project_a_e_add_copy">Copy</el-radio>
          <el-radio v-model="copysectionradio" label="2" id="setup_project_setup_project_a_e_add_add_new">Add New</el-radio>
        </el-row>
        <div class="spaceBetween" />
        <el-row v-if="copysectionradio == '1'">
            <el-col :span="6" style="line-height: 24px;
                              font-size: 12px;
                              color: #303133;">
                    From Section
            </el-col>
            <el-col :span="18">
            <el-select   v-model="selectcopysection" clearable id="setup_project_setup_project_a_e_add_select_copy_section">
                   <el-option
                     v-for="item in getprojectsections"
                     :key="item.id"
                     :label="item.sectiondisplay"
                     :value="item.id">
                   </el-option>
                 </el-select>
                 </el-col>
        </el-row>
      <span slot="footer" class="dialog-footer">
        <el-button @click="()=>{
            copysection = false
            }" id="setup_project_setup_project_a_e_add_cancel">Cancel</el-button>
        <el-button type="primary" @click="addRowSingleconfirm" id="setup_project_setup_project_a_e_add_ok">Ok</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>

export default {
  data() {
    return {
        addddtdata:[],
        rfidsable:false,
        selectcopysection:"",        
        copysectionradio:"2",
        copysection:false,
        sortcol:{order:"ascending",prop:"section"},
        copysec:{},
        copysecindex:null,
        iseditproject:false,
        useincqcall:'',
        cqcsection:"",
        cqcbox:false,
        useincqcs:[
            {name:'Yes',value:'Yes'},
            {name:'No',value:'No'},
            {name:'Excl',value:'Excl'},
        ],
        sectiondisplayarr:[],
        partsearch:"",
      sectionpartget: [],
      part: false,
      projectpartindex: 0,
      addsection: false,
      ProjectPartselect: { name: "", id: 0, projectsections: [] },
      pojectpartname: "",
      partshow: false,
      Projectparts: [{ id: 0, name: "Single Bill" }],
      detail: false,
      form: {},
      detailform: { projectname: "", projectsections: [] },
      tabledata: [],
      grid: { search: "" },
      height: (document.documentElement.clientHeight - 140),
      pagesizes: this.$store.state.pagesizes,
      sections: this.$store.state.sections,
      libraries: this.$store.state.libraries,
      sortorders: this.$store.state.sortorders,
      loading: false,
      showbasaversion: false,
      sortoptions: [
        { label: "Date Created", value: 1 },
        { label: "Date Modified", value: 2 },
        { label: "Date Accessed", value: 3 },
        { label: "Name", value: 4 },
      ],
      sortdata: {
        sorttype: null,
        ordertype: 0
      },
      selectedsortvalue: null,
      sortstate: 0,
    };
  },
  computed: {
    addddtdatalist(){
      console.log(this.addddtdata);
      return this.addddtdata.filter((s) => {
          if (s.projectPartId == this.ProjectPartselect.id) {
            return (!s.locked);
          }});
    },
      getcqcsections(){
          if(this.cqcsection != ""){
              var s = [];
              s = this.getprojectsections.filter(x => x.section == this.cqcsection);
              s.push(this.copysec);
              return s;
          }
      },
    caneditname: function () {
      var canedit = true;
      if (this.detailform.props) {
        canedit = this.detailform.props.caneditname;
      }
      return canedit;
    },
    getprojectsections: function () {
      if (!this.showbasaversion) {
        var sections = this.ProjectPartselect.projectsections.filter((s) => {
          if (s.projectPartId == this.ProjectPartselect.id) {
            return s
          }
        });
            if(this.sortcol.prop != ""){
                return  sections.sort((a,b) => this.mysortfunction(a,b,this.sortcol));
            }else{
                return sections
            }

      } else {
        var sections = this.ProjectPartselect.projectsections.filter((s) => {
          if (s.projectPartId == this.ProjectPartselect.id) {
            return (!s.locked);
          }
        });
        if(this.sortcol.prop != ""){
            return sections.sort((a,b) => this.mysortfunction(a,b,this.sortcol));
        }else{
            return sections
        }
      }
    },
  },
     methods: {
      getsection(){
            // Get NRM section for each page
            if(false){
                // return this.detailform.section;
            }else{
                return "";
            }
        },
        cellchanged(cellaction,callback,cancall){
          const app = this;
          this.$directionutility.setcomponent(app);
          if(cancall){
              callback();
              this.canmatchcellid(()=>{},cellaction,this.getsection());
          }else{
              this.canmatchcellid(callback,cellaction,this.getsection());
          }
        },
        getheight() {
            this.height = (document.documentElement.clientHeight - 140);
        },

        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.grid.pageno = 1;
                this.grid.pagesize = val;
                this.refresh();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },

         focusdisplay(){
            this.sortcol.prop = "section";
         this.sectioneditedsortChange()
         },
         sectioneditedsortChange(sort){
             if(sort != undefined)
             {
               this.sortcol = sort;
             }
          this.ProjectPartselect.projectsections =   this.ProjectPartselect.projectsections.map(x=> {return x;})
         },
          mysortfunction(a, b,coll) {

           var o1 = a[coll.prop];
           var o2 = b[coll.prop];
        //    var p1 = "";var p2 = "";
        //    if(this.issd == true){
            //   var p1 = a.sectiondisplay;
            //   var p2 = b.sectiondisplay;
        //    }
        if(coll.order != "descending"){
           if (o1 < o2) return -1;
           if (o1 > o2) return 1;
        }else{
            if (o1 < o2) return 1;
           if (o1 > o2) return -1;
        }
        //    if(this.issd == true){
            //    if (p1 < p2) return -1;
            //    if (p1 > p2) return 1;
        //    }
           return 0;
         },
         Confirmcopy(copy){
             if(copy)
             {
                 this.ProjectPartselect.projectsections.splice(this.copysecindex + 1, 0, this.copysec);
                 this.copysec = {};
                 this.copysec = null;
                 this.iseditproject = true;
             }else{
                 this.copysec = {};
                 this.copysec = null;
             }
             this.cqcbox = false;
         },
        beforewindowunload: function (e) {
            //Prevent accidently close/reload window
            if (this.detail &&  this.iseditproject) {
                //return window.confirm('Do you really want to leave? you have unsaved changes!');
                return "Do you really want to leave ? you have unsaved changes!";
            }
        },
         CQCAllocationclose(){
             this.$set(this,'useincqcall',"");
             this.$set(this,'cqcsection',"");

         },
         useincqcallchange(){
             this.getcqcsections.forEach(x=>{
                  x.useincqc = this.useincqcall;
                 })
         },
    backdetal: function () {
        var answer = true;
            if (this.detail &&  this.iseditproject) {
                var answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            }
            if (answer == true) {
                this.ProjectPartselect.projectsections = [];
                this.ProjectPartselect.id = 0;
                this.ProjectPartselect.name = "";
                this.detailform.projectsections = [];
                this.detail = false;
                this.iseditproject = false;
            }
    },
    selectpartMultipale: function (event, row) {
        this.iseditproject = true;
      if(this.partsearch != ""){
        row.part = event
      }else{
      row.part = event;
      if (event == false) {
        this.ProjectPartselect.projectsections = this.ProjectPartselect.projectsections.filter(
          (s) => {
            if (s.section == row.section) {
              return;
            } else {
              return s;
            }
          }
        );
      }
      row.part = event;
      }
    },
    selectpart: function (event) {
        this.issd = true
      this.detailform.projectparts.filter((s) => {
        if (event == s.id) {
          this.ProjectPartselect.name = s.name;
          this.ProjectPartselect.id = s.id;
        }
      });
    },
    searchpartsection:function(event){
        if(event === "")
        {
           this.addRow();
        }else{

           this.sectiondisplayarr  = this.ProjectPartselect.projectsections.map((x) => {
                return {
                sectionid:x.id,
                section: x.section,
                sectiondisplay: x.sectiondisplay,
                locked: false,
                part: false,
                projectPartId:x.projectPartId,
                };
            });
           this.sectiondisplayarr = this.sectiondisplayarr.filter((s) => {
             if (s.projectPartId == this.partsearch) {
                 return s
               }
             });
              //this.sectiondisplayarr = t;
        }
    },
    savesection: function () {

      var myarray = [];
      this.sectiondisplayarr.forEach((element, index) => {

        if (element.part == true) {

          var ps = {};
          ps.locked = element.locked;
          ps.projectPartId = this.ProjectPartselect.id;
          ps.part = element.part;
          ps.section = element.section;
          ps.sectiondisplay =  element.sectiondisplay;
          ps.projectPartId = this.ProjectPartselect.id;

          if(element.sectionid != undefined){
              ps.copiedfrom = element.sectionid
          }
          this.ProjectPartselect.projectsections.push(ps);
        }
      });
      this.sectionpartget.push(...this.ProjectPartselect.projectsections);
      this.ProjectPartselect.projectsections = this.sectionpartget.filter((s, index) => {
        if (s.projectPartId != this.ProjectPartselect.id) {
          return s;
        } else {
          myarray.push(s);
          return;
        }
      });
      var k = myarray.filter(
        ((set) => (f) => !set.has(f.sectiondisplay) && set.add(f.sectiondisplay))(new Set())
      );
      this.ProjectPartselect.projectsections.push(...k);
      this.sectionpartget = [];
      this.detailform.projectsections = this.sections.map((x) => {
        return {
          section: x.section,
          sectiondisplay: x.section,
          locked: false,
          part: false,
        };
      });
      this.addsection = false;
    },
    editprojectpart: function (index) {
      this.iseditproject = true;
      this.pojectpartname = this.detailform.projectparts[index].name;
      this.projectpartindex = index;
      this.partshow = true;
    },
    savePart: function () {
         if(this.detailform.projectparts[0].name == "Single Bill" ){
          this.projectpartindex = 0;
          }
      if (this.projectpartindex == this.detailform.projectparts.length) {
        this.detailform.projectparts.push({
          id: this.projectpartindex,
          name: this.pojectpartname,
        });
      } else {
        this.detailform.projectparts[
          this.projectpartindex
        ].name = this.pojectpartname;
        if (this.detailform.projectparts[this.projectpartindex].id <= 0) {
          this.detailform.projectparts[
            this.projectpartindex
          ].id = this.projectpartindex;
        } else {
          this.projectpartindex = this.detailform.projectparts[
            this.projectpartindex
          ].id;
        }
      }
      this.ProjectPartselect.name = this.pojectpartname;
      // if (this.detailform.projectparts[this.projectpartindex].id <= 0) {
      this.ProjectPartselect.id = this.projectpartindex;
      //}
      this.projectpartindex = 0;
      this.pojectpartname = "";
      this.partshow = false;
    },
    addprojectpart() {
        this.iseditproject = true;
      this.projectpartindex = this.detailform.projectparts.length;
      this.partshow = true;
    },
    onSearch: function () {
      this.grid.pageno = 1;
      this.refresh();
    },
    onSearchbykey(row,key,val){
      row[key] = val;
      this.grid.pageno = 1;
      this.refresh(); 
    },
    // searchProject: function (query, done) {
    //   // console.log(new Date().toJSON() + "Query Fired:" + query);
    //   this.$http
    //     .post("project/search?q=" + query, {})
    //     .then((response) => {
    //       var templates = JSON.parse(response.data);
    //       done(templates);
    //     })
    //     .catch((err) => {
    //       this.$bus.$emit("error", err);
    //       //console.log(err.response.data);
    //       //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
    //     });
    // },
    dateformatter: function (row, column, value, index) {
      if (value) {
        var d = new Date(value);
        return d.toDateString();
      }
      return value;
    },
    handleEdit: function (scope, row) {
      // this.loading = true;
      this.$store.state.isLoading = true;

      this.$http
        .post("project/get", row)
        .then((response) => {
          var json = response.data;
          this.detailform = json;
          if(this.detailform.usepart === undefined || this.detailform.usepart === null){
            this.rfidsable = false;
          }else{
            this.rfidsable = true;
          }
          if(this.detailform.projectparts.length <= 0){
              this.detailform.projectparts.unshift({ id: 0, name: "Single Bill" });
               this.ProjectPartselect.name = "Single Bill";
               this.ProjectPartselect.id = 0;
          }else{
               this.ProjectPartselect.name = this.detailform.projectparts[0].name;
               this.ProjectPartselect.id = this.detailform.projectparts[0].id;
          }
          this.addddtdata = [];
          this.addddtdata =  this.detailform.projectsections.filter(x => x.isaddddt == true);
          // console.log(addddtsection);
          this.ProjectPartselect.projectsections = this.detailform.projectsections.filter(x => x.isaddddt == false);
          this.ProjectPartselect.projectsections.forEach((s) => {
            s.part = true;
            s.partdis = true;
          });
          this.detailform.projectsections = this.sections.map((x) => {
            return {
              section: x.section,
              sectiondisplay: x.section,
              locked: false,
              part: false,
              partdis: false,
            };
          });
          this.detail = true;
          // this.loading = false;
          this.$store.state.isLoading = false;

        })
        .catch((err) => {
          // this.loading = false;
          this.$store.state.isLoading = false;
          this.$bus.$emit("error", err);
          //console.log(err);
          //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
        });
    },

    handleDelete: function (index, row) {
      this.detailform.projectsections.splice(index, 1);
    },

    currentChange: function (val) {

      this.grid.pageno = val;
      this.refresh();
    },

    sortChange: function (sort) {
      this.grid.sortcol = sort.prop;
      this.grid.sortorder = sort.order;
      this.refresh();
    },

    sectionchange: function (row) {
        // if (!row.document || row.document == row.sectiondisplay) {
        //            row.document = row.section;
        //          }
        this.iseditproject = true;
                row.sectiondisplay = row.section;
    },

    refresh: function () {
      // this.loading = true;
      this.$store.state.isLoading = true;

      this.$http
        .post("project/getgrid", this.grid)
        .then((response) => {
          //var json = JSON.parse(response.data);
          var json = response.data;
          this.tabledata = json.data;
          this.grid = json.grid;
          // this.loading = false;
          this.$store.state.isLoading = false;
          this.getprojectsortpreferences();
        })
        .catch((err) => {
          // this.loading = false;
          this.$store.state.isLoading = false;
          this.$bus.$emit("error", err);
          //console.log(err);
          //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
        });
    },

    createNew: function () {

              this.detailform = {
        projectname: "",
        library: "",
        sortorder: "",
        billdecimal: 0,
        projectparts: [{ name: "Single Bill", id: 0 }],
      };
      this.ProjectPartselect.id = 0;
      this.ProjectPartselect.name = "Single Bill";
      this.detailform.projectsections = this.sections.map((x) => {
        return {
          section: x.section,
          sectiondisplay: x.section,
          locked: false,
          part: false,
          partdis: false,
        };
      });
      this.detail = true;
      this.rfidsable = false;
    },
    updateproject: function (p) {
      this.$http
        .post("project/updateproject", p)
        .then((response) => {
          this.$message({
            showClose: true,
            message: response.data,
            type: "success",
          });
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
        });
    },


    validate: function (callback) {
      var msg = [];
     for (var i = 0; i < this.detailform.projectsections.length; i++) {
                        var a = this.detailform.projectsections[i];
                          var partname = this.detailform.projectparts.find(p=> p.id == a.projectPartId)
                        if(a.section == ""){
                            msg.push("Section Name must not be empty for Part:  " + partname.name);
                        }
                        if(a.sectiondisplay.length > 60)
                        {
                            msg.push("You have reached the maximum limit of 60 characters for Section Display:" + a.sectiondisplay + " Part:  " + partname.name);
                        }
                        for (var j = i + 1; j < this.detailform.projectsections.length; j++) {
                            var b = this.detailform.projectsections[j];
                            if (a.projectPartId == b.projectPartId && a.sectiondisplay == b.sectiondisplay) {
                                var msgname = "Part " + partname.name +": Section Display '" + this.detailform.projectsections[i].sectiondisplay + "' is duplicate";
                                var check =  msg.find(x=> x == msgname);
                                if(!check){
                                msg.push(msgname);
                                }
                            }
                        }
                    }
                    for (let i = 0; i < this.addddtdata.length; i++) {
                      const a = this.addddtdata[i];
                        var partname = this.detailform.projectparts.find(p=> p.id == a.projectPartId)
                          if(a.section == ""){
                              msg.push("Section Name must not be empty for Part:  " + partname.name);
                          }
                          if(a.sectiondisplay.length > 60)
                          {
                              msg.push("You have reached the maximum limit of 60 characters for Section Display:" + a.sectiondisplay + " Part:  " + partname.name);
                          }
                          for (var j = i + 1; j < this.addddtdata.length; j++) {
                              var b = this.addddtdata[j];
                              if (a.projectPartId == b.projectPartId && a.sectiondisplay == b.sectiondisplay) {
                                  var msgname = "Part " + partname.name +": Section Display '" + this.addddtdata[i].sectiondisplay + "' is duplicate in Add & Ddt section";
                                  var check =  msg.find(x=> x == msgname);
                                  if(!check){
                                    msg.push(msgname);
                                  }
                              }
                          }
                    }
                     for (var i = 0; i < this.detailform.projectparts.length; i++) {
                        var a = this.detailform.projectparts[i];
                        if(a.name == ""){
                            msg.push("Project Part Name must not be empty");
                        }
                        for (var j = i + 1; j < this.detailform.projectparts.length; j++) {
                            var b = this.detailform.projectparts[j];
                            if (a.name == b.name ) {
                                var msgname = "Part: " + a.name  + " is duplicate";
                                var check =  msg.find(x=> x == msgname);
                                if(!check){
                                msg.push(msgname);
                                }
                            }
                        }
                    }
      if (!this.detailform.projectparts) {
        msg.push("Project Part must not be empty");
      }
      if (!this.detailform.projectname) {
        msg.push("Project Name must not be empty");
      }
      if (!this.detailform.sortorder) {
        msg.push("Sort order must not be empty");
      }
      if (!this.detailform.library) {
        msg.push("Library must not be empty");
      }
      if (msg.length > 0) {
        var style = "<style> .el-message-box {width:50%;} </style>";
        this.$alert(msg.join("<br />") + style, "Invalid Data", {
          dangerouslyUseHTMLString: true,
        });
        callback(false);
       this.detailform.projectsections = this.sections.map((x) => {
        return {
          section: x.section,
          sectiondisplay: x.section,
          locked: false,
          part: false,
          partdis: false,
        };
      });
      } else {
        callback(true);
      }
    },
    save: function () {
        // if(this.detailform.projectparts[0].id == 0 && this.detailform.projectparts.length == 1){
        //     this.detailform.projectparts = [];
        // }
     this.detailform.projectsections = this.ProjectPartselect.projectsections;
      this.validate((valid) => {
        if (valid) {
          //save
          // console.log(this.projectsavesection);
          //  console.log(this.detailform);
          this.detailform.addddtsections = this.addddtdata;
          this.showloading();
          this.$http
            .post("project/save", this.detailform)
            .then((response) => {
                  this.hideloading();
              this.$message({
                showClose: true,
                message: response.data,
                type: "success",
              });
              // this.ProjectPartselect.projectsections = [];
              // this.ProjectPartselect.id = 0;
              // this.ProjectPartselect.name = "";
              // this.detailform.projectsections = [];
              // this.detail = false;
              // this.$refs.detailform.resetFields();
              // this.refresh();

              this.iseditproject = false;
            })
            .catch((err) => {
                  this.hideloading();
              this.$bus.$emit("error", err);
              //console.log(err.response.data);
              //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
              //this.$alert(err.data, err.response.status + ' ' + err.response.statusText);
            });
        } else {
          return false;
        }
      });
    },

    deleteproject: function () {
      this.$confirm(
        "This will permanently delete " +
          this.detailform.projectname +
          ". Continue?",
        "Warning",
        {
          confirmButtonText: "OK",
          cancelButtonText: "Cancel",
          type: "warning",
        }
      )
        .then(() => {
          this.$http
            .post("project/delete", this.detailform)
            .then((response) => {
              this.$message({
                showClose: true,
                type: "success",
                message: "Delete completed",
              });
              this.detail = false; //back
            })
            .catch((err) => {
              this.$bus.$emit("error", err);
            });
        })
        .catch(() => {
          this.$message({
            showClose: true,
            type: "info",
            message: "Delete canceled",
          });
        });
    },
    saveas: function (item, event) {
         this.detailform.projectsections = this.ProjectPartselect.projectsections;
      this.validate((valid) => {
        if (valid) {
      this.$prompt("Please enter new project name", "Tip", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        inputValue: this.detailform.projectname + " - Copy",
      }).then((value) => {
          //  this.loading = true;
          this.$store.state.isLoading = true;

        //this.detailform.id = 0; need to call save as function and id is required
        this.detailform.projectname = value.value;
        //this.detailform.projectsections = this.ProjectPartselect.projectsections;
        this.showloading();
        this.$http
          .post("project/saveas", this.detailform)
          .then((response) => {
                this.hideloading();
            this.$message({
              showClose: true,
              message: response.data,
              type: "success",
            });
            this.ProjectPartselect.projectsections = [];
            this.ProjectPartselect.id = 0;
            this.ProjectPartselect.name = "";
            this.detailform.projectsections = [];
            this.detail = false;
            this.$refs.detailform.resetFields();
            this.refresh();
            // this.loading = false;
            this.$store.state.isLoading = false;
          })
          .catch((err) => {
                this.hideloading();
               this.detail = false;
              //  this.loading = false;
              this.$store.state.isLoading = false;
            this.$bus.$emit("error", err);
          });
      });
        }
       });
    },

    addCopy: function (index, row) {
      var ps = JSON.parse(JSON.stringify(row));
      ps.copiedfrom = ps.id;
      ps.sectiondisplay = ps.sectiondisplay + " - Copy";
      ps.id = 0;
      ps.projectPartId = this.ProjectPartselect.id;
      ps.useincqc = "";
      ps.comment = "";
      this.copysec = ps;
      this.copysecindex = index;


        this.cqcbox = true;
        this.cqcsection = row.section;

    },
    addRowSingleconfirm(){
        if(this.copysectionradio == "1"  && this.selectcopysection === "")
        {
            this.$message({
               showClose: true,
               message: 'Please Select Section First Or Select Add New !',
               type: 'warning'
             });
             return;
        }
      this.iseditproject = true;
      var ps = {section:'',sectiondisplay:'',document:'',version:'' ,comment:'' ,locked:false,hide:false};
      if(this.selectcopysection != "")
      {
          ps.copiedfrom = parseInt(this.selectcopysection);
          var csection = this.getprojectsections.find(x=> x.id == this.selectcopysection);
          ps.section = csection.section;
          ps.sectiondisplay = csection.sectiondisplay + "- Copy";
          ps.description = csection.description;
          this.selectcopysection = "";
      }
      ps.id = 0;
      ps.projectPartId = this.ProjectPartselect.id;
      ps.part = true;
      this.ProjectPartselect.projectsections.push(ps);
      this.copysection= false;


    },
    addRowSingle: function () {
     //       if(this.showbasaversion == true){
     //       this.showbasaversion = false;
     //   }
      this.copysectionradio = "2";
      this.selectcopysection = "";
      this.copysection = true;

    },
    addRow: function () {

        this.partsearch = "";
         this.sectiondisplayarr = this.sections.map((x) => {
        return {
          section: x.section,
          sectiondisplay: x.section,
          locked: false,
          part: false,
          projectPartId:0,
        };
      });
    //    if(this.showbasaversion == true){
    //       this.showbasaversion = false;
    //   }
      this.sectiondisplayarr.forEach((ps) => {
        this.ProjectPartselect.projectsections.forEach((sp) => {
          if (
            sp.part == true &&
            sp.section == ps.section &&
            sp.projectPartId == this.ProjectPartselect.id
          ) {
            ps.part = true;
            ps.sectiondisplay = sp.sectiondisplay;
          }
          if (
            sp.partdis == true &&
            sp.part == true &&
            sp.section == ps.section &&
            sp.projectPartId == this.ProjectPartselect.id
          ) {
            ps.partdis = true;
            ps.sectiondisplay = sp.sectiondisplay;
          }
        });
        return !ps.locked;
      });
      this.addsection = true;
    },

    querySearch: function (query, done) {
      query = query ? query.toLowerCase() : "";
      var sections = this.sections.filter((s) => {
        s.section.toLowerCase().indexOf(query) >= 0;
      });
      //filter don't show already added sections
      sections = sections.filter(
        (s) =>
          !this.detailform.projectsections.some(
            (ps) => ps.section.toLowerCase() == s.section.toLowerCase()
          )
      );

      sections = sections.map((v) => {
        return { value: v };
      });

      done(sections);
    },

    handleSelect: function (item, value) {
      //item.template = value;
      //item.templateid = value.id;
    },

    saveprojectsortpreferences: function () {
      debugger
      if (this.sortdata.sorttype <= 0) {
        this.sortdata.sorttype = null;
        this.sortdata.ordertype = 0;
      }

      this.$http
        .post("project/SaveProjectSortPreferences", this.sortdata)
        .then((response) => {
          this.sortdata = response.data;
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
        });
    },

    getprojectsortpreferences: function () {
      this.$http
        .post("project/GetProjectSortPreferences")
        .then((response) => {
          debugger
          if (response.data) {
            this.sortdata = response.data;
          }
        })
        .catch((err) => {
          this.$bus.$emit("error", err);
        });
    },

    toggleSort() {
      debugger
      if (this.sortdata.ordertype === 0) {
        this.sortdata.ordertype = 1;
      } 
      else if (this.sortdata.ordertype === 1) {
        this.sortdata.ordertype = 2;
      } 
      else {
        this.sortdata.ordertype = 0;
      }

      this.saveprojectsortpreferences();
    },
  },

    // beforeDestroy: function () {
    //      if(this.detail &&  this.iseditproject){

    //      }
    // },
     beforeRouteLeave(to, from, next) {
            var answer = true;
            if (this.detail &&  this.iseditproject) {
                var answer = window.confirm('Do you really want to leave? you have unsaved changes!');
            }
            if (answer == true) {
                next()
                window.onbeforeunload = null;
            } else {
                next(false)
            }
            window.clearInterval(this.interval);
        },
  created: function () {
       this.$bus.$on('setsection', (sections) => {
                    this.sections = sections
              })
        let gridrow = this.$training.getusergridrow(this.$route);
        this.grid.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;              
    this.refresh(); //refresh is not required here because sortchange event will be executed immediately
    if (window.File && window.FileReader && window.FileList && window.Blob) {
      // Great success! All the File APIs are supported.
    } else {
      alert("The File APIs are not fully supported in this browser.");
    }
     window.onbeforeunload = this.beforewindowunload;
  },
    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },
};
</script>
<style >
.el-table th.el-table_cell .el-table.cell{
  padding-left: 0px;
  padding-right: 0px;
}
.el-table-column{
padding-left: 0px;
padding-right: 0px;
}
.el-input-number--mini {
  width: 100%;
}

.el-input-number .el-input__inner {
  text-align: right;
}

.el-form-item--mini.el-form-item,
.el-form-item--small.el-form-item {
  margin-bottom: 0px;
}

.el-form-item {
  margin-bottom: 0px;
}

.tableBox.el-table--mini td, .el-table--mini th {
    padding: 0px 0;
}


.custom-select {
  display: flex;
  align-items: center;
}
.custom-el-select .el-input__inner {
  padding-right: 30px; /* Make space for the custom icons */
}
.custom-el-select .el-input__suffix {
  right: 5px; /* Adjust position of suffix */
  display: flex;
  align-items: center;
}
.sort-icons {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
.sort-icons i {
  color: #bfbfbf;
}
.sort-icons i.active {
  color: #409eff; /* Active color */
}


.projectsortselectcaret-wrapper {
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    width: 24px;
    vertical-align: middle;
    cursor: pointer;
    overflow: initial;
    position: relative;
    padding: 0px 13px !important;
    height: 28px;
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
}

.projectsortselect-caret {
    width: 0;
    height: 0;
    border: 5px solid transparent;
    position: absolute;
    left: 7px;
}

.projectsortselect-caret.ascending {
    border-bottom-color: #C0C4CC;
    top: 2px;
}

.projectsortselect-caret.descending {
    border-top-color: #C0C4CC;
    bottom: 2px;
}

.projectsortselect-caret.active.ascending {
  border-bottom-color: #409eff; /* Highlight active ascending */
}

.projectsortselect-caret.active.descending {
  border-top-color: #409eff; /* Highlight active descending */
}

</style>
