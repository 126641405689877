<template>
    <div>
        <div v-if="!isedit" class="directionseditstyletable">
            <div class="fixdheader">
                <el-row>
                    <span class="headerstyle left paddingr83">Directions Library</span>
                    <span class="left" style="width:430px;">
                        <el-pagination class="pagesizeswidth textalignlpage paddingtop" @size-change="rowperpages"
                            @current-change="refresh" :current-page.sync="view.pageno" :page-sizes="pagesizes"
                            :page-size="view.pagesize" layout="sizes, prev, pager, next" :total="view.count">
                        </el-pagination>
                    </span>
                    <span class=" headerstyle floatright ">
                        <el-button type="primary" :disabled="!editpermission" @click="createNew()"
                            icon="el-icon-circle-plus">Create New&nbsp;</el-button>
                    </span>
                </el-row>
                <el-row>
                    <div class="left padright5px">
                        <el-input :spellcheck="isspellcheck" v-model="view.filter[0].value" placeholder="Directions Name"
                            :id="'library_directionslibrary_filter_directionsname'" @keyup.enter.native="onSearch()"
                            class="bold width260px">
                            <el-button slot="append" icon="el-icon-search" class="" circle @click="onSearch"></el-button>
                        </el-input>
                    </div>
                    <div class="left paddingl13">
                        <!-- <el-autocomplete class="inline-input width260px bold" v-model="view.filter[1].value"
                            :fetch-suggestions="querySection" placeholder="Section" @select="onSearch"
                            :spellcheck="isspellcheck">
                            <el-button slot="suffix" @click="clearsection" type="text" size="mini">&times;</el-button>
                        </el-autocomplete> -->
                        <el-select v-model="view.filter[1].value" @change="onSearch" :filterable="true" :clearable="true" 
                            :id="'library_directionslibrary_filter_offercloseoptions'" placeholder="Offer Close Options"
                            class="fullwidth">
                            <el-option
                                v-for="item in [{ value: 'error', label: 'Requirement to Correct' }, { value: 'warning', label: 'Warning correction needed' }]"
                                :key="item.value" :label="item.label" :value="item.value">
                            </el-option>
                        </el-select>
                    </div>

                </el-row>
                <hr class="hrstyle" />
            </div>
            <div>
                <el-table :data="tabledata" :height="height"
                    :default-sort="{ prop: 'dd.directionname', order: 'ascending' }" @sort-change="sortChange" align="left">
                    <el-table-column prop="dd.directionname" sortable="custom" label="Direction Name"
                        width="280"></el-table-column>
                    <el-table-column prop="dd.offercloseoption" label="Offer Close Options" sortable="custom" width="180">
                        <template slot-scope="scope">
                            <div v-if="scope.row.dd.offercloseoption == 'error'">
                                {{ "Requirement to Correct" }}
                            </div>
                            <div v-else>
                                {{ "Warning correction needed" }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="dd.description" label="Description" sortable="custom"
                        width="270"></el-table-column>
                    <el-table-column prop="ca.displayname" label="Action's" width="200"></el-table-column>
                    <el-table-column prop="author.loginname" label="Author" width="60"></el-table-column>
                    <el-table-column label="Actions" align="center" width="110">
                        <template slot-scope="scope">
                            <el-button size="mini" class="padding7" type="primary" title="Edit"
                                :icon="editpermission == true ? 'el-icon-edit' : 'el-icon-view'"
                                @click="handleEdit(scope.$index, scope.row)"> </el-button>
                            <el-button size="mini" class="padding7" type="primary" title="Copy"
                                icon="glyphicon glyphicon-duplicate" @click="handleCopy(scope.$index, scope.row)"
                                :disabled="!editpermission"></el-button>

                            <el-button size="mini" class="padding7" type="danger" title="Delete" icon="el-icon-delete"
                                @click="handleDelete(scope.$index, scope.row)" :disabled="!editpermission"></el-button>

                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>

        <div class="directionseditstyleedit" v-if="isedit">
            <div class="fixdheader">
                <el-row>
                    <span class="headerstyle left paddingr83">Directions Creation Form</span>
                </el-row>
                <hr class="hrstyle" />
            </div>
            <div>
                <el-form ref="detailform" :model="FormData" label-position="left" label-width="150px" size="mini">
                    <el-row>
                        <el-form-item label="Direction Name" class="labelheight">
                            <el-col>
                                <el-input placeholder="Direction Name"
                                    :id="'library_directionslibrary_addedit_directionname'"
                                    v-model="FormData.directionname"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="Description" class="labelheight">
                            <el-col>
                                <el-input type="textarea" :rows="2" placeholder="Description"
                                    :id="'library_directionslibrary_addedit_description'" v-model="FormData.description"
                                    show-word-limit maxlength="50">
                                </el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="Page Location" class="labelheight">
                            <el-col>
                                <el-select v-model="FormData.pageid" :filterable="true" @change="pagecellvalidation"
                                    class="fullwidth" :id="'library_directionslibrary_addedit_pagelocation'"
                                    placeholder="Page Location">
                                    <el-option v-for="item in pagelist" :disabled="pagedisable(item)" :key="item.p.id"
                                        :label="item.p.displayname" :value="item.p.id">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <!-- <el-form-item label="Cell Location A" class="labelheight">
                                <el-col>
                                    <el-input placeholder="Cell Location" v-model="FormData.celllocation"></el-input>
                                    <el-select v-model="FormData.pagecellid"  class="fullwidth" placeholder="Cell Location">
                                        <el-option v-for="item in pagecells" :key="item.id" :label="item.displayname"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item> -->


                        <div v-for="(dcdetail, index) in FormData.directioncelldetails" :key="dcdetail.id">
                            <el-form-item :label="cellLocationLabel(dcdetail, index)" class="labelheight">
                                <!-- <el-co> -->
                                <el-select v-model="dcdetail.pagecellid" class=""
                                    :id="'library_directionslibrary_addedit_celllocation_pagecellid_' + index"
                                    placeholder="Cell Location" style="width: 85%" :filterable="true" clearable @change="changepagecell">
                                    <el-option v-for="item in pagecells" :key="item.id" :label="item.displayname"
                                        :value="item.id" :disabled="disableusedcellitems(item.id)">
                                    </el-option>
                                </el-select>

                                <el-button v-if="index === 0" @click="addCellLocation()" class="padding7px7px marginleft4px"
                                    type="primary" icon="el-icon-circle-plus" :disabled="!editpermission"></el-button>
                                <el-button v-else @click="deleteCellLocation(index)" class="padding7px7px marginleft4px"
                                    type="danger" icon="el-icon-delete" :disabled="!editpermission"></el-button>
                                <el-button type="primary" @click="toggleExpand(index)" class="padding7px7px"
                                    :icon="dcdetail.isExpanded ? 'el-icon-caret-top' : 'el-icon-caret-bottom'"></el-button>
                                <!-- </el-co> -->
                            </el-form-item>

                            <div v-show="dcdetail.isExpanded">
                                <div v-show="dcdetail.pc && dcdetail.pc.celltype
                                    && (dcdetail.pc.celltype == '' || dcdetail.pc.celltype == 'regex' ||
                                        dcdetail.pc.celltype == 'file' ||
                                        dcdetail.pc.celltype == 'radiobutton' ||
                                        dcdetail.pc.celltype == 'date & time')">
                                    <el-form-item label="Format Rule" class="labelheight">
                                        <el-col>
                                            <el-input type="textarea" :rows="2" placeholder="Format Rule"
                                                :id="'library_directionslibrary_addedit_celllocation_expand_formatrules_' + index"
                                                v-model="dcdetail.formatrule" show-word-limit maxlength="120">
                                            </el-input>
                                        </el-col>
                                    </el-form-item>

                                    <el-form-item label="Exclude Rules" class="labelheight">
                                        <el-col>
                                            <el-input type="textarea" :rows="2" placeholder="Exclude Rules"
                                                :id="'library_directionslibrary_addedit_celllocation_expand_excluderules_' + index"
                                                v-model="dcdetail.excluderule" show-word-limit maxlength="120">
                                            </el-input>
                                        </el-col>
                                    </el-form-item>

                                    <el-form-item label="Include Rules" class="labelheight">
                                        <el-col>
                                            <el-input type="textarea" :rows="3" placeholder="Include Rules"
                                                :id="'library_directionslibrary_addedit_celllocation_expand_includerules_' + index"
                                                v-model="dcdetail.includerule" show-word-limit maxlength="240">
                                            </el-input>
                                        </el-col>
                                    </el-form-item>
                                </div>

                                <div v-show="dcdetail.pc && dcdetail.pc.celltype && dcdetail.pc.celltype == 'number'">
                                    <el-form-item label="Check Value" class="labelheight">
                                        <el-col>
                                            <el-input-number class="inputleft_number" placeholder="Check Value"
                                                :id="'library_directionslibrary_addedit_celllocation_expand_checkvalue_' + index"
                                                :controls="false" v-model="dcdetail.checkvalue">
                                            </el-input-number>
                                        </el-col>
                                    </el-form-item>

                                    <el-form-item label="Operator" class="labelheight">
                                        <el-col>
                                            <el-select class="reportname_width" placeholder="Operator" :filterable="true" :clearable="true" 
                                                :id="'library_directionslibrary_addedit_celllocation_expand_operation_' + index"
                                                v-model="dcdetail.operation">
                                                <el-option v-for="item in operations" :key="item.value" :label="item.label"
                                                    :value="item.value">
                                                </el-option>
                                            </el-select>
                                        </el-col>
                                    </el-form-item>
                                </div>

                                <div v-show="dcdetail.pc && dcdetail.pc.celltype && dcdetail.pc.celltype == 'checkbox'">
                                    <el-form-item label="Check Box" class="labelheight">
                                        <el-col>
                                            <el-checkbox class="archiveborderedtickbox" :border="true"
                                                :id="'library_directionslibrary_addedit_celllocation_expand_ischeckbox_' + index"
                                                v-model="dcdetail.ischeckbox"
                                                @change="changechecked($event, dcdetail, index)">
                                            </el-checkbox>
                                        </el-col>
                                    </el-form-item>
                                </div>

                            </div>
                        </div>


                        <!-- <el-form-item label="Cell Location B" class="labelheight">
                                <el-col>
                                    <el-select v-model="FormData.pagecellid2"  class="fullwidth" placeholder="Cell Location">
                                        <el-option v-for="item in pagecells" :key="item.id" :label="item.displayname"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item>

                            <el-form-item label="Cell Location C" class="labelheight">
                                <el-col>
                                    <el-select v-model="FormData.pagecellid3"  class="fullwidth" placeholder="Cell Location">
                                        <el-option v-for="item in pagecells" :key="item.id" :label="item.displayname"
                                            :value="item.id">
                                        </el-option>
                                    </el-select>
                                </el-col>
                            </el-form-item> -->

                        <el-form-item label="Action" class="labelheight">
                            <el-col>
                                <el-select v-model="FormData.cellactionid" :filterable="true" class="fullwidth"
                                    :id="'library_directionslibrary_addedit_action'" placeholder="Action">
                                    <el-option v-for="item in cellaction" :key="item.id" :label="item.displayname"
                                        :value="item.id">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="Message Box Type" class="labelheight">
                            <el-col>
                                <el-select v-model="FormData.messageboxtype" class="fullwidth" :filterable="true"
                                    :id="'library_directionslibrary_addedit_messageboxtype'" placeholder="Message Box Type">
                                    <el-option v-for="item in boxtypes" :key="item" :label="item" :value="item">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="NRM Section (Filter)" class="labelheight">
                            <el-col>
                                <!-- <el-select v-model="FormData.sectionid" class="fullwidth"
                                        placeholder="NRM Section (Filter)">
                                        <el-option v-for="item in sections" :key="item.section" :label="item.section"
                                            :value="item.id">
                                        </el-option>
                                    </el-select> -->
                                <el-select :disabled="!editpermission" multiple value-key="section"
                                    :id="'library_directionslibrary_addedit_nrmsection'"
                                    v-model="FormData.directionsections" placeholder="Sections" @change="handlesectionselection" class="fullwidth">
                                    <el-option v-for="item in sections" :disabled="!editpermission" :key="item.section"
                                        :label="item.section" :value="item">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>

                        <el-form-item label="Filter By User Name" class="labelheight">
                            <el-col>
                                <el-select v-model="FormData.userids" class="fullwidth"
                                    :id="'library_directionslibrary_addedit_filterbyusername'" placeholder="Users"
                                    @change="handleUserSelection" multiple :disabled="!editpermission" clearable>
                                    <el-option v-for="item in userlist" :key="item.id" :label="item.fullname"
                                        :value="item.id" :disabled="!editpermission">
                                    </el-option>
                                </el-select>
                            </el-col>
                        </el-form-item>

                        <!-- <el-form-item label="Format Rule" class="labelheight">
                                <el-col>
                                    <el-input type="textarea" :rows="2" placeholder="Format Rule"
                                        v-model="FormData.formatrule" show-word-limit maxlength="120">
                                    </el-input>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="Exclude Rules" class="labelheight">
                                <el-col>
                                    <el-input type="textarea" :rows="2" placeholder="Exclude Rules"
                                        v-model="FormData.excluderule" show-word-limit maxlength="120">
                                    </el-input>
                                </el-col>
                            </el-form-item>
                            <el-form-item label="Include Rules" class="labelheight">
                                <el-col>
                                    <el-input type="textarea" :rows="3" placeholder="Include Rules"
                                        v-model="FormData.includerule" show-word-limit maxlength="240">
                                    </el-input>
                                </el-col>
                            </el-form-item> -->
                        <el-form-item label="Graphic Box" class="labelheight">
                            <el-col>
                                <el-upload ref="upload" action="" :drag="true" :show-file-list="false"
                                    :id="'library_directionslibrary_addedit_graphicbox'"
                                    class="guidancedragupload guidanceuploadiconsize textupload"
                                    :http-request="(event) => { return addAttachment(event, FormData); }"
                                    accept=".png,.jpg,.jpeg,.gif,.mp4,.mp3">

                                    <em class="el-icon-upload"></em>
                                    <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                                </el-upload>
                                <!-- <div v-if="FormData.file">{{ FormData.file.originalname }}</div> -->
                                <span class="left">
                                    <div v-if="FormData.file">
                                        <span class="widthlabel300">
                                            <div>
                                                <span class="uploadedfilenames">
                                                    <em class="el-icon-document"></em>
                                                    <span @click="beforeuploadpreview(FormData.file)">{{
                                                        FormData.file.originalname }}</span>
                                                </span>
                                                <span>
                                                    <em class="el-icon-close deletefiles"
                                                        @click="deleteAttachment(FormData.file.originalname, FormData.file)"></em>
                                                </span>
                                            </div>
                                        </span>
                                    </div>

                                    <div class="guidancemediacontentstyle" v-if="FormData.mediacontent">
                                        <span class="UR-Link marginl5 marginright5px fontsize12 margintop5"
                                            @click="previewsnip(FormData.mediacontent)">{{ FormData.mediacontent.name
                                            }}</span>
                                    </div>
                                </span>
                                <!-- <el-input placeholder="Please input" v-model="FormData.name"></el-input> -->
                            </el-col>
                        </el-form-item>
                        <el-form-item label="Direction Degree" class="labelheight">
                            <el-col>
                                <span class="left">
                                    <el-button @click="rotatearrowicon(FormData)"
                                        class="glyphicon glyphicon-circle-arrow-up arrowiconstyle"
                                        :style="'transform: rotate(' + FormData.directiondegree + 'deg); transition: 0.5s linear;'"
                                        circle></el-button>
                                </span>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="Message Box" class="labelheight">
                            <el-col>
                                <!-- <el-input type="textarea" :rows="2" placeholder="Message Box" v-model="FormData.message"
                                        show-word-limit maxlength="50">
                                    </el-input> -->
                                <div class="row" style="font-weight: normal;">
                                    <span class="Directionscoll1">
                                        <editor :metadata="metadata"></editor>
                                    </span>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="Policy Link Cell" class="labelheight">
                            <el-col>
                                <el-input placeholder="Policy Link Cell"
                                    :id="'library_directionslibrary_addedit_policylinkcell'"
                                    v-model="FormData.link"></el-input>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="Offer Close Options" class="labelheight">
                            <el-col>
                                <div>
                                    <el-select v-model="FormData.offercloseoption" placeholder="Offer Close Options"
                                        :id="'library_directionslibrary_addedit_offercloseoptions'" class="fullwidth">
                                        <el-option
                                            v-for="item in [{ value: 'error', label: 'Requirement to Correct' }, { value: 'warning', label: 'Warning correction needed' }]"
                                            :key="item.value" :label="item.label" :value="item.value">
                                        </el-option>
                                    </el-select>
                                </div>
                            </el-col>
                        </el-form-item>
                        <el-form-item label="">
                            <el-row>
                                <el-col>
                                    <div class="floatright">
                                        <el-button :disabled="!editpermission" type="success"
                                            @click="cellchanged('library_directionslibrary_addedit_save', () => { save() }, false)"
                                            icon="el-icon-circle-check">Save</el-button>
                                        <el-button type="success" icon="el-icon-chat-line-round" @click="showpopup">Show
                                            Preview</el-button>
                                        <el-button icon="el-icon-back" type="info" @click="back">Back</el-button>
                                    </div>
                                </el-col>
                            </el-row>
                        </el-form-item>
                    </el-row>
                </el-form>
            </div>
            <!-- </div> -->
            <el-dialog :title="beforemedia.originalname" :visible.sync="beforemedia.show" :before-close="beforepreviewclose"
                class="dialogboxpadding" width="70%">
                <div>
                    <span
                        v-if="beforemedia.extention == 'jpg' || beforemedia.extention == 'jpeg' || beforemedia.extention == 'gif' || beforemedia.extention == 'png'">
                        <img :src="beforemedia.link" class="previewbox" />
                    </span>
                    <span v-if="beforemedia.extention == 'mp4'">
                        <video controls class="previewbox">
                            <source :src="beforemedia.link" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>
                    <span v-if="beforemedia.extention == 'mp3'">
                        <audio controls class="previewbox">
                            <source :src="beforemedia.link" type="audio/ogg">
                            <source :src="beforemedia.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>
            </el-dialog>
            <el-dialog :title="selectedmedia.name" :visible.sync="selectedmedia.showdialog" :before-close="mediadialogClose"
                class="dialogboxpadding" width="70%">
                <div>
                    <el-row>
                        <el-col :span="12">
                            <el-button type="primary">
                                <el-link :underline="false" :href="selectedmedia.externalurl" class="externallink"
                                    target="_blank">External Link</el-link>
                            </el-button>
                            <el-button icon="el-icon-download" @click="downloadmedia(selectedmedia)" type="primary">
                                Download
                            </el-button>
                            <el-button icon="el-icon-delete" class="marginl5" @click="deleteMedia(selectedmedia)"
                                type="danger">
                            </el-button>
                        </el-col>
                    </el-row>
                    <span
                        v-if="selectedmedia.extention == 'jpg' || selectedmedia.extention == 'jpeg' || selectedmedia.extention == 'gif' || selectedmedia.extention == 'png'">
                        <img :src="selectedmedia.link" class="previewbox" />
                    </span>
                    <span v-if="selectedmedia.extention == 'mp4'">
                        <video controls class="previewbox">
                            <source :src="selectedmedia.url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>
                    <span v-if="selectedmedia.extention == 'mp3'">
                        <audio controls class="previewbox">
                            <source :src="selectedmedia.link" type="audio/ogg">
                            <source :src="selectedmedia.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Editor from "./Editor.vue";
export default {
    data() {
        return {
            pagecells: [],
            cellaction: [],
            metadata: {
                content: "",
            },
            extensions: [
                { name: 'jpg' },
                { name: 'jpeg' },
                { name: 'png' },
                { name: 'gif' },
                { name: 'mp4' },
                { name: 'mp3' }
            ],
            beforemedia: {},
            selectedmedia: {},
            pagelist: [],
            boxtypes: ['Small 2 Part Pop up'],
            sections: [],
            FormData: {},
            isedit: false,
            tabledata: [],
            view: {
                pagesize: 20,
                filter: [
                    { table: 'dd', column: 'directionname', value: "" },
                    { table: 'dd', column: 'offercloseoption', value: "" },
                ],
                sortcol: "dd.directionname",
                sortorder: "ascending",
            },
            userlist: [],
            selectedusers: [],
            height: (document.documentElement.clientHeight - 132),
            pagesizes: this.$store.state.pagesizes,
            operations: this.$store.state.operations,
        }
    },
    components: {
        Editor
    },
    computed: {
        pagedisable() {
            return function (data) {
                if (data && data.p &&
                    (data.p.displayname.includes('Setup >') ||
                        data.p.displayname.includes('AMOS >') ||
                        data.p.displayname.includes('Project >') ||
                        data.p.displayname.includes('Libraries > MC_Templates Library') ||
                        data.p.displayname.includes('Libraries > AM_Templates Library') ||
                        data.p.displayname.includes('Libraries > BQ Code Library') ||
                        data.p.displayname.includes('Libraries > RWC Search Library') ||
                        data.p.displayname.includes('Libraries > Checklist Library') ||
                        data.p.displayname.includes('Libraries > CQC Library') ||
                        data.p.displayname.includes('Libraries > MoM Library') ||
                        data.p.displayname.includes('Libraries > Directions Library') ||
                        data.p.displayname.includes('Libraries > Comparatives Library') ||
                        data.p.displayname.includes('QA-Analysis > RWC Report') ||
                        data.p.displayname.includes('QA-Analysis > CQC Report') ||
                        data.p.displayname.includes('QA-Analysis > External Check / QA') ||
                        data.p.displayname.includes('QA-Analysis > Sign Off - Sections') ||
                        data.p.displayname.includes('QA-Analysis > Sign Off - Project') ||
                        data.p.displayname.includes('QA-Analysis > Add & Ddt') ||
                        data.p.displayname.includes('QA-Analysis > QA Report') ||
                        data.p.displayname.includes('Comms > RFI Entry Form') || 
                        data.p.displayname.includes('Comms > RFI Reporting / Uploading')
                    )) {
                    // data.p.displayname.includes('QA-Analysis > Sign Off - Parts Summary') ||
                    return false;
                } else {
                    return true;
                }
            }
        },
    },
    methods: {
        changepagecell(event) {
            if (event != "") {
                this.FormData.directioncelldetails.forEach(x => {
                    if (x.pagecellid != null) {
                        let currentpagecell = this.pagecells.find(c => c.id == x.pagecellid);
                        if (currentpagecell != undefined) {
                            x.pc = currentpagecell;
                        }
                    }
                });
            }
        },

        changechecked(event, row) {
            if (event == true) {
                row.checkvalue = 1;
            }
            else {
                row.checkvalue = 0;
            }
        },

        rotatearrowicon: function (row) {
            if (row.directiondegree == 360) {
                row.directiondegree = 0;
            }
            row.directiondegree = row.directiondegree + 45;
        },
        showpopup() {
            // this.FormData.message = this.metadata.content;
            var option = {
                isedit: true,
                visible: true,
                boxtype: "Small 2 Part Pop up",
                content: this.metadata.content,
                mediacontent: {
                    name: this.FormData.file != null ? this.FormData.file.originalname : "",
                    link: this.FormData.file != null ? URL.createObjectURL(this.FormData.file.content) : ""
                },
                offercloseoption: this.FormData.offercloseoption,
                link: this.FormData.link,
                heading: this.FormData.directionname,
                directiondegree: this.FormData.directiondegree,
            }
            if (this.FormData.mediacontent && this.FormData.mediacontent.id > 0) {
                option.mediacontent = this.FormData.mediacontent;
            }
            option.position = {};
            // var checkvalue = "";
            this.$store.state.directionsdialogue = [];
            // this.$store.state.directionsdialogue = [option];

            this.$store.state.directionsdialogue = [option];
            this.$store.state.currentdirectionindex = 0;
            this.$store.state.currentdirection = option;
            // this.pageactionchange('save',checkvalue,option);
        },
        downloadmedia(row) {
            var link = document.createElement("a");
            link.href = row.link;
            link.download = row.name;
            link.click();
        },
        mediadialogClose(done) {
            this.selectedmedia = {};
            done();
        },
        deleteAttachment() {
            this.$set(this.FormData, 'file', null);
        },
        beforeuploadpreview(i) {
            i.extention = i.originalname.substring(i.originalname.lastIndexOf('.') + 1).toLowerCase();
            i.link = URL.createObjectURL(i.content);
            i.show = true;
            URL.revokeObjectURL(i.content);
            this.beforemedia = i;
        },

        beforepreviewclose(done) {
            this.beforemedia = {};
            done();
        },
        deleteMedia: function (row) {
            this.$confirm('Are you sure you want to Delete?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.showloading();
                this.$http.post("Direction/deleteddirectionropboxmidea", row)
                    .then(response => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: "success"
                        });
                        this.FormData.sectionalviewimageid = null
                        this.selectedmedia.showdialog = false;
                        this.FormData.mediacontent = null;
                        // this.refresh();
                        this.selectedmedia = {};
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        previewsnip(i) {
            i.extention = i.name.substring(i.name.lastIndexOf('.') + 1).toLowerCase();
            i.showdialog = true;
            i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
            if (i.extention === "mp4") {
                i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
            }
            this.selectedmedia = i;
        },
        addfile(file) {
            const name = file.file.name;
            const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
            const existextension = this.extensions.find(x => x.name == ext.toLowerCase());
            const validext = this.extensions.map(e => { return e.name; }).join(", ");
            var style = "<style> .el-message-box {width:30%;} </style>";
            if (existextension == undefined) {
                this.$alert(`<span style='color:red;'>${name}</span> has invalid extension, allowed extensions are  ${validext}. ${style}`,
                    "Validate extension", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            var maxsize = 104857600; // 100 MB size
            if (file.file.size > maxsize) {
                this.$alert(`<span style='color:red;'>${file.file.name}</span> File size must be less than 100 mb.<br/>. ${style}`,
                    "Validate extension", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            const rename = `${file.file.name}`;
            const renamefile = new File([file.file], rename);
            const f = {
                originalname: name,
                checkingname: rename,
                content: renamefile
            };
            this.$set(this.FormData, 'file', f);
            // this.FormData.file = f;
        },
        addAttachment(file, row) {
            if (this.FormData.mediacontent && this.FormData.mediacontent.id > 0) {
                this.$confirm("Old midea will be removed.Are you sure you want to Update New Midea?", 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning'
                }).then(() => {
                    this.FormData.mediacontent = null;
                    this.addfile(file);
                }).catch(() => {
                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            } else {
                this.addfile(file);
            }
        },
        getPages: function () {
            this.showloading();
            this.$http.get("account/getpages")
                .then(response => {
                    this.pagelist = response.data;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },
        clearsection() {
            this.view.filter[1].value = "";
            this.view.pageno = 1;
            this.refresh();
        },
        querySection: function (query, done) {
            query = query ? query : ""; // ifnull set to empty string
            var result = [];
            this.sections.forEach(x => {
                if (x.section.toLowerCase().indexOf(query.toLowerCase()) >= 0)
                    result.push({ value: x.section });
            });
            done(result);
        },
        createNew() {
            this.isedit = true;
            this.metadata = {
                content: "",
            };
            this.FormData = JSON.parse(JSON.stringify({
                id: 0,
                directionname: "",
                description: "",
                pageid: null,
                celllocation: "",
                action: "",
                messageboxtype: "Small 2 Part Pop up",
                sectionid: null,
                formatrule: "",
                excluderule: "",
                includerule: "",
                sectionalviewimageid: null,
                message: "",
                directioncelldetails: [],
                directionsections: [],
                link: "",
                corrent: false,
                archive: false,
                ignore: false,
                directiondegree: 0
            }));

            this.addCellLocation();
        },
        back() {
            this.FormData = {};
            this.isedit = false;
            this.refresh();
        },
        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },

        jsonToFormData(data) {
            const formData = new FormData();
            this.buildFormData(formData, data);
            return formData;
        },
        validsave() {
            var msg = [];
            if (this.FormData.directionname == "" || this.FormData.directionname == null) {
                msg.push("Direction Name must not be empty");
            } else {
                if (this.FormData.directionname.length > 50) {
                    msg.push("Direction Name exceed 50 characters limitation");
                }
            }
            if(this.FormData.pageid == undefined || this.FormData.pageid == null || this.FormData.pageid <= 0) {
                msg.push("Please select Page Location.");
            }

            for (let i = 0; i < this.FormData.directioncelldetails.length; i++) {
                const row = this.FormData.directioncelldetails[i];
                if(row.pagecellid == undefined || row.pagecellid == null || row.pagecellid <= 0) {
                    msg.push(`Please select cell name in ${row.celllocation}`);
                }  
            }
            if(this.FormData.cellactionid == undefined || this.FormData.cellactionid == null || this.FormData.cellactionid <= 0) {
                msg.push("Please select action.");
            }

            // if(this.FormData.sectionid == null)
            // {
            //     msg.push("NRM section must be required");
            // }
            if (this.FormData.messageboxtype == "" || this.FormData.messageboxtype == null) {
                msg.push("Message Box type must not be empty");
            }

            if (this.FormData.directioncelldetails.length > 0) {
                let dcdarray = this.FormData.directioncelldetails;
                let pagecellids = new Set();
                for (const directioncell of dcdarray) {
                    let pagecellid = directioncell.pagecellid;
                    if (pagecellid) {
                        if (pagecellids.has(pagecellid)) {
                            let errmsg = `Selected value for '${directioncell.celllocation}' is already in use. Please choose a unique value.`;
                            msg.push(errmsg);
                        } else {
                            pagecellids.add(pagecellid);
                        }
                    }
                }
            }

            if (msg.length > 0) {
                var style = "<style> .el-message-box {width:50%;} </style>";
                this.$alert(msg.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true,
                });
                return false;
            } else {
                return true;
            }
        },
        getsection() {
            if (this.FormData.directionsections.length > 0) {
                const combinedSections = this.FormData.directionsections.map(item => item.section).join("|");
                return combinedSections;
            } else {
                return "";
            }
        },

        cellchanged(cellaction, callback, cancall) {
            const app = this;
            this.$directionutility.setcomponent(app);
            if (cancall) {
                callback();
                this.canmatchcellid(() => { }, cellaction, this.getsection());
            } else {
                this.canmatchcellid(callback, cellaction, this.getsection());
            }
        },
        save() {
            if (this.validsave()) {
                this.FormData.directioncelldetails.forEach(x => {
                    if (x.pc != undefined && x.pc.celltype == "checkbox") {
                        if (!x.ischeckbox) {
                            x.checkvalue = "0";
                        }
                    }
                })
                const newsave = () => {
                    this.showloading();
                    let userids = this.FormData.userids;
                    if (userids.length > 0) {
                        this.FormData.userids = userids.join(',');
                    }

                    let formdata = this.jsonToFormData(this.FormData);
                    this.$http.post("Direction/AddEditDirection", formdata)
                        .then((response) => {
                            this.hideloading();
                            this.$message({
                                showClose: true,
                                message: response.data, type: "success"
                            });
                            this.isedit = false;
                            this.refresh();
                        })
                        .catch((err) => {
                            this.hideloading();
                            this.$bus.$emit("error", err);
                        });
                }
                this.FormData.message = this.metadata.content;
                newsave();
            }
        },
        handleCopy(index, row) {
            this.$confirm('Would you like to copy ' + row.dd.directionname + '?', 'Conform', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$http.post("Direction/copyDirection?id=" + row.dd.id)
                    .then((res) => {
                        this.$message({
                            showClose: true,
                            message: res.data, type: "success"
                        });
                        this.refresh();
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Copy canceled'
                });
            });
        },
        handleDelete(index, row) {
            this.$confirm('Are you sure you want to Delete ' + row.dd.directionname + '?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.$http.post("Direction/DeleteDirection?id=" + row.dd.id)
                    .then((res) => {
                        this.$message({
                            showClose: true,
                            message: res.data, type: "success"
                        });
                        this.refresh();
                    })
                    .catch((err) => {
                        this.$bus.$emit("error", err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },
        handleEdit(index, row) {
            this.$http.post("Direction/GetDirection?id=" + row.dd.id,)
                .then((res) => {
                    this.FormData = res.data;

                    if (this.FormData.directioncelldetails && this.FormData.directioncelldetails.length == 0) {
                        this.addCellLocation();
                    }

                    if (this.FormData.pageid && this.FormData.pageid > 0) {
                        this.getpagecellsbypageid(this.FormData.pageid)
                    }
                    // if(this.FormData.pagecellid && this.FormData.pagecellid > 0){
                    //     this.getcellactionbycellid(this.FormData.pagecellid)
                    // }

                    this.FormData.previouspageid = this.FormData.pageid;
                    let userids = this.FormData.userids;
                    if (userids) {
                        this.FormData.userids = userids.split(',').map(Number);
                    }

                    this.FormData.directioncelldetails.forEach(x => {
                        if (x.checkvalue == "1") {
                            x.ischeckbox = true;
                        }
                        x.isExpanded = false;
                    });

                    this.metadata.content = this.FormData.message;
                    this.isedit = true;
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        getheight() {
            this.height = (document.documentElement.clientHeight - 132);
        },
        onSearch() {
            this.view.pageno = 1;
            this.refresh();
        },
        rowperpages: async function (val) {
            try {
                this.usergridrow.ugv.rowperpage = val;
                this.usergridrow = await this.$training.savepagelayout(this.usergridrow);
                this.view.pageno = 1;
                this.view.pagesize = val;
                this.refresh();
            } catch (error) {
                this.$bus.$emit('error', error);
            }
        },
        sortChange: function (sort) {
            this.view.sortcol = sort.prop;
            this.view.sortorder = sort.order;
            this.refresh();
        },
        refresh() {
            this.$http.post("Direction/GetDirectionGrid", this.view)
                .then((res) => {
                    this.view = res.data.view;
                    this.tabledata = res.data.data;
                    this.getAllUsers();
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                });
        },
        getallsection() {
            this.$http.get('project/GetSectionsProjectCreate')
                .then(response => {
                    this.sections = response.data.map(x => { return { section: x.section }; });
                    this.sections.unshift({ section: 'All' });
                }).catch(err => {
                    this.$bus.$emit('error', err);
                });
        },
        getpagecellsbypageid(pageid) {
            this.$http.get('direction/getpagecellsbypageid?id=' + pageid)
                .then(response => {
                    this.pagecells = response.data.pclist;
                    this.cellaction = response.data.calist;
                    this.FormData.directioncelldetails.forEach(x => {
                        if (x.pagecellid != null) {
                            let currentpagecell = this.pagecells.find(c => c.id == x.pagecellid);
                            if (currentpagecell != undefined) {
                                x.pc = currentpagecell;
                            }
                        }
                    });
                }).catch(err => {
                    this.$bus.$emit('error', err);
                });
        },
        // getcellactionbycellid(cellid){
        //     this.$http.get('direction/getcellactionbycellid?id='+cellid)
        //         .then(response => {
        //             this.cellaction = response.data;
        //         }).catch(err => {
        //             this.$bus.$emit('error', err);
        //         });
        // },

        pagecellvalidation() {
            if (!this.FormData.previouspageid) {
                this.FormData.previouspageid = this.FormData.pageid;
            }

            let isnonemptycell = this.FormData.directioncelldetails.some(x => x.pagecellid)
            if (this.FormData.cellactionid || isnonemptycell) {
                this.$confirm("<b>'Page Location'</b> will reset the selected values in <b>'Cell Location'</b> and <b>'Action'</b> dropdowns. <br>Are you sure you want to proceed?", 'Warning', {
                    confirmButtonText: 'OK',
                    cancelButtonText: 'Cancel',
                    type: 'warning',
                    dangerouslyUseHTMLString: true
                }).then(() => {
                    this.FormData.directioncelldetails.forEach(x => {
                        x.pagecellid = null;
                    });
                    this.FormData.cellactionid = null;
                    this.getpagecellsbypageid(this.FormData.pageid);
                }).catch(() => {
                    this.FormData.pageid = this.FormData.previouspageid;
                    if (!this.FormData.pageid) {
                        this.FormData.cellactionid = null;
                        this.FormData.pagecellid = null;
                        this.pagecells = [];
                        this.cellaction = [];
                    }

                    this.$message({
                        showClose: true,
                        type: 'info',
                        message: 'Delete canceled'
                    });
                });
            } else {
                this.getpagecellsbypageid(this.FormData.pageid);
            }
        },

        getAllUsers() {
            this.$http.get("account/GetUsers")
                .then((response) => {
                    this.userlist = JSON.parse(response.data);
                    this.userlist.unshift({ id: -1, fullname: "All" });
                })
                .catch((err) => {
                    this.$bus.$emit("error", err);
                })
        },

        handlesectionselection(values) {
            let existall = values.find(x => x.section == 'All');            
            if (existall != undefined) {
                this.FormData.directionsections = [existall];
            } else {
                this.FormData.directionsections = values;
            }
        },

        handleUserSelection(selectedvalues) {
            if (selectedvalues.includes(-1)) {
                this.FormData.userids = [-1];
            } else {
                this.FormData.userids = selectedvalues;
            }
        },

        toggleExpand(index) {
            this.FormData.directioncelldetails[index].isExpanded = !this.FormData.directioncelldetails[index].isExpanded;
            this.$forceUpdate();
        },

        cellLocationLabel(row, index) {
            let cellname = 'Cell Location ' + (index === 0 ? 'A' : String.fromCharCode(65 + index));
            row.celllocation = cellname;
            return cellname
        },

        addCellLocation() {
            let newcell = {
                id: null, directionid: null, celllocation: null, pagecellid: null,
                excluderule: null, formatrule: null, includerule: null, isExpanded: null,
                ischeckbox: false, checkvalue: null, operation: null
            };
            newcell.id = this.FormData.directioncelldetails.length * -1;
            this.FormData.directioncelldetails.push(newcell);
        },

        deleteCellLocation(index) {
            this.FormData.directioncelldetails.splice(index, 1);
        },

        disableusedcellitems(pagecellId) {
            return this.FormData.directioncelldetails.some(
                direction => direction.pagecellid === pagecellId
            );
        }
    },

    destroyed() {
        window.removeEventListener('resize', this.getheight);
    },

    mounted: function () {
        window.addEventListener('resize', this.getheight);
    },

    created: function () {
        if (this.$store.state.sections == undefined || this.$store.state.sections.length == 0) {
            this.getallsection();
        } else {
            this.sections = this.$store.state.sections.map(x => { return { section: x.section }; });
            this.sections.unshift({ section: 'All' });
        }
        this.getPages();
        let gridrow = this.$training.getusergridrow(this.$route);
        this.view.pagesize = gridrow.ugv.rowperpage;
        this.usergridrow = gridrow;
        this.refresh();
    },
}
</script>
