<template>
    <div>
        <div class="fixdheader">
            <el-row class="width935px">
                <el-col :span="12"><span class="headerstyle ">Add &amp; Ddt</span> </el-col>
                <el-col :span="12" v-show="exportbtn">
                <div class="right">
                    <el-button 
                        type="success" 
                        class="left marginLeft6px" 
                        :disabled="!editpermission"
                        @click="cellchanged('analysis_add_ddt_save', () => { saveaddddt() }, false)">
                        Save
                    </el-button>
                    <el-button 
                        type="success" 
                        class="left marginLeft6px" 
                        :disabled="!editpermission"
                        @click="cellchanged('analysis_add_ddt_export', () => { getAddDdt() }, false)">
                        Export
                    </el-button>
                </div>
                    <!--icon="el-icon-document"-->
                </el-col>
            </el-row>
            <!-- <div class="spaceBetween" /> -->

            <el-row>
                <el-col :span="1.5">
                    <div class="labelwidth75"><b>File Name:</b></div>
                </el-col>

                <el-col :span="22.5">
                    <el-row>
                        <el-col :span="17.5">
                            <el-input 
                                :disabled="!editpermission" 
                                :id="'analysis_add_ddt_filename'" 
                                v-model="data.filename" 
                                class="padding2px width600px">
                            </el-input>
                        </el-col>
                        
                        <el-col :span="6.5">
                            <div class="el-input el-input--mini">
                                <span><b>&nbsp; &nbsp; Add &amp; Ddt Date: </b>{{currentdate}}</span>
                            </div>
                        </el-col>
                    </el-row>
                </el-col>
            </el-row>
            <!-- <div style="height:2px"></div> -->
            <el-row>
                <el-col :span="1.5">
                    <div class="labelwidth75"><b>Origin:</b></div>
                </el-col>

                <el-col :span="22.5">
                    <projectselectpart :defaultproject="leftcurrentproject" @projectsectionchange="leftprojectsectionchange" :remember="false"></projectselectpart>
                </el-col>
            </el-row>

            <div style="height:2px"></div>

            <el-row>
                <el-col :span="1.5">
                    <div class="labelwidth75"><b>Outcome: </b></div>
                </el-col>

                <el-col :span="22.5">
                    <projectselectpart :defaultproject="rightcurrentproject" ref="rightprojectref" @projectsectionchange="rightprojectsectionchange"></projectselectpart>
                </el-col>
            </el-row>

            <!-- <div style="height:2px"></div> -->
            <el-row>
                <el-col :span="1.5">
                    <div class="labelwidth75"><b>Variation: </b></div>
                </el-col>

                <el-col class="width1060px">
                    <el-row :span="24">
                        <el-input 
                            :disabled="!editpermission" 
                            v-model="data.variation" 
                            :id="'analysis_add_ddt_variation'" 
                            class="padding2px" 
                            type="textarea">
                        </el-input>
                    </el-row>
                </el-col>
            </el-row>

            <hr class="hrstyle" />

            <el-table v-show="detail" :data="[{}]" class="tableBox-cell">
                <el-table-column label="" prop="srno" width="50"></el-table-column>
                <el-table-column label="" prop="detail" width="600"></el-table-column>
                <el-table-column label="Origin" prop="qty" width="80" align="right"></el-table-column>
                <el-table-column label="" prop="unitold" width="60"></el-table-column>
                <el-table-column label="Add" prop="addqty" width="80" align="right"></el-table-column>
                <el-table-column label="Ddt" prop="ddtqty" width="80" align="right"></el-table-column>
                <el-table-column label="Outcome" prop="totalqty" width="90" align="right"></el-table-column>
                <el-table-column label="Outcome Unit" prop="unit" width="100" align="center"></el-table-column>
            </el-table>
        </div>

        <div v-show="detail">
            <el-form ref="detailform"
                     label-width="120px" size="mini"
                     v-if="detail"
                     class="tablewordbreak">

                <el-table :data="detailform.details"
                          class="fullwidth"
                          :cell-class-name="cellClassName"
                          @row-click="rowClick"
                          :show-header="false">
                    <el-table-column label="" prop="srno" width="50">
                    </el-table-column>

                    <el-table-column label="" prop="detail" width="600">
                        <template slot-scope="scope">
                            <span v-html="scope.row.detail"></span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Origin" prop="qty" width="80" align="right">
                        <template slot-scope="scope">
                            {{ commaseparatednumber(scope.row.qty, null) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="" prop="unitold" width="60">
                    </el-table-column>

                    <el-table-column label="Add" prop="addqty" width="80" align="right">
                        <template slot-scope="scope">
                            {{ commaseparatednumber(scope.row.addqty, null) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Ddt" prop="ddtqty" width="80" align="right">
                        <template slot-scope="scope">
                            {{ scope.row.ddtqty ? '(' + commaseparatednumber(scope.row.ddtqty, null) + ')' : scope.row.ddtqty }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Outcome" prop="totalqty" width="90" align="right">
                        <template slot-scope="scope">
                            {{ commaseparatednumber(scope.row.totalqty, null) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Outcome Unit" prop="unit" width="100">
                    </el-table-column>

                    <!--<el-table-column label="match" prop="match" width="100" align="right">
                </el-table-column>-->
                    <!--<el-table-column label="Comments" prop="comment" width="300">
                </el-table-column>-->

                </el-table>
                <!--<el-form-item label="">
                <el-button @click="getBill()" type="success" icon="el-icon-document" size="default">Generate Bill</el-button>
            </el-form-item>-->
                <br />
            </el-form>
        </div>

        <customdialog 
            v-if="validationerror" 
            @warnerror="warnerror" 
            :err="errorobject">
        </customdialog>

    </div>
</template>

<script>
    import projectselectpart from './projectselectpart.vue';
    import customdialogbox from "./customdialogbox.vue";
    import moment from 'moment';

    export default {
        data() {
            return {
                cansend:true,
                value:'',
                detail: false,
                exportbtn: false,
                leftproject: { id: null, projectname: '', library: '' },
                leftpart: {},
                leftsectionid: null,
                rightproject: { id: null, projectname: '', library: '' },
                rightpart: {},
                rightsectionid: null,
                data: {},
                detailform: {details:[]},
                errorobject: {
                    message: [],
                    type: "",
                    visible: false,
                },
                validationerror: false,
                currenturl: {
                    left: '',
                    right: ''
                },
                leftcurrentproject: null,
                rightcurrentproject: null
            }
        },

        computed: {
            currentdate() {
                var dt = new Date();
                return moment(dt).format("DD-MMM-YYYY HH:mm");
            }
        },

        methods: {
            validatesave(){
                var error =  [];
                if(!this.data.filename  || this.data.filename.trim() == ""){
                    error.push("Please add a file name first.");
                }else{
                    if(this.data.filename.length > 60){
                      error.push("You have reached the maximum limit of 60 characters for File Name")
                    }
                    var isfind = this.rightproject.projectsections.find(x => x.sectiondisplay == this.data.filename);
                    if(isfind){
                    error.push("File name already exists in the outcome project sections.");
                    }
                }
                if (!this.data.variation || this.data.variation.trim() == "" ) {
                    error.push("Please fill out <b>'Variation'</b> field.");
                }
                if(error.length > 0){
                    var style = "<style> .el-message-box {width:30%;} </style>";
                    this.$alert(error.join("</br>")+style, "! Warning", {
                        dangerouslyUseHTMLString: true,
                    });
                    return false;
                }else{
                    return true;
                }
            },

            setdirectionsection() {
                let sections = [];
                if (this.leftproject.section.section) {
                    sections.push(this.leftproject.section.section);
                }
                if (this.rightproject.section.section) {
                    sections.push(this.rightproject.section.section);
                }
                if (sections.length > 0) {
                    const combinedSections = sections.map(s => s).join("|");
                    return combinedSections;
                } else {
                    return "";
                }
            },

            cellchanged(cellaction, callback, cancall) {
                const app = this;
                this.$directionutility.setcomponent(app);
                if (cancall) {
                    callback();
                    this.canmatchcellid(() => { }, cellaction, this.setdirectionsection());
                } else {
                    this.canmatchcellid(callback, cellaction, this.setdirectionsection());
                }
            },

            saveaddddt(){
                // if(!this.data.filename){
                    
                //     this.$message({
                //         showClose: true,
                //         message:"Please add a file name first.",
                //         type: "error",
                //     });
                //     return false;
                // }else{
                //     var isfind = this.rightproject.projectsections.find(x => x.sectiondisplay == this.data.filename);
                //     if(isfind){
                //         this.$message({
                //         showClose: true,
                //         message:"File name already exists in the outcome project sections",
                //         type: "error",
                //     }); 
                //     return false;
                //     }
                // }
                // if (!this.data.variation) {
                //     var style = "<style> .el-message-box {width:30%;} </style>";
                //     this.$alert("Please fill out <b>'Variation'</b> field."+style, "! Warning", {
                //         dangerouslyUseHTMLString: true,
                //     });
                //     return;
                // }
                if(this.validatesave()){
                    // const issectionright = this.leftproject.projectsections.find(x => x.id == this.leftsectionid);
                    const issectionright = this.rightproject.projectsections.find(x => x.id == this.rightsectionid);
                    if(issectionright){
                        const newright = JSON.parse(JSON.stringify(issectionright)); 
                        newright.sectiondisplay = this.data.filename.trim();
                        var obj = {
                            rightprojectsection:JSON.parse(JSON.stringify(newright)),
                            // rightprojectsection:JSON.parse(JSON.stringify(issectionright))
                            leftsection:this.leftsectionid,
                            rightsection:this.rightsectionid,
                            leftpart:this.leftpart,
                            rightpart:this.rightpart,
                            leftproject:this.leftproject.id,
                            rightproject:this.rightproject.id,
                            variation:this.data.variation
                        };
                        this.$http.post('project/saveaddddt', obj)
                            .then(response => {
                                this.data.filename = "";
                                this.$message({
                                    showClose: true,
                                    message:response.data,
                                    type: "success",
                                }); 
                                
                                if(this.$refs.rightprojectref){
                                    this.$refs.rightprojectref.getSections();
                                }
                            }).catch(err => {
                                this.$bus.$emit('error', err);
                                //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                            });
                    }
                }

            },
            refresh: function () {
                if (this.leftproject.id && this.leftsectionid && this.rightproject.id && this.rightsectionid) {
                    this.getProject();
                }
                else {
                    this.detail = false;
                    this.exportbtn = false;
                }
            },

            generateurl(column) {
                    const baseurl = `${window.location.origin}${window.location.pathname}`;
                    let newurl = "";
                    if (column == 'left') {
                        this.currenturl.left = `originproject=${this.leftproject.id}&originpart=${this.leftpart.id}&originsection=${this.leftsectionid}`;
                        newurl = `${baseurl}?${this.currenturl.left}`;
                    }
                    if (column == 'right') {
                        this.currenturl.right = `outcomeproject=${this.rightproject.id}&outcomepart=${this.rightpart.id}&outcomesection=${this.rightsectionid}`;
                        newurl = `${baseurl}?${this.currenturl.right}`;
                    }

                    if (this.currenturl.left != '' && this.currenturl.right != "") {
                        newurl = `${baseurl}?${this.currenturl.left}&${this.currenturl.right}`;
                    }

                    window.history.pushState({ path: newurl }, '', newurl);
            },

            leftprojectsectionchange: function (Projectobj) {
                this.leftsectionid = Projectobj.sectionid;
                this.leftpart = Projectobj.part;
                this.leftproject = Projectobj.project;
                this.generateurl('left');
                 
                if(this.leftsectionid != null && this.rightsectionid != null){
                    this.refresh();
                }
            },

            rightprojectsectionchange: function (Projectobj) {
                this.rightsectionid = Projectobj.sectionid;
                this.rightpart = Projectobj.part;
                this.rightproject = Projectobj.project;
                    this.generateurl('right'); 
                    if(this.leftsectionid != null && this.rightsectionid != null){
                        this.refresh();
                    }
                // this.refresh();
            },


            getProject: function () {
                if(this.cansend == true){
                this.data.leftsection = this.leftsectionid;
                this.data.rightsection = this.rightsectionid;
                this.data.leftpart = this.leftpart;
                this.data.rightpart = this.rightpart;
                this.data.leftproject = this.leftproject.id;
                this.data.rightproject = this.rightproject.id;
                this.cansend = false;

                this.$http.post('project/getAddDdtView', this.data)
                    .then(response => {
                        this.cansend = true;
                        //var json = JSON.parse(response.data);
                        var json = response.data;
                        this.detailform = json;
                        this.attachments = [];
                        this.detail = true;
                        this.exportbtn = true;

                        //if (json.errors.length > 0) {
                        //    var style = '<style> .el-message-box {width:70%;} </style>'
                        //    this.$alert(json.errors.join('<br />') + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                        //}

                        if ((json.leftproject.section.errors != null || json.rightproject.section.errors != null) &&
                            (json.leftproject.section.errors && json.leftproject.section.errors.trim() != "") || 
                            (json.rightproject.section.errors && json.rightproject.section.errors.trim() != "")) {
                            this.exportbtn = false;
                            var msg = "";

                            /*
                            var style = '<style> .el-message-box {width:70%; height:90%; overflow: scroll;} </style>'
                            */

                            if (json.leftproject.section.errors != null && json.leftproject.section.errors.trim() != "") {
                                msg = msg + "<h4><b>Origin Project</b></h4>" + json.leftproject.section.errors + "<br/><br/>";
                            }
                            if (json.rightproject.section.errors != null && json.rightproject.section.errors.trim() != "") {
                                msg = msg + "<h4><b>Outcome Project</b></h4>" + json.rightproject.section.errors;
                            }

                            let addddterrmsg = [];
                            addddterrmsg.push(msg);

                            this.errorobject = {
                                message: addddterrmsg, 
                                type: "warning", 
                                visible: true
                            };

                            this.validationerror = true;

                            /*
                            this.$alert(msg + style, "Invalid Data", { dangerouslyUseHTMLString: true });
                            */
                        }

                        //this.checkQty();
                    }).catch(err => {
                        this.cansend = true;
                        this.$bus.$emit('error', err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    }); 
                }
            },

            cellClassName({ row, column, rowIndex, columnIndex }) {
                var cls = [];
                if (columnIndex == 1) {
                    if (row.bold) {
                        cls.push("bold");
                    }
                    if (row.underline) {
                        cls.push("underline");
                    }
                    if (row.match == 0) { //row deleted //&& row.qty > 0
                        cls.push("red");
                    }
                    if (row.match == 1) { //row added //&& row.totalqty > 0
                        cls.push("green");
                    }
                    if (!row.detailmatch) {
                        //cls.push("blue");
                    }
                } else if (columnIndex == 4) { //Add
                    if (row.addqty) {
                        cls.push("bold green");
                    }
                }
                else if (columnIndex == 5) { //Ddt
                    if (row.ddtqty) {
                        cls.push("bold red");
                    }
                }
                return cls.join(" ");
            },

            getAddDdt: function () {
                var hasError = false;

                if (!this.data.variation) {
                    var style = "<style> .el-message-box {width:30%;} </style>";
                    this.$alert("Please fill out <b>'Variation'</b> field."+style, "! Warning", {
                        dangerouslyUseHTMLString: true,
                    });
                    return;
                }

                this.data.leftsection = this.leftsectionid;
                this.data.rightsection = this.rightsectionid;
                this.data.leftproject = this.leftproject.id;
                this.data.rightproject = this.rightproject.id;

                this.$http.post('project/getAddDdt', this.data, { responseType: 'blob' })
                    .then(response => {
                        /*
                        var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                        var url = window.URL.createObjectURL(blob)
                        window.open(url);
                        */
                        var blob = response.data;
                        //var fileName = response.headers.filename;
                        var partname = "";
                        if (this.rightpart.id > 0 && this.rightpart.name != "Single Bill") {
                            partname = this.rightpart.name + " - ";
                        }
                        var fileName = "AddDdt-" + this.rightproject.projectname + ' - ' + partname + this.rightproject.section.sectiondisplay + '.xlsx';
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    }).catch(err => {
                        this.$bus.$emit('error', err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });

            },

            rowClick: function (row, event, column) {
                if (!row.bqcode)
                    return;
                this.$confirm('Would you like to edit this activity?', 'Move to edit?', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: '' //success
                }).then(() => {
                    window.setTimeout(() => {
                        this.$store.state.billbqcode = row.bqcode;
                        this.$store.state.billasterisk = row.asterisk;
                        this.$router.push('/projectpage');
                    }, 100);
                }).catch(() => {/*Cancel clicked*/ });
            },

            warnerror: function(warncheck) {
                if (warncheck) {
                    this.exportbtn = true;
                    this.validationerror = false;
                }
            },

            getprojectusingparams() {
                var left = {};
                var right = {};
                const params = new URLSearchParams(window.location.search);
                if (params.size > 0) {
                    let leftprojectid = this.$training.checkandparseint(params.get('originproject'));
                    let leftpartid = this.$training.checkandparseint(params.get('originpart'));
                    let leftsectionid = this.$training.checkandparseint(params.get('originsection'));
                    if (leftprojectid != null) {
                        // this.leftcurrentproject = {
                        //     projectid: leftprojectid,
                        //     partid: leftpartid,
                        //     sectionid: leftsectionid
                        // };
                        left = {
                            projectid: leftprojectid,
                            partid: leftpartid,
                            sectionid: leftsectionid
                        }
                    }

                    let rightprojectid = this.$training.checkandparseint(params.get('outcomeproject'));
                    let rightpartid = this.$training.checkandparseint(params.get('outcomepart'));
                    let rightsectionid = this.$training.checkandparseint(params.get('outcomesection'));
                    if (rightprojectid != null) {
                        // this.rightcurrentproject = {
                        //     projectid: rightprojectid,
                        //     partid: rightpartid,
                        //     sectionid: rightsectionid
                        // };
                        right = {
                            projectid: rightprojectid,
                            partid: rightpartid,
                            sectionid: rightsectionid
                        }
                    }
                    this.rightcurrentproject = right;
                    this.leftcurrentproject = left;
                } 
            }
        },

        created: function () {
            this.getprojectusingparams();            
        },

        components: {
            'projectselectpart': projectselectpart,
            customdialog: customdialogbox
        }
    }
</script>

<style>
    .bold {
        font-weight: bold;
    }

    .italic {
        font-style: italic;
    }
    .greenback {
        background-color: lightgreen
    }
    .redback {
        background-color: red
    }
    .blueback {
        background-color: blue
    }
    .red {
        color: red;
    }
    .green {
        color: blue;
    }
    .green {
        color: green;
    }
</style>
