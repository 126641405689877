<template>
  <div id="app">
    <div id="dialoguecontainer">
      <div style="position:sticky; top: 0; z-index: 1000">
        <nav-menu params="route: route"></nav-menu>
      </div>
      <div class="fixmargin">
        <!--<keep-alive>-->
        <router-view></router-view>
        <!--</keep-alive>-->
      </div>
      <!-- <directionsdialogue v-for=" (i, index) in $store.state.directionsdialogue" :myoption="i" :key="i.id">
      </directionsdialogue> -->
      <directionsdialogue v-if="$store.state.currentdirection" :myoption="$store.state.currentdirection">
      </directionsdialogue>

      <loading-helper></loading-helper>
    </div>
  </div>
</template>

<script>
//  import HomePage from './components/home-page'
// import HelloWorld from './components/HelloWorld.vue'
import NavMenu from './components/nav-menu'
import directionsdialogue from "./components/directionsdialogue.vue";
import Vue from 'vue'
Vue.component('nav-menu', NavMenu);
Vue.component('directionsdialogue', directionsdialogue);
export default {
  name: 'App',
  // components: {
  //   // HelloWorld,
  //   // HomePage,
  //   NavMenu
  // }
}
</script>

<style></style>
