<template>
   <div>
    <pre :style="{'margin': '0px','display': 'grid','min-height':height}">
        <code style="border-radius: 5px;" class="language-javascript " @blur="highlighttext" :id="'myCode'+id" @input="highlightinput" :spellcheck="false"  :contenteditable="true">
        </code>
    </pre>
  </div>
</template>

<script>
import hljs from '../classes/highlight';


export default {
  model: {
    prop: 'modelValue'
  },
  props:{
    containte:{ type: String, default: ''},
    id:{ type: Number, default: 0},
    height:{ type: String, default: '30px'},
     modelValue: {
      default: ""
    },
  },
    data(){
        return {
        }
    },
    watch: {
        'id'(newQuestion, oldQuestion) {
          debugger
            this.settexthighlight();
        }
    },
    methods:{
      highlightinput(e){
       this.$emit('input',e);
      //  this.highlighttext(e);
      },
      settexthighlight(){
        // this.id = id;
        this.$nextTick(()=>{
          console.log(this.modelValue);
          console.log(this.containte);
          const codeElement = document.getElementById('myCode'+this.id);
          if (codeElement) {
            this.$set(codeElement,'innerHTML',this.modelValue);
            hljs.highlightBlock(codeElement);
          }
        });
        
      },
      highlighttext(e){
        const codeElement = document.getElementById('myCode'+this.id);
        if (codeElement) {
          hljs.highlightBlock(codeElement);
        }
      },
    },
    // mounted(){
    //   console.log(this.modelValue); 
    //  this.highlighttext();
    // },
   
}
</script>
<style >
.hljs{display:block;overflow-x:auto;padding:.5em;background:#444}.hljs-keyword,.hljs-link,.hljs-literal,.hljs-section,.hljs-selector-tag{color:#fff}.hljs,.hljs-subst{color:#ddd}.hljs-addition,.hljs-attribute,.hljs-built_in,.hljs-bullet,.hljs-name,.hljs-string,.hljs-symbol,.hljs-template-tag,.hljs-template-variable,.hljs-title,.hljs-type,.hljs-variable{color:#d88}.hljs-comment,.hljs-deletion,.hljs-meta,.hljs-quote{color:#777}.hljs-doctag,.hljs-keyword,.hljs-literal,.hljs-name,.hljs-section,.hljs-selector-tag,.hljs-strong,.hljs-title,.hljs-type{font-weight:700}.hljs-emphasis{font-style:italic}
.hljs-comment{color:#7285b7}.css .hljs-class,.css .hljs-id,.css .hljs-pseudo,.hljs-attribute,.hljs-regexp,.hljs-tag,.hljs-variable,.html .hljs-doctype,.ruby .hljs-constant,.xml .hljs-doctype,.xml .hljs-pi,.xml .hljs-tag .hljs-title{color:#ff9da4}.hljs-built_in,.hljs-constant,.hljs-literal,.hljs-number,.hljs-params,.hljs-pragma,.hljs-preprocessor{color:#ffc58f}.css .hljs-rule .hljs-attribute,.ruby .hljs-class .hljs-title{color:#ffeead}.hljs-header,.hljs-inheritance,.hljs-name,.hljs-string,.hljs-value,.ruby .hljs-symbol,.xml .hljs-cdata{color:#d1f1a9}.css .hljs-hexcolor,.hljs-title{color:#9ff}.coffeescript .hljs-title,.hljs-function,.javascript .hljs-title,.perl .hljs-sub,.python .hljs-decorator,.python .hljs-title,.ruby .hljs-function .hljs-title,.ruby .hljs-title .hljs-keyword{color:#bbdaff}.hljs-keyword,.javascript .hljs-function{color:#ebbbff}.hljs{display:block;overflow-x:auto;background:#002451;color:#fff;padding:.5em;-webkit-text-size-adjust:none}.coffeescript .javascript,.javascript .xml,.tex .hljs-formula,.xml .css,.xml .hljs-cdata,.xml .javascript,.xml .vbscript{opacity:.5}
</style>