<template>
    <div class="width1240px">
        <div class="fixdheader">
            <span class="headerstyle">Training Popup Add / Edit</span>
            <div class="floatright margintb1mm">
                <span class="padright8px">
                    <el-button @click="setpositioninpage" type="primary" class="marginl5" v-if="pageid && guidancedatalist.length > 0">Set Boxes Position</el-button>
                    <el-select v-model="pageid" class="width300px marginl5" clearable placeholder="Select Page" @change="getGuidanceData">
                        <el-option v-for="item in pagelist"
                                    :key="item.p.id"
                                    :label="item.p.displayname"
                                    :value="item.p.id">
                        </el-option>
                    </el-select>
                    <el-button :disabled="!editpermission" icon="el-icon-circle-check" class="marginl5" type="success" @click="saveGuidaneData">Save</el-button>
                    <el-button :disabled="!editpermission" icon="el-icon-circle-plus" class="marginl5" type="primary" @click="addNewItem"></el-button>
                    <el-button icon="el-icon-back" @click="$router.go(-1)">Back</el-button>
                </span>
            </div>
            <hr class="hrstyle" />
        </div>

        <div style="margin-left: -8px;">
            <!-- <el-select v-model="pageid" class="width88pr" clearable placeholder="Select Page" @change="getGuidanceData">
                <el-option v-for="item in pagelist"
                            :key="item.p.id"
                            :label="item.p.displayname"
                            :value="item.p.id">
                </el-option>
            </el-select>

            <el-button @click="setpositioninpage" type="primary" class="marginl5" v-if="pageid && guidancedatalist.length > 0">Set Boxes Position</el-button> -->

            <div v-for="(gditem, index) in guidancedatalist" :key="index" ref="whiteBackground" id="whiteBackground">
                <div class="row">
                    <div class="left">
                        <el-input v-model="gditem.popupname" placeholder="Popup Name" class="NFPpopupnamecellstyle"></el-input>
                    </div>
                    <div class="left p-5-LR" >
                        <span>Info Button's:</span>
                        <span class="p-5-LR">
                            <el-select v-model="gditem.pagecellid" style="width: 149px;border: 1px solid;border-radius: 4px;" placeholder="Select Info Button's">
                                <el-option
                                v-for="item in pagecells"
                                :key="item.id"
                                :label="item.displayname"
                                :value="item.id">
                                </el-option>
                            </el-select>
                        </span>
                    </div>
                    <div class="right">
                        <el-button @click="rotatearrowicon(gditem)" class="glyphicon glyphicon-circle-arrow-up arrowiconstyle" :style="'transform: rotate('+ gditem.directiondegree +'deg); transition: 0.5s linear;'" circle></el-button>
                    </div>
                </div>

                <div class="row">
                    <div class="column1">
                        <editor :metadata="gditem.metadata"></editor>
                    </div>

                    <div class="column2" :style="'background-color: ' + gditem.content2">
                        <span class="left">
                            <!-- <div class="mergeuploadcontrol">
                                <span class="uploadwidth124 left"> -->
                                    <span class="guidancepopupcolorpicker guidancepickcolorstyle"> <b>Pick color :- &nbsp;</b> <el-color-picker v-model="gditem.content2" size="mini"></el-color-picker></span>

                                    <el-upload ref="upload"
                                            action=""
                                            :drag="true"
                                            :show-file-list="false"
                                            class="guidancedragupload guidanceuploadiconsize textupload"
                                            :http-request="(event)=>{ return addAttachment(event, gditem, index); }"
                                            :on-change="()=>{ return editedchanged(gditem); }"
                                            accept=".png,.jpg,.jpeg,.gif,.mp4,.mp3">
                                            
                                        <em class="el-icon-upload"></em>
                                        <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                                    </el-upload>
                                <!-- </span> -->

                                <!-- <span class="left"> -->
                                    <!-- <div @click="copycontrolfile($event, sc, index)" class="copycontrol"> -->
                                    <!-- <div @click="pasteimage(gditem, index)" class="copycontrol">
                                        <div class="copycontroltext">
                                            <span class="copycontrolfont">Paste the snip from the clipboard</span>
                                        </div>
                                    </div>
                                </span> -->
                            <!-- </div> -->
                        </span>

                        <span class="left">
                            <div v-for="(f, i) in gditem.files" :key="i">
                                <span class="widthlabel300">
                                    <div>
                                        <span class="uploadedfilenames">
                                            <em class="el-icon-document"></em>
                                            <span @click="beforeuploadpreview(f)">{{ f.originalname }}</span>
                                        </span>
                                        <span>
                                            <em class="el-icon-close deletefiles" @click="deleteAttachment(f.originalname, gditem.files)"></em>
                                        </span>
                                    </div>
                                </span>
                            </div>

                            <div v-for="svi in gditem.mediacontents" :key="svi.id" class="guidancemediacontentstyle">
                                <span class="UR-Link marginl5 marginright5px fontsize12 margintop5" @click="previewsnip(svi)">{{ svi.name }}</span>
                            </div>
                        </span>
                    </div>
                </div>
                
                <div class="row margintop5">
                    <span class="p-5-LR">New Feature No. &nbsp;<el-input v-model="gditem.version" @change="$training.formatversion(gditem,'version')"  class="NFPinfotrainingcellstyle"></el-input></span>
                    <span class="p-5-LR">Training No. &nbsp;<el-input-number v-model="gditem.trainingnumber" :controls="false" class="NFPinfotrainingcellstyle"></el-input-number></span>
                    <span class="p-5-LR">Information button &nbsp;<el-input-number v-model="gditem.informationnumber" :controls="false" class="NFPinfotrainingcellstyle"></el-input-number></span>
                    <span class="floatright"><el-button type="danger" @click="deleteItem(gditem, index)">Delete</el-button></span>
                </div>
            </div>
        </div>

        <div>
            <el-dialog :title="selectedmedia.name"
                       :visible.sync="selectedmedia.showdialog"
                       :before-close="mediadialogClose"    
                       class="dialogboxpadding"
                       width="70%">
                <div>
                    <el-row>
                        <el-col :span="12">
                            <el-button type="primary">
                                <el-link :underline="false" :href="selectedmedia.externalurl" class="externallink" target="_blank">External Link</el-link>
                            </el-button>
                            <el-button icon="el-icon-download" @click="downloadmedia(selectedmedia)"
                                       type="primary">
                                Download
                            </el-button>
                            <el-button icon="el-icon-delete" class="marginl5" @click="deleteMedia(selectedmedia)"
                                       type="danger">
                            </el-button>
                        </el-col>
                    </el-row>

                    <span v-if="isImage(selectedmedia)">
                        <img :src="selectedmedia.link" class="previewbox" />
                    </span>

                    <span v-else-if="isVideo(selectedmedia)">
                        <video controls class="previewbox">
                            <source :src="selectedmedia.url" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>

                    <span v-else-if="isAudio(selectedmedia)">
                        <audio controls class="previewbox">
                            <source :src="selectedmedia.link" type="audio/ogg">
                            <source :src="selectedmedia.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>
            </el-dialog>
        </div>

        <div>
            <el-dialog :title="beforemedia.originalname"
                       :visible.sync="beforemedia.show"
                       :before-close="beforepreviewclose"    
                       class="dialogboxpadding"                   
                       width="70%">
                <div>                    
                    <span v-if="isImage(beforemedia)">
                        <img :src="beforemedia.link" class="previewbox" />
                    </span>
                    <span v-else-if="isVideo(beforemedia)">
                        <video controls class="previewbox">
                            <source :src="beforemedia.link" type="video/mp4">
                            Your browser does not support the video tag.
                        </video>
                    </span>
                    <span v-else-if="isAudio(beforemedia)">
                        <audio controls class="previewbox">
                            <source :src="beforemedia.link" type="audio/ogg">
                            <source :src="beforemedia.link" type="audio/mpeg">
                            Your browser does not support the audio element.
                        </audio>
                    </span>
                </div>
            </el-dialog>
        </div>
    </div>
</template>

<script>
import Editor from "./Editor.vue";

export default {
    data() {
        return {
            isvalidversion:true,
            pagecells:[],
            pagelist: [],
            pageid: null,
            guidancedatalist: [{ 
                id: 0, 
                pageid: null, 
                metadata: {content:""},
                content: null,
                directiondegree: 0,
                mediacontents: [],
                files: []
            }],
            extensions: [
                {name: 'jpg'},
                {name: 'jpeg'},
                {name: 'png'},
                {name: 'gif'},
                {name: 'mp4'},
                {name: 'mp3'}
            ],
            files: [{
                originalname: '', checkingname: '', content:''
            }],
            guidancedialogue: { visible: false},
            beforemedia: {},
            selectedmedia: {},
        };
    },

    computed: {
        isImage() {
            return (media) => ['jpg', 'jpeg', 'gif', 'png'].includes(media.extention);
        },
        isVideo() {
            return (media) =>  media.extention === 'mp4';
        },
        isAudio() {
            return (media) => media.extention === 'mp3';
        }
    },

    methods: {
        getPages: function () {
            this.showloading();
            this.$http.get("account/getpages")
                .then(response => {
                    this.pagelist = response.data;
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        getGuidanceData: function() {
            this.showloading();
            this.$http.post("sectional/getGuidanceData?pageid=" + this.pageid, {})
                .then(response => {
                    this.guidancedatalist = response.data.guidancedatalist;
                    this.pagecells = response.data.pagecells
                    this.guidancedatalist.forEach(gditem => {
                        gditem.metadata = { content: gditem.content };
                    });

                    if (this.guidancedatalist.length == 0) {
                        this.addNewItem();
                    }

                    this.guidancedatalist = this.guidancedatalist.sort((a, b) => a.trainingnumber - b.trainingnumber);
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        saveGuidaneData: function() {
            if (!this.pageid) {
                this.$alert("Please select page name.", 'Warning', {
                    confirmButtonText: 'OK',
                    dangerouslyUseHTMLString: true,
                    type: 'warning'
                })
                return;
            }

            // this.guidancedatalist.sort((s1, s2) => {
            //     return s1.id + s2.id;
            // });

            let duplicates = [];
            let trainingNumbers = {};
            let informationNumbers = {};

            for (let i = 0; i < this.guidancedatalist.length; i++) {
                let guidanceData = this.guidancedatalist[i];

                // Check for duplicate training numbers
                if (guidanceData.trainingnumber > 0) {
                    if (trainingNumbers[guidanceData.trainingnumber]) {
                        duplicates.push({
                            type: 'Training',
                            number: guidanceData.trainingnumber,
                            row: i + 1
                        });
                    } else {
                        trainingNumbers[guidanceData.trainingnumber] = true;
                    }
                }

                // Check for duplicate information numbers
                if (guidanceData.informationnumber > 0) {
                    if (informationNumbers[guidanceData.informationnumber]) {
                        duplicates.push({
                            type: 'Information',
                            number: guidanceData.informationnumber,
                            row: i + 1
                        });
                    } else {
                        informationNumbers[guidanceData.informationnumber] = true;
                    }
                }

                guidanceData.pageid = this.pageid;
                guidanceData.content = guidanceData.metadata.content;

                // this.guidancedatalist[i].pageid = this.pageid;
                // this.guidancedatalist[i].content = this.guidancedatalist[i].metadata.content;
                
                // this.guidancedatalist[i].files.forEach((gdlmediaitem) => {
                //     gdlmediaitem.checkingname = `${i} || ${gdlmediaitem.originalname}`;
                // });
            }

            if (duplicates.length > 0) {
                let warnmessage = [];
                for (let duplicate of duplicates) {
                    warnmessage.push(`In new feature box-${duplicate.row}, ${duplicate.type} number-'${duplicate.number}' has been duplicated. Please provide a unique ${duplicate.type} number.`);
                }
                let style = "<style> .el-message-box {width:740px;} </style>";
                this.$alert(warnmessage.join("<br />") + style, "Invalid Data", {
                    dangerouslyUseHTMLString: true
                });
                return;
            }
            
            let guidancedata = { guidancedatalist: this.guidancedatalist };
            let formdata = this.jsonToFormData(guidancedata);
            this.showloading();
            let onprogress = {
                onUploadProgress: (progress) => {
                    this.uploadprogress = Math.floor(100 / progress.total * progress.loaded);
                }
            };

            this.$http.post("sectional/saveGuidanceData", formdata, onprogress)
                .then(response => {
                    this.$message({
                        type: "success",
                        showClose: true,
                        message: response.data.message
                    });
                    this.getGuidanceData();
                    this.hideloading();
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                });
        },

        buildFormData(formData, data, parentKey) {
            if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
                Object.keys(data).forEach(key => {
                    this.buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
                });
            } else {
                const value = data == null ? '' : data;
                formData.append(parentKey, value);
            }
        },

        jsonToFormData(data) {
            const formData = new FormData();
            this.buildFormData(formData, data);
            return formData;
        },

        rotatearrowicon: function(row){
            if (row.directiondegree == 360) {
                row.directiondegree = 0;
            }
            row.directiondegree = row.directiondegree + 45;
        },

        addNewItem: function() {
            let isemptycontent = false;
            if (this.guidancedatalist.length > 0) {
                let lastindex = this.guidancedatalist.length - 1;
                isemptycontent = this.guidancedatalist[lastindex].metadata.content == "" && this.guidancedatalist[lastindex].files == 0 ? true : false;
            }

            if (!isemptycontent) {
                let item = { id: null, pageid: null, metadata: {content:""}, content: null, directiondegree: 0, files: []}
                item.id = this.guidancedatalist.length * -1;
                this.guidancedatalist.push(item);
            }
        },

        deleteItem: function(row, index) {
            if (!this.pageid) {
                this.$alert("Please select page name.", 'Warning', {
                    confirmButtonText: 'OK',
                    dangerouslyUseHTMLString: true,
                    type: 'warning'
                })
                return;
            }

            this.$confirm('Are you sure you want to Delete?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.showloading();
                this.guidancedatalist.splice(index, 1);
                row.pageid = this.pageid;
                this.$http.post('sectional/deleteGuidanceData', row)
                .then(response => {
                    this.hideloading();
                    this.$message({
                        showClose: true,
                        type: 'success', message: response.data.message
                    });
                })
                .catch(err => {
                    this.hideloading();
                    this.$bus.$emit('error', err);
                })
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });

        },

        addAttachment(file, row, index) {
            const name = file.file.name;
            const ext = name.substring(name.lastIndexOf('.') + 1).toLowerCase();
            const existextension = this.extensions.find(x => x.name == ext);
            const validext = this.extensions.map(e => { return e.name; }).join(", ");
            if (existextension == undefined) {
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(`<span style='color:red;'>${name}</span> has invalid extension, allowed extensions are  ${validext}. ${style}`, 
                    "Validate extension", {
                    dangerouslyUseHTMLString: true,
                });
                return;
            }
            const rename = `${index} || ${file.file.name}`;
            const renamefile = new File([file.file], rename);
            const f = {
                originalname: name,
                checkingname: rename,
                content: renamefile
            };
            row.files.push(f);
        },

        beforeuploadpreview(i) {
            i.extention = i.originalname.substring(i.originalname.lastIndexOf('.') + 1).toLowerCase();
            i.link = URL.createObjectURL(i.content);
            i.show = true;
            URL.revokeObjectURL(i.content);
            this.beforemedia = i;
        },

        beforepreviewclose(done) {
            this.beforemedia = {};
            done();
        },

        previewsnip(i) {
            i.extention = i.name.substring(i.name.lastIndexOf('.') + 1).toLowerCase();
            i.showdialog = true;
            i.externalurl = `${i.link.substring(0, i.link.length - 1)}0`;
            if (i.extention === "mp4") {
                i.url = `${i.link.substring(0, i.link.length - 4)}raw=1`;
            }
            this.selectedmedia = i;
        },

        downloadmedia(row) {
            var link = document.createElement("a");
            link.href = row.link;
            link.download = row.name;
            link.click();
        },

        deleteMedia: function (row) {
            this.$confirm('Are you sure you want to Delete?', 'Warning', {
                confirmButtonText: 'OK',
                cancelButtonText: 'Cancel',
                type: 'warning'
            }).then(() => {
                this.showloading();
                this.$http.post("sectional/deleteGuidanceMediaFromDropbox", { id: row.id })
                    .then(response => {
                        this.hideloading();
                        this.$message({
                            showClose: true,
                            message: response.data.message,
                            type: "success"
                        });
                        this.selectedmedia.showdialog = false;
                        this.getGuidanceData();
                    })
                    .catch(err => {
                        this.hideloading();
                        this.$bus.$emit('error', err);
                    });
            }).catch(() => {
                this.$message({
                    showClose: true,
                    type: 'info',
                    message: 'Delete canceled'
                });
            });
        },

        mediadialogClose(done) {
            this.selectedmedia = {};
            done();
        },

        editedchanged: function (row) {
            row.edited = true;
        },

        deleteAttachment(name, files) {
            let index = files.findIndex(x => x.originalname == name);
            files.splice(index, 1);
        },

        setpositioninpage() {
            this.$store.state.newfeaturedialgoue.editedialgoue = true;

            let findpage = this.pagelist.find(item => item.p.id == this.pageid);
            this.$router.push({name: findpage.p.name, params: { openediteddialogue: true }});
        }
    },

    components: {
        Editor
    },

    created: function () {
        if (this.$store.state.pages.length > 0) {
            this.pagelist = this.$store.state.pages;
        } else {
            this.getPages();
        }
        this.guidancedialogue.visible = true;
    },
};
</script>

<style scoped>
#whiteBackground {
    padding: 5px 10px 10px 10px;
    background-color: #e1e1e1;
    border: 1px solid;
    width: 600px;
    height: 400px;
    resize: none;
    display: inline-block;
    margin: 15px 10px;
}

.column1 {
  float: left;
  width: 285px;
  padding: 0px;
  height: 315px;
  border: 1px solid;
  background-color: white;
  overflow: auto;
}

.column2 {
  float: right;
  width: 285px;
  padding: 0px;
  height: 315px;
  border: 1px solid;
  background-color: white;
  overflow: auto;
}

.guidancemediacontentstyle {
    width: 280px;
    margin-top: 5px;
    border-top: 1px solid #e7e7e7;
    display: flex;
}

.row:after {
  content: "";
  display: table;
  clear: both;
}
</style>