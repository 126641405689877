<template>
    <div  v-loading.fullscreen.lock="fullscreenLoading" >
        <div style="position:sticky; z-index:1;top:41px; background-color:white;" :class="billviewtype == 0 ? 'billwidth': 'addddtwidth'">
            <!--<h2 style="margin:0px;">Bill View</h2>-->
            <!--<el-row style="position:sticky; top:5px; z-index:1; height:50px; margin-top:-40px">-->
            <el-row>
                <el-col :span="8" class="headerstyle">Bill View</el-col>
                <el-col :span="16">
                    <div v-show="buttonshow" class="paddingwithfloatright">
                        <!-- <el-select v-model="billviewtype"
                                    @change="getProject()"
                                   style="width: 106px;">
                            <el-option v-for="item in [
									{name:'Page Setup',value:0},
									{name:'Add & Ddt view',value:1}
								]"
                                       :key="item.value"
                                       :label="item.name"
                                       :value="item.value">
                            </el-option>
                        </el-select> -->
                        <el-select v-model="view"
                                   class="marginleft4px"
                                   @change="expandcollapsechange"
                                   :id="'billiew_billview_filter_select_view'">
                            <el-option v-for="item in [
									'Bill View',
									'Detailed Bill View',
                                    'Add & Ddt view'
								]"
                                       :key="item"
                                       :label="item"
                                       :value="item">
                            </el-option>
                        </el-select>

                        <el-button type="primary"
                                   class="spellbtn marginleft4px"
                                   style="padding: 3px 5px 1px 5px;"
                                   @click="spellcheckMethod()">
                            Spell Check:
                            <span>
                                <el-switch @change="spellchack()"
                                           v-model="spell"
                                           style="margin-bottom:2px;"
                                           active-color="primary"
                                           :active-value="true"
                                           :inactive-value="false">
                                </el-switch>
                            </span>
                        </el-button>

                        <el-button @click="refresh()"
                                    v-if="billviewtype == 0"
                                   type="primary"
                                   class="btnpaddinglr8 marginleft4px"
                                   icon="el-icon-refresh">Refresh</el-button>

                        <el-button :disabled="!editpermission"
                                   @click="getBill()"
                                   class="btnpaddinglr8 marginleft4px"
                                   type="success"
                                   icon="el-icon-document">Create Bill</el-button>
                    </div>
                </el-col>
            </el-row>

            <el-row>
                <el-col >
                    <div >
                        <projectselectpart pagename="projectbill" :absoluteurl="true" :training="true" :lastsavedshow="true"
                                           @projectsectionchange="projectsectionchange"></projectselectpart>
                    </div>
                </el-col>
            </el-row>
            <div class="spaceBetween" />
            <div class="spaceBetween" />
            <el-row>
                <div v-if="lastsavedinfo != null">
                    <div class="el-input el-input--mini">
                        <span class="bold"> {{ lastsavedinfo.library }}</span>
                        <b class="marginleft4px">Last saved:</b> {{ lastsavedinfo.lastsavedbyname }}
                        {{ lastsavedinfo.lastsaved }}
                    </div>
                </div>
            </el-row>

            <hr class="spaceBetween groove" />
            <el-table v-if="billviewtype == 1" :data="[{}]" class="tableBox-cell">
                <el-table-column label="" prop="srno" width="50"></el-table-column>
                <el-table-column label="" prop="detail" width="600"></el-table-column>
                <el-table-column label="Origin" prop="qty" width="80" align="right"></el-table-column>
                <el-table-column label="" prop="unitold" width="60"></el-table-column>
                <el-table-column label="Add" prop="addqty" width="80" align="right"></el-table-column>
                <el-table-column label="Ddt" prop="ddtqty" width="80" align="right"></el-table-column>
                <el-table-column label="Outcome" prop="totalqty" width="90" align="right"></el-table-column>
                <el-table-column label="Outcome Unit" prop="unit" width="100" align="center"></el-table-column>
            </el-table>
        </div>
        <customdialog
            v-if="validationerror"
            @warnerror="warnerror"
            :err="qtyobject"
        ></customdialog>
        <div >
            <!-- <div class="billtype">
                    <div style="height:5px;background-color:white;">&nbsp;</div>

                </div> -->
            <div v-if="billviewtype == 0"
                ref="spell"
                :contenteditable="true"
                :spellcheck="spell"
                @click="onspellclick"
                class="spellstyle tablepaddinglr10px"
            >
                <el-table
                    ref="table" v-show="detail"
                    :data="detailform.rows"
                    :class="'fullwidth ' + (spell ? 'back' : '')"
                    :row-class-name="rowClassName"
                    :cell-class-name="cellClassName"
                    @row-click="rowClick"
                    row-key="id"
                    :expand-row-keys="expandedrows"
                    :default-expand-all="false"
                    :show-header="false"
                    :cell-style="{ padding: '0', height: '25px' }"
                >
                    <el-table-column
                        type="expand"
                        :width="view == 'Bill View' ? 1 : 20"
                    >
                        <template slot-scope="b">
                            <div v-if="b.row.sslist.length > 0">
                                <el-table
                                    :data="b.row.sslist"
                                    class="fullwidth"
                                    header-cell-class-name="detailheader"
                                    cell-class-name="detailrow"
                                >
                                    <el-table-column
                                        label="Label"
                                        property="label"
                                        minwidth="100"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="Subject"
                                        property="subject"
                                        minwidth="400"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="Category"
                                        property="category"
                                        minwidth="300"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="Measure"
                                        property="measure"
                                        width="70"
                                        align="right"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="Dim"
                                        property="dim"
                                        width="70"
                                        align="right"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="Qty"
                                        property="qty"
                                        width="80"
                                        align="right"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                        label="Unit"
                                        property="unit"
                                        width="80"
                                    >
                                    </el-table-column>
                                </el-table>
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column label="" prop="srno" width="20">
                    </el-table-column>

                    <el-table-column label="" prop="detail" width="600">
                        <template slot-scope="scope">
                            <div v-html="setNoSpellCheck(scope.row.detail)" class="textalign">
                            </div>
                        </template>
                    </el-table-column>

                    <el-table-column
                        label=""
                        prop="qty"
                        width="80"
                        align="right"
                    >
                    </el-table-column>

                    <el-table-column label="" prop="unit" width="80">
                        <template slot-scope="scope">
                            <div v-html="setNoSpellCheck(scope.row.unit)" class="textalign">
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
          <div v-if="billviewtype == 1">
           

            <div class="addddtwidth" 
            :contenteditable="true"
                :spellcheck="spell"
                @click="onspellclick"
            ref="spell">
            <!-- <el-form  ref="spell"
                     label-width="120px" size="mini"
                     > -->

                <el-table :data="addddtrows"
                          class="fullwidth"
                          :cell-class-name="cellClassNameaddddt"
                          @row-click="rowClickaddddt"
                          :show-header="false">
                    <el-table-column label="" prop="srno" width="50">
                    </el-table-column>

                    <el-table-column label="" prop="detail" width="600">
                        <template slot-scope="scope">
                            <span v-html="scope.row.detail"></span>
                        </template>
                    </el-table-column>

                    <el-table-column label="Origin" prop="qty" width="80" align="right">
                        <template slot-scope="scope">
                            {{ commaseparatednumber(scope.row.qty, null) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="" prop="unitold" width="60">
                    </el-table-column>

                    <el-table-column label="Add" prop="addqty" width="80" align="right">
                        <template slot-scope="scope">
                            {{ commaseparatednumber(scope.row.addqty, null) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Ddt" prop="ddtqty" width="80" align="right">
                        <template slot-scope="scope">
                            {{ scope.row.ddtqty ? '(' + commaseparatednumber(scope.row.ddtqty, null) + ')' : scope.row.ddtqty }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Outcome" prop="totalqty" width="90" align="right">
                        <template slot-scope="scope">
                            {{ commaseparatednumber(scope.row.totalqty, null) }}
                        </template>
                    </el-table-column>

                    <el-table-column label="Outcome Unit" prop="unit" width="100">
                    </el-table-column>

                    <!--<el-table-column label="match" prop="match" width="100" align="right">
                </el-table-column>-->
                    <!--<el-table-column label="Comments" prop="comment" width="300">
                </el-table-column>-->

                </el-table>
                <!--<el-form-item label="">
                <el-button @click="getBill()" type="success" icon="el-icon-document" size="default">Generate Bill</el-button>
            </el-form-item>-->
                <br />
            <!-- </el-form> -->
        </div>
          </div>
            <!--<el-form-item label="">
            <el-button @click="getBill()" type="success" icon="el-icon-document" size="default">Generate Bill</el-button>
        </el-form-item>-->
            <br />
        </div>
        <!-- <el-dialog v-if="intimechecklist" 
                   :visible.sync="intimechecklist"
                   :modal-append-to-body="false"
                   :close-on-press-escape="false"
                   :close-on-click-modal="false"
                   :append-to-body="true"
                   width="63%"> -->
                   <CustomPageDraggable :isvisible="intimechecklist" class="chklisectionpopupstyle">
                        <intimechecklist :ismsg="true" @messageclose="intimechecklist = false" :pageaction="pageaction"></intimechecklist>
                    </CustomPageDraggable>
        <!-- </el-dialog> -->


        <el-dialog :visible.sync="showExcludeWordsDlg" custom-class="width650px height280px no-header-dialog no-padding-dialogbody">
            <el-row>
                <div class="var_crt">
                    <span class="whitecolor marginl5">Spell Check REGEX</span>
                    <span class="floatright marginright5px">
                        <el-button @click="savebillregexdetail" type="success" plain class="padding4px10px2px fontsize13px blackcolor">Save</el-button>
                        <el-button plain class="padding3px5px2px fontsize14px blackcolor" @click="showExcludeWordsDlg = false;">x</el-button>
                    </span>
                </div>

                <el-row v-if="showExcludeWordsDlg" class="padding_TB5px_LR10px">
                    <el-col :span="24">
                        <el-row :gutter="20">
                            <el-col :span="5">
                                Section
                            </el-col>

                            <el-col :span="10">
                                <el-input
                                    v-model="project.section.sectiondisplay"
                                    placeholder="Please input"
                                    :disabled="true">
                                </el-input>
                            </el-col>

                            <el-col :span="9">
                                <span>
                                    (REGEX for Specific Section)
                                </span>
                                <span class="right">
                                    <i class="el-icon-info el-popover__reference"
                                       style="color: rgb(96, 98, 102);font-size: 15px"></i>
                                </span>
                            </el-col>
                        </el-row>

                        <el-row>
                            <el-col>
                                <el-row>
                                    <el-col :span="5">
                                        Exclude
                                    </el-col>

                                    <el-col :span="19">
                                        <span style="width:96%;" class="left">
                                            <el-input 
                                                type="textarea" 
                                                :disabled="readables.exclude1 || !editpermission"
                                                @input="checkvalidpattern(billregexdetail, 'excludewords')" 
                                                :spellcheck="isspellcheck"
                                                :autosize="{ minRows: 3, maxRows: 7 }"
                                                v-model="billregexdetail.excludewords">
                                            </el-input>

                                            <div class="red" v-if="billregexdetail.excludewordsregexvalid">
                                                <strong>Invalid Regular Expression.</strong>
                                            </div>
                                        </span>

                                        <span class="left">
                                            <i class="el-icon-edit el-button--primary" @click="readables.exclude1 = readables.exclude1 == false ? true : false"></i>
                                        </span>
                                    </el-col>
                                </el-row>
                            </el-col>

                            <el-col>
                                <hr class="hrstyle" />
                                <el-row>
                                    <el-col :span="5">
                                        Exclude <br> in all sections
                                    </el-col>

                                    <el-col :span="19">
                                        <span style="width:96%;" class="left">
                                            <el-input  
                                                type="textarea" 
                                                :disabled="readables.exclude2 || !editpermission"
                                                @input="checkvalidpattern(billregexdetail, 'allsectionsexcludewords')" 
                                                :spellcheck="isspellcheck"
                                                :autosize="{ minRows: 3, maxRows: 7 }"
                                                v-model="billregexdetail.allsectionsexcludewords">
                                            </el-input>

                                            <div class="red" v-if="billregexdetail.allsectionsexcludewordsregexvalid">
                                                <strong>Invalid Regular Expression.</strong>
                                            </div>
                                        </span>

                                        <span class="left">
                                            <i class="el-icon-edit el-button--primary" @click="readables.exclude2 = readables.exclude2 == false ? true : false"></i>
                                        </span>
                                    </el-col>
                                </el-row>
                            </el-col>

                        </el-row>
                    </el-col>
                </el-row>
            </el-row>
        </el-dialog>
    </div>
</template>

<script>
import projectselectpart from "./projectselectpart.vue";
import customdialogbox from "./customdialogbox.vue";
import intimechecklist from './checklistsection.vue';
import CustomPageDraggable from './CustomPageDraggable.vue';
export default {
    data() {
        return {
            fullscreenLoading:false,
            addddtrows:[],
            billviewtype:0,
            user: this.$store.state.user,
            pageaction:{},
            intimechecklist:false,
            buttonshow:false,
            togglespell: true,
            lastsavedinfo: null,
            spellclick: null,
            spell: true,
            detail: true,
            project: { id: null, projectname: "", library: "" },
            sectionid: null,
            projectpart: null,
            detailform: { rows: [] },
            expandedrows: [],
            view: "Bill View",
            qtyobject: {
                message: [],
                type: "",
                visible: false,
                sourceqty: "",
                qtydown: ""
            },
            validationerror: false,
            warncheck: false,
            usertick: false,
            readables: {
                exclude1: true,
                exclude2: true
            },
            billregexdetail: {
                excludewords: "",
                allsectionsexcludewords: ""
            },
            isValidPattern: true,
            showExcludeWordsDlg: false,
            mainsections: []
        };
    },
    mounted() {
          this.spell = true
        //   this.$refs.spell.spellcheck = true;
          this.$set(this.$refs.spell,'autofocus',true);
          this.$set(this.$refs.spell,'spellcheck',true);
          this.$refs.spell.focus();
    },
    methods: {
        getpageactionmessage(page,action,pro){
            this.intimechecklist = false;
               let obj = {
                   elt:{formtype:pro.library,nrmelementid:pro.section.section},
                   pa:{pagename:page,actionname:action},
                   reminder:{projectsectionid:pro.section.id,pagename:page,actionname:action,userid:this.user.id}
               }
               this.post("ElementList/sendmessage",obj).then((res)=>{
                   var newdata = res.data;  
                   if(newdata == true){
                       this.pageaction = {pagename:page,actionname:action};
                       this.intimechecklist = true;
                   }
               });
           },
        onspellclick(){
            if(this.spell){
                this.spell = false;
            }
        },
        spellchack() {
            this.togglespell = false;
           this.$refs.spell.focus();
        },
        spellcheckMethod: function() {
            if (this.togglespell) {
                this.showExcludeWordsDlg = true;
                /*
                this.spell = this.spell == true ? false : true;
                */
            }

            /*
            this.spellchack();
            */
            this.togglespell = true;
        },

        refresh: function() {
            if (this.project.id && this.sectionid) {
                this.getProject();
            } else {
                this.detail = false;
                this.buttonshow = false;
            }
        },

        checkEmptyTemplate: function() {
            if (this.detail) {
                if (this.detailform.emptytemplates.length > 0) {
                    var msg =
                        "Please check the template(s) given below for template rows <br />" +
                        this.detailform.emptytemplates.join("<br />");

                    let style = '<style> .el-message-box__content { height: 400px; overflow-y: auto; } </style>';

                    this.$alert(msg + style, "Empty Templates", {
                        dangerouslyUseHTMLString: true
                    });
                }
            }
        },

        projectsectionchange: function(Projectobj) {
            if (Projectobj.lastsavedinfo.library != undefined) {
                this.lastsavedinfo = Projectobj.lastsavedinfo;
            }
            this.sectionid = Projectobj.sectionid;
            this.project = Projectobj.project;
            if(this.sectionid){
                if(this.project.section){
                    if(this.project.section.isaddddt == true){
                        this.view = "Add & Ddt view";
                        this.billviewtype = 1;
                    }else{
                        if(this.view == "Add & Ddt view"){
                            this.view = "Bill View";
                        }
                        this.billviewtype = 0;
                    }
                }
                this.getpageactionmessage("Bill","open",this.project);
            }
            this.projectpart = Projectobj.part;

            this.billregexdetail.excludewords = "";
            this.billregexdetail.allsectionsexcludewords = "";
            
            this.refresh();
        },
        expandcollapsechange(){
            if(this.view == "Add & Ddt view"){
                this.billviewtype = 1;
                this.getProject();
            }else{
                this.billviewtype = 0;
                if(this.detailform.rows == undefined || this.detailform.rows.length <= 0){
                    this.getProject();
                }else{
                    if(this.view == "Detailed Bill View"){
                        this.expandcollapse();
                    }else{
                        this.expandedrows = [];
                    }
                }
            }
        },
        expandcollapse: function() {
            var expandedrows = [];
            if (this.view == "Detailed Bill View") {
                this.detailform.rows.forEach((v, i) => {
                    v.id = i + 1;
                    if (v.qty > 0) {
                        expandedrows.push(i + 1);
                    }
                });
            }
            this.expandedrows = expandedrows;
        },

        getProject: function() {
            // this.showloading();
            var data = {
                id: this.project.id,
                projectpart: this.project.projectpart,
                section: { id: this.sectionid },
                billtype:this.billviewtype
            };
            this.fullscreenLoading = true;
            this.$http
                .post("project/getBillView", data)
                .then(response => {
                    this.fullscreenLoading = false;
                    // this.hideloading();
                    if(this.billviewtype == 0){
                    //var json = JSON.parse(response.data);
                    var json = response.data;

                    json.rows.forEach((v, i) => {
                        v.id = i + 1;
                    });

                    this.getbillregexdetail();
                    
                    this.$set(this,'detailform',json);
                    this.$set(this,'addddtrows',[]);
                    // this.detailform = json;
                   
                    //this.checkQty();
                    this.checkEmptyTemplate();
                    this.expandcollapse();
                    // this.attachments = [];
                    this.detail = true;
                    this.buttonshow = true;
                    this.$refs.spell.focus();
                    
                }else{
                
                    this.$set(this,'detailform',[]);
                    this.$set(this,'addddtrows',response.data);
                    this.detail = true;
                    this.buttonshow = true;
                }
                
                    // this.$nextTick(() => {
                    //     if (this.editing) {
                    //         setTimeout(() => {
                    //             this.$refs.table.focus();
                    //             this.$refs.spell.focus();
                    //         }, 1000);
                    //     }
                    // });
                })
                .catch(err => {
                    // this.hideloading();
                    this.fullscreenLoading = false;
                    this.detailform = {};
                    this.detail = false;
                    this.$bus.$emit("error", err);
                    //console.log(err);
                    //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                });
        },
        rowClassName: function(row, rowIndex) {
            var cls = [];
            if (row.row.sslist.length <= 0 || this.view == "Bill View") {
                cls.push("noexpand");
            }
            return cls.join(" ");
        },
        cellClassNameaddddt({ row, column, rowIndex, columnIndex }) {
                var cls = [];
                if (columnIndex == 1) {
                    if (row.bold) {
                        cls.push("bold");
                    }
                    if (row.underline) {
                        cls.push("underline");
                    }
                    if (row.match == 0) { //row deleted //&& row.qty > 0
                        cls.push("red");
                    }
                    if (row.match == 1) { //row added //&& row.totalqty > 0
                        cls.push("green");
                    }
                    if (!row.detailmatch) {
                        //cls.push("blue");
                    }
                } else if (columnIndex == 4) { //Add
                    if (row.addqty) {
                        cls.push("bold green");
                    }
                }
                else if (columnIndex == 5) { //Ddt
                    if (row.ddtqty) {
                        cls.push("bold red");
                    }
                }
                return cls.join(" ");
            },
        cellClassName({ row, column, rowIndex, columnIndex }) {
            var cls = [];
            // if (rowIndex == 1 && columnIndex == 2) {
            //     let tableRow = document.getElementsByTagName('td');
            //     let changerowtext = tableRow[columnIndex].innerHTML;
            //     console.log(changerowtext);
            // }

            if (columnIndex == 2) {
                if (row.bold) {
                    cls.push("bold");
                }
                if (row.underline) {
                    cls.push("underline");
                }
            }
            if (row.pagebreak && this.view == "Bill View") {
                cls.push("pagebreak");
            }
            return cls.join(" ");
        },

        checkQty: function() {
            if(this.billviewtype == 0){
            var qtywarn = [];
            var boxfill = false;
            this.qtyobject = {
                message: [],
                type: "",
                visible: false,
                sourceqty: "",
                qtydown: ""
            };
            if (
                this.detailform.section.amoseditedon !=
                this.detailform.section.amossavedtoprojecton
            ) {
                this.qtyobject.sourceqty =
                    "The data set that you wish to view or export as a Bill of Quantities appears to <br/>not match with last saved amos data <br> Please, repeat 'Save to project' action within amos tab<br/><br/>";
                this.qtyobject.qtydown =
                    "Amos-Project data mismatch Identified <br/>";
                this.qtyobject.type = "qty";
                boxfill = true;
            }
            var msgcheck = false;
            if (
                !this.warncheck &&
                (this.detailform.cqc == null || this.detailform.rwc == null)
            ) {
                this.qtyobject.sourceqty =
                    this.qtyobject.sourceqty +
                    "The data set that you wish to view or export as a Bill of Quantities appears to contain RWC or CQC errors.<br> Please check the relevant RWC and CQC reports and resolve any issues before proceeding to create the bill<br/><br/>";
                this.qtyobject.qtydown =
                    this.qtyobject.qtydown + "RWC/CQC Errors Identified<br>";
                this.qtyobject.type = "qty";
                boxfill = true;
                msgcheck = true;
            } else if (
                !this.warncheck &&
                (this.detailform.cqc.signoff != true ||
                    this.detailform.rwc.signoff != true) &&
                msgcheck == false
            ) {
                this.qtyobject.sourceqty =
                    this.qtyobject.sourceqty +
                    "The data set that you wish to view or export as a Bill of Quantities appears to contain RWC or CQC errors.<br/> Please check the relevant RWC and CQC reports and resolve any issues before proceeding to create the bill<br/><br/>";
                this.qtyobject.qtydown =
                    this.qtyobject.qtydown + "RWC / CQC Errors Identified<br>";
                this.qtyobject.type = "qty";
                boxfill = true;
            }
            if (this.detail) {
                this.detailform.rows.forEach((v, i) => {
                    v.id = i + 1;
                    if (v.detail == "*") {
                        qtywarn.push(
                            "Item " +
                                v.srno +
                                " has * within the description. Bill export is disabled. Please, fill the Asterisk cell within Project tab"
                        );
                    }
                    if (v.qty == 0) {
                        qtywarn.push(
                            "Undefined item has quantity 0. Bill export is disabled. Please, correct the Dim cell value within Project tab or reimport data(?)"
                        );
                    }
                });
                if (this.detailform.ssqty != this.detailform.billqty) {
                    qtywarn.push(
                        "<b>Qty Mismatch</b><br/>Sorting sheet qty total" +
                            this.detailform.ssqty.toFixed(3) +
                            " does not match with bill qty <br/> total " +
                            this.detailform.billqty.toFixed(3) +
                            "<br/>" +
                            "difference " +
                            (
                                this.detailform.billqty - this.detailform.ssqty
                            ).toFixed(3) +
                            "<br/>" +
                            "Total Round Up " +
                            this.detailform.totalroundup.toFixed(3) +
                            "<br/>" +
                            "Total Round Down " +
                            this.detailform.totalrounddown.toFixed(3)
                    );
                }
                if (this.detailform.section.errors != null) {
                    qtywarn.push("<br/> <b>Project page warnings<b/>");
                    qtywarn.push(this.detailform.section.errors);
                }
                if (qtywarn.length > 0) {
                    this.qtyobject.message = qtywarn;
                    this.qtyobject.sourceqty =
                        this.qtyobject.sourceqty +
                        "The data set that you wish to view or export as a Bill of Quantities appears to contain Bill view errors.<br/>";
                    this.qtyobject.qtydown =
                        this.qtyobject.qtydown + "Bill view Errors Identified";
                    this.qtyobject.type = "qty";
                    boxfill = true;
                }
            }
            this.qtyobject.visible = boxfill;
            this.validationerror = true;
            var dbtime = false;
            if (boxfill == false) {
                dbtime = this.warncheck;
                this.warncheck = true;
            }
            if (!this.warncheck) {
                this.warncheck = dbtime;
                return false;
            } else {
                this.warncheck = dbtime;
                return true;
            }
        }else{
            return true;
        }
        },

        getBill: function() {
            if (this.checkQty()) {
                this.getpageactionmessage("Bill","Export",this.project);
                var data = {
                    id: this.project.id,
                    projectpart: this.project.projectpart,
                    section: { id: this.sectionid, ischeck: this.usertick }
                };
                var url =
                    "project/getBill?detailed=" +
                    (this.view == "Detailed Bill View" ? "true" : "false") + "&isaddddt=" +(this.billviewtype == 1);
                    // this.showloading(); 
                    this.fullscreenLoading = true;   
                this.$http
                    .post(url, data, { responseType: "blob" })
                    .then(response => {
                        this.fullscreenLoading = false;
                        // this.hideloading();
                        /*
                            var blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                            var url = window.URL.createObjectURL(blob)
                            window.open(url);
                            */
                        var blob = response.data;
                        this.warncheck = false;
                        //var fileName = response.headers.filename;
                        var partname = "";
                        // this.projectpart.id > 0 &&
                        if (
                            this.projectpart.name != "Single Bill"
                        ) {
                            partname = this.projectpart.name + "-";
                        }
                        var fileName =
                            this.project.projectname +
                            "-" +
                            partname +
                            this.project.section.sectiondisplay +
                            ".xlsx";
                        var link = document.createElement("a");
                        link.href = window.URL.createObjectURL(blob);
                        link.download = fileName;
                        link.click();
                    })
                    .catch(err => {
                        this.fullscreenLoading = false;
                        // this.hideloading();
                        this.warncheck = false;
                        this.$bus.$emit("error", err);
                        //console.log(err);
                        //this.$alert(err.response.data, err.response.status + ' ' + err.response.statusText, { dangerouslyUseHTMLString: true });
                    });
            }
        },
        warnerror: function(warncheck) {
            this.warncheck = warncheck;
            if (this.warncheck) {
                this.usertick = true;
                this.getBill();
                this.validationerror = false;
            }
        },
        rowClickaddddt(row, event, column) {
                if (!row.bqcode)
                    return;
                this.$confirm('Would you like to edit this activity?', 'Move to edit?', {
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'Cancel',
                    type: '' //success
                }).then(() => {
                    window.setTimeout(() => {
                        this.$store.state.billbqcode = row.bqcode;
                        this.$store.state.billasterisk = row.asterisk;
                        this.$router.push('/projectpage');
                    }, 100);
                });
            },
        rowClick: function(row, event, column) {
            if (!row.bqcode) return;
            this.$confirm(
                "Would you like to edit this activity?",
                "Move to edit?",
                {
                    confirmButtonText: "Yes",
                    cancelButtonText: "Cancel",
                    type: "" //success
                }
            )
                .then(() => {
                    var routname = "/projectpage";
                    if(row.origin != null){
                       if(row.origin == 1){
                           routname = "/projectamos"
                       }
                       if(row.origin == 2){
                           routname = "/projectpage"
                       }
                    }
                    window.setTimeout(() => {
                        this.$store.state.billbqcode = row.bqcode;
                        this.$store.state.billasterisk = row.asterisk;
                        this.$store.state.billspecification = row.specification;
                        this.$router.push(routname);
                    }, 100);
                })
                .catch(() => {
                    this.spell  = true
                    /*Cancel clicked*/
                });
        },

        savebillregexdetail: function() {
            if(this.validatesave()){
                var mainsection = this.mainsections.find(s => s.section == this.project.section.section);

                var data = [];
                if (this.billregexdetail.excludewords != null && 
                    this.billregexdetail.excludewords != undefined) {
                    data.push({
                        sectionid: mainsection?.id,
                        regularexpression: this.billregexdetail.excludewords,
                        type: 'exclude',
                        usage: 'spellcheck',
                        section: mainsection
                    });
                }

                if (this.billregexdetail.allsectionsexcludewords != null && 
                    this.billregexdetail.allsectionsexcludewords != undefined) {
                    data.push({
                        sectionid: -1,
                        regularexpression: this.billregexdetail.allsectionsexcludewords,
                        type: 'exclude',
                        usage: 'spellcheck',
                        section: {}
                    });
                }

                this.$http.post("project/saveBilSpellCheckExcludeWords", data)
                .then(response => {
                    this.showExcludeWordsDlg = false;
                    this.getbillregexdetail();

                })
                .catch(err => {
                    this.$bus.$emit("error", err);
                });
            }
        },

        getbillregexdetail: function () {

            var mainsection = this.mainsections.find(s => s.section == this.project.section.section);

            this.$http.post("project/getBilSpellCheckExcludeWords?sectionname="+mainsection.section, {})
            .then(response => {
                var json = response.data;
                json.forEach(brd => {
                    if (brd.sectionid > 0) {
                        this.billregexdetail.excludewords = brd.regularexpression;
                    }
                    else if (brd.sectionid == -1) {
                        this.billregexdetail.allsectionsexcludewords = brd.regularexpression;
                    }
                });


                /*
                this.detailform.rows.forEach((v) => {
                    v.detailhtml = v.detail;
                    v.unithtml = v.unit;
                    
                    if (this.billregexdetail.excludewords || this.billregexdetail.allsectionsexcludewords) {
                        var excludewords = this.billregexdetail.excludewords + " ||" + this.billregexdetail.allsectionsexcludewords;
                        v.detailhtml = this.highlightWords(v.detail, excludewords);

                        if (v.unit) {
                            v.unithtml = this.highlightWords(v.unit, excludewords);
                        }
                    }
                });
                */

            })
            .catch(err => {
                this.$bus.$emit("error", err);
            });
        },

        highlightWords: function(inputString, regexValue) {
             // Split by '|' and trim spaces
            const patterns = regexValue.split('|').map(pattern => pattern.trim()).filter(pattern => pattern.length > 0);
            
            // Join patterns with '|' and escape special characters
            const escapedPatterns = patterns.map(pattern => pattern.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')).join('|');
            
            // Create a case-sensitive regex pattern with global flag
            const regexPattern = new RegExp(`\\b(${escapedPatterns})\\b`, 'g');

            // Replace matched words with span-wrapped words
            const resultString = inputString.replace(regexPattern, (match) => {
                return `<span spellcheck="false">${match}</span>`;
            });

            return resultString;
        },

        validatesave(){
            var error = [];
            var cansave = true;
            if(this.billregexdetail.excludewordsregexvalid == true){
                error.push("Regular Expression is invalid in 'Exclude' box.");
                cansave = false;
            }

            if(this.billregexdetail.allsectionsexcludewordsregexvalid == true){
                error.push("Regular Expression is invalid in 'All Section Exclude' box.");
                cansave = false;
            }

            if(!cansave){
                var style = "<style> .el-message-box {width:30%;} </style>";
                this.$alert(error.join(" ") +style, "Length Limitation", {
                    dangerouslyUseHTMLString: true,
                });
            }
            return cansave; 
        },

        checkvalidpattern(data, property){
            this.isValidPattern = true;
            data[property + 'regexvalid'] = false;

            try {
                new RegExp(data[property]);
            } catch(e) {
                this.isValidPattern = false;
                data[property + 'regexvalid'] = true;
            }
        },

        setNoSpellCheck(rowdetail) {
            if (rowdetail) {
                if (this.billregexdetail.excludewords || this.billregexdetail.allsectionsexcludewords) {
                    var excludewords = this.billregexdetail.excludewords + " ||" + this.billregexdetail.allsectionsexcludewords;
                    return this.highlightWords(rowdetail, excludewords);
                }
            }

            var cc = this.detailform.rows;

            return rowdetail;
        }
    },
    components: {
        projectselectpart: projectselectpart,
        customdialog: customdialogbox,
        intimechecklist:intimechecklist,
        CustomPageDraggable:CustomPageDraggable
    },
    created: function() {
        this.$bus.$on("setuser", (user) => {
            this.user = user;
        });
        const params = new URLSearchParams(window.location.search);
        if (params.size == 3) {
            this.$training.getprojectusingparam(params);
        }

        this.$training.getallsection().then((data) => {
            this.mainsections = data;
        });
        
        //this.spellchack();
    }
};
</script>
<style itemscope>
.spellbtn.el-button--mini,
.el-button--mini.is-round {
    padding: 3px 15px;
}
.pagebreak {
    border-top: double;
}

.noexpand .el-icon {
    display: none;
}

.noexpand .el-table__expand-icon {
    pointer-events: none;
}

.detailrow {
    background-color: rgb(246, 240, 250) !important;
}

.detailheader {
    background-color: rgb(125, 119, 153) !important;
    color: white !important;
}

.el-row {
    margin-bottom: 0px;
}

.billtype {
    position: sticky;
    top: 111px;
    z-index: 1;
    background-color: white;
}

/*Remove the bottom border*/
.el-table::before {
    height: 0;
}
[contenteditable] {
    outline: 0px solid transparent;
}

.back {
    z-index: -1;
}

.spellstyle:focus{
    caret-color:transparent !important;
}
.red {
        color: red;
    }
    .green {
        color: blue;
    }
    .green {
        color: green;
    }
    .addddtwidth{
        width: 1150px;
    }
    .billwidth{
        width:fit-content;
    }
</style>
